export const columns = [
  {
    title: 'email',
    dataIndex: 'username',
    align: 'left',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'left',
  },
];
