import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Button, Form, Table, Tooltip } from 'antd';

import { compose } from 'services/helpers';
import useModal from 'common/hooks/useModal';
import { UPDATE_REQUEST_INTERVAL } from 'common/constants';

import Modal from 'components/modal';
import Total from 'components/table/total';
import { Table as CustomTable } from 'components';
import EditSubFleetForm from 'components/forms/subFleets/edit-subFleet-form';
import CreateSubFleetForm from 'components/forms/subFleets/create-subFleet-form';
import { ActionsWrapper } from 'pages/customers/styled';
import { fleets } from './styled';

import {
  reviewColumns,
  robotStatusBadges,
  subFleetColumns,
  subFleetRobotsColumns,
  subFleetsActions,
} from './settings';

const { createSubFleetBtn, editSubFleetModal, reviewSubFleetModal } = fleets;

const Subfleets = ({
  subFleetsStore,
  robotsStore,
  customersStore,
  fleetsStore,
  ordersStore,
}) => {
  const [
    isCreateOrderModalShown,
    onSetIsCreateOrderModalShown,
    onCreateOrderOkAction,
    onCreateOrderCancelAction,
  ] = useModal();

  const [
    isUpdateSubFleetModalShown,
    onSetIsUpdateSubFleetModalShown,
    onUpdateOkAction,
    onUpdateSubFleetCancelAction,
  ] = useModal();

  const [
    isReviewSubFleetModalShown,
    onSetIsReviewSubFleetModalShown,
    onReviewSubFleetOkAction,
    onReviewSubFleetCancelAction,
  ] = useModal();

  const [
    isDeleteSubFleetModalShown,
    onSetIsDeleteSubFleetModalShown,
    onDeleteSubFleetOkAction,
    onDeleteSubFleetCancelAction,
  ] = useModal();

  const [createSubfleetForm] = Form.useForm();
  const [editSubfleetForm] = Form.useForm();
  const { orderDetails, updateOrder, deleteSubfleetAction } = subFleetsActions;

  const { getAllActive, activeOrders } = ordersStore;
  const {
    getRobotsByIds,
    robotsByIdsData,
    allRobots,
    getAllNoPagination: getAllRobots,
  } = robotsStore;
  const {
    getByFleetName: getCustomersByFleetName,
    customersByFleet,
  } = customersStore;
  const { fleets: allFleets, getAll: getAllFleets } = fleetsStore;
  const {
    create: createSubFleet,
    pageSubFleets,
    getPage,
    pagination,
    total,
    changePagination,
    onSelectSubFleet,
    resetSelectedSubFleet,
    update: updateSubFleet,
    deleteSubfleet,
    onDeleteSubfleet,
    selectedSubFleet,
  } = subFleetsStore;

  useEffect(() => {
    if (!activeOrders.length) getAllActive();
  }, [activeOrders.length, getAllActive]);

  useEffect(() => {
    if (!allRobots.length) getAllRobots();
  }, [getAllRobots, allRobots]);

  useEffect(() => {
    if (!allFleets.length) getAllFleets();
  }, [allFleets.length, getAllFleets]);

  const [expandedRowKeys, setExpandedRowKeys] = useState('');

  const onSetIsSelectedSubFleet = key => _ => {
    if (!key) return;
    onSelectSubFleet(key);
  };

  useEffect(() => {
    let timer = null;
    // Used to update SubFleets each UPDATE_REQUEST_INTERVAL milliseconds
    (async function updateRequest() {
      await getPage();
      timer = setTimeout(updateRequest, UPDATE_REQUEST_INTERVAL);
    })();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [getPage]);

  const getOrderId = useCallback(
    delId => {
      if (activeOrders.length) {
        const order = activeOrders.find(o => o.id === delId);
        if (order) return order.order_id;
      }
    },
    [activeOrders]
  );

  const filteredRobotsInfo = useMemo(() => {
    return robotsByIdsData.map(
      ({ id, name, current_state, current_delivery_id }) => ({
        key: id,
        name: name,
        status: robotStatusBadges(current_state),
        orderId: getOrderId(current_delivery_id),
      })
    );
  }, [getOrderId, robotsByIdsData]);

  const expandedRowRender = () => (
    <Table
      columns={subFleetRobotsColumns}
      dataSource={filteredRobotsInfo}
      pagination={false}
    />
  );

  const onSubfleetCreate = () =>
    createSubfleetForm.validateFields().then(async values => {
      const error = await createSubFleet({ ...values });
      if (!error) {
        onCreateOrderOkAction()();
        createSubfleetForm.resetFields();
      }
    });

  const onSubFleetEdit = () => {
    editSubfleetForm.validateFields().then(async values => {
      const error = await updateSubFleet({ ...values });
      editSubfleetForm.resetFields();
      getPage();
      getRobotsByIds(values.robot_ids);
      if (!error) {
        resetSelectedSubFleet();
        onUpdateOkAction()();
      }
    });
  };

  const onSubFleetDelete = async () => {
    const error = await deleteSubfleet();
    await onDeleteSubfleet();
    if (!error) {
      resetSelectedSubFleet();
      onDeleteSubFleetOkAction()();
    }
  };

  const onExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);

    if (expanded) {
      const subFleet = pageSubFleets.find(sf => sf.id === record.key);
      if (!subFleet) return;
      getRobotsByIds([...subFleet.robot_ids]);
    }
  };

  const subfleetsNamesData = useMemo(
    () =>
      pageSubFleets.map(({ id, name }) => ({ key: id, subfleetName: name })),
    [pageSubFleets]
  );

  const { robot_ids = [], customer_ids = [] } = selectedSubFleet;

  const selectedRobotsNames = useMemo(() => {
    if (!allRobots.length) return '';

    return allRobots
      .filter(r => robot_ids.includes(r.id))
      .map(r => r.name)
      .join(', ');
  }, [allRobots, robot_ids]);

  const selectedCustomersNames = useMemo(() => {
    if (!customersByFleet.length) return '';

    return customersByFleet
      .filter(r => customer_ids.includes(r.id))
      .map(r => r.name)
      .join(', ');
  }, [customer_ids, customersByFleet]);

  const selectedFleetName = useMemo(() => {
    if (!selectedSubFleet.fleet_id) return '';
    const fleet = allFleets.find(f => f.id === selectedSubFleet.fleet_id);
    return fleet.name;
  }, [allFleets, selectedSubFleet.fleet_id]);

  const currentSelectedSubfleetData = useMemo(
    () => [
      { key: 'Subfleet name', value: selectedSubFleet.name },
      { key: 'Fleet city', value: selectedFleetName },
      { key: 'Customers', value: selectedCustomersNames },
      { key: 'Robots', value: selectedRobotsNames },
      { key: 'Slack link', value: selectedSubFleet.slack_url },
      { key: 'Latitude', value: selectedSubFleet.latitude },
      { key: 'Longitude', value: selectedSubFleet.longitude },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedCustomersNames,
      selectedRobotsNames,
      selectedFleetName,
      selectedSubFleet.name,
    ]
  );

  useEffect(() => {
    getCustomersByFleetName(selectedFleetName);
  }, [getCustomersByFleetName, selectedFleetName]);

  const columnsWithAction = [
    ...subFleetColumns,
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: subFleet => {
        return (
          <ActionsWrapper>
            <Tooltip title={updateOrder.tooltip}>
              <div>
                <Button
                  style={updateOrder.style}
                  type={updateOrder.type}
                  icon={updateOrder.icon}
                  onClick={onSetIsUpdateSubFleetModalShown(
                    onSetIsSelectedSubFleet(subFleet.key)
                  )}
                />
              </div>
            </Tooltip>
            <Tooltip title={subFleetsActions.orderDetails.tooltip}>
              <div>
                <Button
                  style={orderDetails.style}
                  type={orderDetails.type}
                  icon={orderDetails.icon}
                  onClick={onSetIsReviewSubFleetModalShown(
                    onSetIsSelectedSubFleet(subFleet.key)
                  )}
                />
              </div>
            </Tooltip>
            <Tooltip title={subFleetsActions.deleteSubfleetAction.tooltip}>
              <div>
                <Button
                  style={deleteSubfleetAction.style}
                  type={deleteSubfleetAction.type}
                  icon={deleteSubfleetAction.icon}
                  onClick={onSetIsDeleteSubFleetModalShown(
                    onSetIsSelectedSubFleet(subFleet.key)
                  )}
                />
              </div>
            </Tooltip>
          </ActionsWrapper>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={createSubFleetBtn.title}
        visible={isCreateOrderModalShown}
        width={createSubFleetBtn.width}
        destroyOnClose
        onOk={onSubfleetCreate}
        onCancel={onCreateOrderCancelAction(() => resetSelectedSubFleet())}
      >
        <CreateSubFleetForm form={createSubfleetForm} />
      </Modal>
      <Modal
        title={editSubFleetModal.title}
        visible={isUpdateSubFleetModalShown}
        width={createSubFleetBtn.width}
        destroyOnClose
        onOk={onSubFleetEdit}
        onCancel={onUpdateSubFleetCancelAction(resetSelectedSubFleet)}
      >
        <EditSubFleetForm form={editSubfleetForm} />
      </Modal>

      <Modal
        title={reviewSubFleetModal.title}
        visible={isReviewSubFleetModalShown}
        width={reviewSubFleetModal.width}
        destroyOnClose
        onOk={onReviewSubFleetOkAction(resetSelectedSubFleet)}
        onCancel={onReviewSubFleetCancelAction(resetSelectedSubFleet)}
      >
        <CustomTable
          isModal
          showHeader={false}
          columns={reviewColumns}
          dataSource={currentSelectedSubfleetData}
          pagination={false}
        />
      </Modal>
      <Modal
        title={selectedSubFleet.name}
        visible={isDeleteSubFleetModalShown}
        width='40%'
        destroyOnClose
        okButtonProps={{
          danger: true
        }}
        okText='Delete'
        onOk={onSubFleetDelete}
        onCancel={onDeleteSubFleetCancelAction(() => resetSelectedSubFleet())}
      >
        Are you sure you want to delete this subfleet?
      </Modal>

      <Col>
        <Button
          shape={createSubFleetBtn.shape}
          icon={createSubFleetBtn.icon}
          type={createSubFleetBtn.type}
          onClick={onSetIsCreateOrderModalShown()}
          style={createSubFleetBtn.style}
        >
          {createSubFleetBtn.title}
        </Button>
      </Col>
      <Table
        columns={columnsWithAction}
        expandable={{ expandedRowRender }}
        expandRowByClick={true}
        dataSource={subfleetsNamesData}
        onExpand={onExpand}
        expandedRowKeys={expandedRowKeys}
        onChange={changePagination}
        pagination={
          pagination && {
            ...pagination,
            total,
            showTotal: (_, range) => <Total total={total} range={range} />,
          }
        }
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
    subFleetsStore: rootStore.subFleetsStore,
    errorStore: rootStore.errorStore,
    robotsStore: rootStore.robotsStore,
    fleetsStore: rootStore.fleetsStore,
    ordersStore: rootStore.ordersStore,
    rootStore,
  })),
  observer
)(Subfleets);
