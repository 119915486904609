import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { compose } from 'services/helpers';
import { columns } from './settings';

import useModal from '../../common/hooks/useModal';
import Modal from '../../components/modal';
import { VersionForm } from '../../components/forms';
import { StyledTable } from './styled';

const Versions = ({ rootStore, authStore }) => {
  const [addVersionForm] = Form.useForm();
  const { permissionNames } = authStore;

  const {
    versions,
    getAllVersions,
    addVersion,
    changeVersionsPagination,
    versionsTotal,
    versionsPagination,
    dropVersionsPagination,
  } = rootStore;

  const isUserCanAddVersions =
    permissionNames && permissionNames.includes('admin_versions');

  const [
    isAddVersionModalShown,
    onSetIsAddVersionModalShown,
    onAddVersionOkAction,
    onAddVersionCancelAction,
  ] = useModal();

  const onVersionCreate = () =>
    addVersionForm.validateFields().then(async values => {
      const error = await addVersion({
        ...values,
        release_date: values.release_date._d,
      });
      if (!error) {
        onAddVersionOkAction()();
        addVersionForm.resetFields();
      }
    });

  useEffect(() => {
    getAllVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dropVersionsPagination();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isUserCanAddVersions && (
        <>
          <Button
            shape="round"
            icon={<PlusOutlined />}
            type="primary"
            style={{ marginBottom: '20px' }}
            onClick={onSetIsAddVersionModalShown()}
          >
            Add Version
          </Button>
          <Modal
            title="Add Version"
            visible={isAddVersionModalShown}
            width="100%"
            style={{ maxWidth: 600 }}
            destroyOnClose
            onOk={onVersionCreate}
            onCancel={onAddVersionCancelAction()}
          >
            <VersionForm form={addVersionForm} />
          </Modal>
        </>
      )}
      <StyledTable
        bordered
        columns={columns}
        data={versions}
        pagination={versionsPagination}
        onChange={changeVersionsPagination}
        paginationTotal={versionsTotal}
      />
    </div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    authStore: rootStore.authStore,
  })),
  observer
)(Versions);
