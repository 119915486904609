import { Row, DatePicker } from 'antd';
import styled from 'styled-components';

import { Table } from 'components';

const { RangePicker: AntdRangePicker } = DatePicker;

const alpha = 0.2;

export const StyledTable = styled(Table)`
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: inherit;
  }
  tr {
    background: #fff;
  }
  .colored--light-blue {
    background: #f4faff;
  }
  .colored--light-blue td {
    transition: none;
  }
  .colored--light-blue td.ant-table-column-sort,
  .colored--light-blue td.ant-table-cell-fix-left,
  .colored--light-blue td.ant-table-cell-fix-right {
    background: #f4faff;
  }
  .colored--red {
    background: rgba(255 0 0 / ${alpha});
  }
  .colored--blue {
    background: rgba(0 0 255 / ${alpha});
  }
  .colored--darkcyan {
    background: rgba(0 139 139 / ${alpha});
  }
  .colored--orange {
    background: rgba(255 165 0 / ${alpha});
  }
  .colored--navy {
    background: rgba(0 0 128 / ${alpha});
  }
  .colored--yellow {
    background: rgba(255 255 0 / ${alpha});
  }
  .colored--violet {
    background: rgba(238 130 238 / ${alpha});
  }
  .colored--green {
    background: rgba(0 128 0 / ${alpha});
  }
  .colored--purple {
    background: rgba(128 0 128 / ${alpha});
  }
  .colored--lime {
    background: rgb(0 255 0 / ${alpha});
  }
`;

export const PageActionBar = styled(Row)`
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

export const TopActionBar = styled(Row)`
  margin-bottom: 15px;
`;

export const RangePicker = styled(AntdRangePicker)`
  margin: 0 30px 0 10px;
  width: 218px;
`;
