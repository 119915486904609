import { action, computed, observable } from 'mobx';

import { DEFAULT_CURRENT_LOC, DEFAULT_DROPOFF, DEFAULT_PICKUP } from './Order';

class HabitatOrder {
  @observable order_id = '';
  @observable expires_at = '';
  @observable current_state = '';
  @observable pickup = DEFAULT_PICKUP;
  @observable dropoff = DEFAULT_DROPOFF;
  @observable current_location = DEFAULT_CURRENT_LOC;

  constructor(order, rootStore) {
    Object.assign(this, order);
    this.rootStore = rootStore;
  }

  @action.bound setDropoffAddress = value => {
    this.dropoff.address = value;
  };

  @action.bound setDropoffLocation = ({ latitude, longitude }) => {
    this.dropoff.location.latitude = latitude;
    this.dropoff.location.longitude = longitude;
  };

  @action.bound setPickupAddress = value => {
    this.pickup.address = value;
  };

  @action.bound setPickupLocation = ({ latitude, longitude }) => {
    this.pickup.location.latitude = latitude;
    this.pickup.location.longitude = longitude;
  };

  @action.bound setPickupPhone({ target }) {
    this.pickup.contact_phone = target.value;
  }

  @action.bound setPickupNotes({ target }) {
    this.pickup.notes = target.value;
  }

  @computed get dropoffAddress() {
    return this.dropoff.address;
  }

  @computed get dropoffLocation() {
    return this.dropoff.location;
  }

  @computed get pickupAddress() {
    return this.pickup.address;
  }

  @computed get pickupLocation() {
    return this.pickup.location;
  }
}

export default HabitatOrder;
