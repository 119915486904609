export const settings = {
  polygonSettings: {
    options: {
      fillOpacity: 0.3,
      strokeWeight: 0.5,
      editable: true,
      clickable: true,
    },
  },
  drawingManagerSettings: {
    options: {
      fillOpacity: 0.3,
      strokeWeight: 1,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
    },
  },
};

export const dataType = {
  json: 'application/json',
  csv: 'text/csv'
}
