import React, { useRef, useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import meanBy from 'lodash/meanBy';
import {
  GoogleMap,
  Polygon,
  DrawingManager,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

import { austinCoordinates, LAT_REGEX, LNG_REGEX } from 'common/constants';
import { compose } from 'services/helpers';

import { LoadScriptOnlyIfNeeded } from 'pages/robots-map';
import { apiKey } from 'components/google-map/settings';
import { dataType, settings } from './settings';

import { Menu, Dropdown, Space, Upload, message, Modal } from 'antd';
import {
  DownOutlined,
  DownloadOutlined,
  UploadOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import Button from 'components/button';
import { StyledControls, StyledDropdownWrapper, StyledMessage } from './styled';

const { confirm } = Modal;

const PolygonCreating = ({ customersStore }) => {
  const {
    customer,
    onPolygonCreate,
    polygon,
    onPolygonDelete,
  } = customersStore;

  const customerCoordinate = {
    lat: +customer.addressLatitude,
    lng: +customer.addressLongitude,
  };

  useEffect(() => {
    onPolygonCreate(customer.service_area || []);
  }, [customer.service_area, onPolygonCreate]);

  const { polygonSettings, drawingManagerSettings } = settings;
  const modalContentHeight = parseInt(window.innerHeight * 0.6);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true);
  const polygonRef = useRef(null);

  const onPolygonComplete = useCallback(
    polygon => {
      const pathPolygon = polygon.getPath().getArray();
      const polygonData = pathPolygon.map(polygon => ({
        lat: polygon.lat(),
        lng: polygon.lng(),
      }));
      onPolygonCreate(polygonData);
      polygon.visible = false;
    },
    [onPolygonCreate]
  );

  const getListPosition = useCallback(
    event => {
      let currentIndex = event.vertex || event.edge;
      let polygonData = polygonRef.current.props.paths.map(polygon => ({
        lng: polygon.lng,
        lat: polygon.lat,
      }));
      if (event.edge === 0 || event.edge) {
        currentIndex = event.edge + 1;
        let newLat = event.latLng.lat() * 2 - polygonData[currentIndex - 1].lat;
        let newlng = event.latLng.lng() * 2 - polygonData[currentIndex - 1].lng;
        polygonData.splice(currentIndex, 0, { lng: newlng, lat: newLat });
      }
      if (event.vertex === 0 || event.vertex) {
        currentIndex = event.vertex;
        polygonData[currentIndex] = {
          lng: event.latLng.lng(),
          lat: event.latLng.lat(),
        };
      }
      onPolygonCreate(polygonData);
    },
    [onPolygonCreate]
  );

  const [center, setCenter] = useState(customerCoordinate || austinCoordinates);

  // this effect is added to avoid marker disappearing
  useEffect(() => {
    setCenter(center);
  }, [center]);

  function downloadPolygonFile(url, fileName) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
    a.remove();
  }

  const exportPolygon = ({ key: extension = 'json' }) => {
    if (Array.isArray(polygon)) {
      // Need to keep 6 trailing digits
      let polygonWithoutHeight = polygon.map(({ lat, lng }) => ({
        lat: lat.toFixed(6),
        lng: lng.toFixed(6),
      }));

      let exportData = `data:${dataType[extension]};charset=utf-8,`;
      if (extension === 'json') {
        const jsonString = JSON.stringify(polygonWithoutHeight);
        // Regular expression to match "lat": "value" and "lng": "value"
        // (remove quotes around values)
        let updatedJsonString = jsonString
          .replace(/"lat":\s*"(.*?)"/g, '"lat":$1')
          .replace(/"lng":\s*"(.*?)"/g, '"lng":$1');
        exportData += encodeURI(updatedJsonString);
      } else if (extension === 'csv') {
        let csvContent = 'Latitude,Longitude\n';
        polygonWithoutHeight.forEach(row => {
          csvContent += Object.values(row).join(',');
          csvContent += '\n';
        });
        exportData += encodeURI(csvContent);
      }
      downloadPolygonFile(exportData, `polygon_${customer.name}.${extension}`);
    }
  };

  const menuExportPolygon = (
    <Menu onClick={exportPolygon} style={{ textAlign: 'right' }}>
      <Menu.Item key="csv">
        <div>*.csv</div>
      </Menu.Item>
      <Menu.Item key="json">
        <div>*.json</div>
      </Menu.Item>
    </Menu>
  );

  const filterImportPolygon = content => {
    let isIncorrectImportPolygon = false;
    const newPolygon = content
      .map(point => {
        const lat = point.lat || null;
        const lng = point.lng || null;

        const isCorrectPoint = LAT_REGEX.test(lat) && LNG_REGEX.test(lng);

        if (!isCorrectPoint && !isIncorrectImportPolygon) {
          isIncorrectImportPolygon = true;
        }

        return isCorrectPoint
          ? {
              lat,
              lng,
            }
          : null;
      })
      .filter(point => point);

    return { newPolygon, isIncorrectImportPolygon };
  };

  const formatCSV = content =>
    content
      .trim()
      .replace('Latitude,Longitude\n', '')
      .split('\n')
      .map(point => {
        const coords = point.split(',').map(c => c && Number(c));
        return {
          lat: coords[0],
          lng: coords[1],
        };
      });

  const uploadProps = {
    beforeUpload: async file => {
      const isJson = file.type === 'application/json';
      const isCsv = file.type === 'text/csv';
      const isJsonOrCSV = isJson || isCsv;

      if (!isJsonOrCSV) {
        message.error(
          <StyledMessage>
            <span>{file.name}</span> isn't .csv or .json file
          </StyledMessage>,
          5
        );
        return false;
      }

      const reader = new FileReader();
      reader.onload = e => {
        const content = e.target.result;

        const { newPolygon, isIncorrectImportPolygon } = filterImportPolygon(
          isJson ? JSON.parse(content) : formatCSV(content)
        );

        if (newPolygon.length > 0) {
          if (polygon.length > 0) {
            confirm({
              title: 'Override existing polygon?',
              content: isIncorrectImportPolygon ? (
                <StyledMessage>
                  <span>{file.name}</span> contains missing or corrupted
                  coordinates, they will be skipped for polygon
                </StyledMessage>
              ) : null,
              icon: <ExclamationCircleFilled />,
              style: {
                top: 'calc(50% - 70px)',
              },
              onOk() {
                onPolygonCreate(newPolygon);
                const lat = meanBy(newPolygon, p => p.lat);
                const lng = meanBy(newPolygon, p => p.lng);
                setCenter({ lat, lng });
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          } else {
            if (isIncorrectImportPolygon) {
              message.error(
                <StyledMessage>
                  <span>{file.name}</span> contains missing or corrupted
                  coordinates, they will be skipped for polygon
                </StyledMessage>,
                5
              );
            }
            onPolygonCreate(newPolygon);
            const lat = meanBy(newPolygon, p => p.lat);
            const lng = meanBy(newPolygon, p => p.lng);
            setCenter({ lat, lng });
          }
        } else {
          message.error(
            <StyledMessage>
              <span>{file.name}</span> contains inappropriate data
            </StyledMessage>,
            5
          );
        }
      };
      reader.readAsText(file);

      return isJsonOrCSV || Upload.LIST_IGNORE;
    },
    openFileDialogOnClick: true,
    showUploadList: false,
    multiple: false,
    customRequest: () => {},
    onChange: () => {},
  };

  const menuImportPolygon = (
    <Menu>
      <Menu.Item key="csv" style={{ textAlign: 'right', display: 'block' }}>
        <Upload {...uploadProps} accept="text/csv">
          <div>*.csv</div>
        </Upload>
      </Menu.Item>
      <Menu.Item key="json" style={{ textAlign: 'right', display: 'block' }}>
        <Upload {...uploadProps} accept="application/json">
          <div>*.json</div>
        </Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <LoadScriptOnlyIfNeeded googleMapsApiKey={apiKey} libraries={['drawing']}>
      <Upload {...uploadProps} openFileDialogOnClick={false}>
        <div className="google-map-polygon">
          <GoogleMap
            mapContainerStyle={{ height: `${modalContentHeight}px` }}
            center={center}
            zoom={15}
          >
            <Marker
              title={customer.name}
              position={customerCoordinate}
              onClick={() => setIsInfoWindowOpen(true)}
            />
            {customerCoordinate && isInfoWindowOpen && (
              <InfoWindow
                position={customerCoordinate}
                onCloseClick={() => setIsInfoWindowOpen(false)}
              >
                <span>{customer.name}</span>
              </InfoWindow>
            )}
            <Polygon
              ref={polygonRef}
              paths={polygon || []}
              onRightClick={() => onPolygonDelete()}
              onMouseUp={getListPosition}
              editable
              options={polygonSettings.options}
            />
            (
            <DrawingManager
              drawingMode={'polygon'}
              onPolygonComplete={onPolygonComplete}
              options={drawingManagerSettings.options}
            />
            )
          </GoogleMap>
        </div>
      </Upload>
      <StyledControls>
        {polygon.length ? (
          <Dropdown overlay={menuExportPolygon} placement="bottomRight">
            <Space>
              <Button>
                <DownloadOutlined />
                Export
                <DownOutlined />
              </Button>
            </Space>
          </Dropdown>
        ) : (
          <Button disabled={polygon.length < 1}>
            <DownloadOutlined />
            Export
            <DownOutlined />
          </Button>
        )}
        <StyledDropdownWrapper>
          <Dropdown overlay={menuImportPolygon} placement="bottomRight">
            <Space>
              <Button>
                <UploadOutlined />
                Import
                <DownOutlined />
              </Button>
            </Space>
          </Dropdown>
          <span className="subtitle">or Drag&Drop .csv or .json file</span>
        </StyledDropdownWrapper>
      </StyledControls>
    </LoadScriptOnlyIfNeeded>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
  })),
  observer
)(PolygonCreating);
