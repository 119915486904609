const urls = {
  login: {
    method: 'post',
    url: 'users/login',
  },
  logout: {
    method: 'post',
    url: 'users/logout',
  },
  refreshToken: {
    method: 'post',
    url: refreshToken => `users/refresh_login?refresh_token=${refreshToken}`,
  },
  admins: {
    getAll: { method: 'get', url: 'admin/admins' },
  },
  versions: {
    create: { method: 'post', url: 'admin/versions' },
    getAll: { method: 'get', url: 'admin/versions' },
    getLast: { method: 'get', url: 'admin/version' },
  },
  orders: {
    getAll: { method: 'get', url: 'admin/deliveries' },
    create: {
      method: 'post',
      url: customerId => `admin/customers/${customerId}/deliveries`,
    },
    search: {
      method: 'get',
      url: state =>
        `admin/deliveries/${state === 'revoke' ? 'cancelled' : state}/search`,
    },
    update: { method: 'put', url: id => `admin/deliveries/${id}` },
    export: {
      method: 'get',
      url: state => `admin/deliveries/${state}.csv`,
    },
    robotReassign: {
      method: 'put',
      url: delId => `admin/deliveries/${delId}/reassign_robot`,
    },
    getFailedSnapshots: { method: 'get', url: 'admin/deliveries/failed_snapshot' },
  },
  observerDashboard: {
    getAll: { method: 'get', url: 'observer/dashboard' },
  },
  customers: {
    getAll: { method: 'get', url: 'admin/customers' },
    update: { method: 'put', url: 'admin/customers/' },
    create: { method: 'post', url: 'admin/customers' },
    search: {
      method: 'get',
      url: `admin/customers/search`,
    },
    active: {
      method: active => (active ? 'put' : 'del'),
      url: customerId => `admin/customers/${customerId}/active`,
    },
    integrationsStatus: {
      method: 'put',
      url: customerId => `admin/customers/${customerId}`,
    },
    matches: { method: 'get', url: 'admin/customers/search_autocomplete' },
    getByFleet: { method: 'get', url: 'admin/customers/' },
    serviceArea: {
      method: 'put',
      url: customerId => `admin/customers/${customerId}/service_area`,
    },
  },
  robots: {
    getAll: { method: 'get', url: `admin/robots` },
    // Todo: remove getAllNoPagination after changing logic of back-end
    getAllNoPagination: {
      method: 'get',
      url: 'admin/robots?page=1&per_page=1100',
    },
    get: { method: 'get', url: `admin/robots/` },
    getByFleet: { method: 'get', url: 'admin/robots/' },
    getAvailableBySubFleetId: {
      method: 'get',
      url: 'admin/robots/available_list',
    },
    getAvailableRobotsByDistance: {
      method: 'get',
      url: 'admin/robots/cycling_distance',
    },
    update: { method: 'put', url: `admin/robots/` },
  },
  subFleets: {
    getAll: { method: 'get', url: 'admin/sub_fleets' },
    create: { method: 'post', url: 'admin/sub_fleets' },
    update: { method: 'put', url: 'admin/sub_fleets/' },
    delete: { method: 'del', url: 'admin/sub_fleets/' },
  },
  fleets: {
    getAll: { method: 'get', url: 'admin/fleets' },
  },
  settings: {
    get: { method: 'get', url: 'admin/settings' },
  },
  deliveryHistory: {
    getById: {
      method: 'get',
      url: deliveryId => `admin/deliveries/${deliveryId}/snapshot`,
    },
  },
};

export default urls;
