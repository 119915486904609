import { observable, action, computed, toJS } from 'mobx';
import { keys } from 'lodash';
import moment from 'moment';

import { deliveryStatesCategories } from '../common/constants';
import { isOdd } from '../services/helpers';
import Order from '../models/Order';
import {
  columns,
  activeColumns,
  completedColumn,
  scheduledColumns,
} from '../pages/orders/settings';
import { buildParams, handleFilters } from '../services/helpers';
import HabitatOrder from '../models/HabitatOrder';

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
  filter: '',
};

const DEFAULT_BATCHING_PAGINATION = {
  current: 1,
  pageSize: 20,
  columnKey: '',
  order: '',
  filter: '',
};

const DEFAULT_ORDER_STATE = 'active';
const DEFAULT_DATE_RANGE = { dateFrom: '', dateTo: '' };

class Orders {
  @observable state = DEFAULT_ORDER_STATE;
  @observable rawOrders = [];
  @observable rawOrder = {};
  @observable rawOrdersActive = [];
  @observable totalCount = '';
  @observable searchValue = '';
  @observable rawPagination = DEFAULT_BATCHING_PAGINATION;
  @observable dateRange = DEFAULT_DATE_RANGE;
  @observable rawColumns = columns;
  @observable rawActiveColumns = activeColumns;
  @observable rawCompletedColumns = [...columns, completedColumn];
  @observable rawScheduledColumns = scheduledColumns;
  @observable rawScheduledTotalCount = 0;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawOrder = new Order({}, rootStore);
    this.rawHabitatOrder = new HabitatOrder({}, rootStore);
  }

  @action.bound onHabitatSearch({ items: [pickupData] }) {
    this.rawHabitatOrder.pickup.address = pickupData.address.description;
    this.rawHabitatOrder.pickup.contact_phone = pickupData.contact_phone;
    this.rawHabitatOrder.pickup.notes = pickupData.notes;
    this.rawHabitatOrder.pickup.id = pickupData.id;
  }

  @action.bound setStatus(state) {
    this.state = state;
  }

  @action.bound findOne(id) {
    const selectedOrder = this.rawOrders.find(e => e.id === id);
    this.rawOrder = new Order(toJS(selectedOrder), this.rootStore);
  }

  @action.bound resetSelectedOrder() {
    this.rawOrder = new Order({}, this.rootStore);
  }

  @action.bound async getAll() {
    const { method, url } = this.searchValue
      ? this.rootStore.urls.orders.search
      : this.rootStore.urls.orders.getAll;
    let params;
    const urlValue = this.searchValue ? url(this.state) : url;

    if (this.searchValue) {
      params = buildParams({
        ...this.rawPagination,
        ...this.dateRange,
        search: this.searchValue,
      });
    } else {
      params = buildParams({
        ...this.rawPagination,
        ...this.dateRange,
        search: this.searchValue,
        status: this.state,
      });
    }

    return this.rootStore.makeRequest(
      this.onGetAll,
      method,
      `${urlValue}${params}`
    );
  }

  @action.bound async getAllActive() {
    const { method, url } = this.rootStore.urls.orders.getAll;
    const params =  buildParams({
      status: DEFAULT_ORDER_STATE,
    });

    return this.rootStore.makeRequest(
      this.onGetAllActive,
      method,
      // Todo: remove per_page after changing logic of back-end
      `${url}${params}&per_page=1000`
    );
  }

  @action.bound async changePagination(pagination, filter, sort) {
    this.rawPagination = {
      ...pagination,
      ...sort,
      filter: handleFilters(filter),
    };
    this.rawColumns = this.rawColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
      filteredValue: keys(filter).includes(column.key)
        ? filter[column.key]
        : false,
    }));

    this.rawActiveColumns = this.rawActiveColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
      filteredValue: keys(filter).includes(column.key)
        ? filter[column.key]
        : false,
    }));

    this.rawCompletedColumns = this.rawCompletedColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
      filteredValue: keys(filter).includes(column.key)
        ? filter[column.key]
        : false,
    }));

    this.rawScheduledColumns = this.rawScheduledColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
      filteredValue: keys(filter).includes(column.key)
        ? filter[column.key]
        : false,
    }));
    this.getAll();
  }

  @action.bound dropPagination(state) {
    this.searchValue = '';

    this.dateRange = DEFAULT_DATE_RANGE;
    this.rawColumns = this.rawColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: false,
    }));

    this.rawActiveColumns = this.rawActiveColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: false,
    }));

    this.rawCompletedColumns = this.rawCompletedColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: false,
    }));

    this.rawScheduledColumns = this.rawScheduledColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: false,
    }));

    if (state && state === DEFAULT_ORDER_STATE) {
      this.rawPagination = DEFAULT_BATCHING_PAGINATION;
    } else if (!state && this.state === DEFAULT_ORDER_STATE) {
      this.rawPagination = DEFAULT_BATCHING_PAGINATION;
    } else {
      this.rawPagination = DEFAULT_PAGINATION;
    }
  }

  @action.bound onGetAll(data) {
    let prepared = data.items;
    if (this.state === DEFAULT_ORDER_STATE) {
      prepared = this.preparedRobotsArray(data.items);
    }

    this.rawOrders = prepared.map(order => new Order(order, this.rootStore));

    this.totalCount = data.pagination.total_count;
    this.rawScheduledTotalCount = data.pagination.scheduled_total_count;
  }

  @action.bound onGetAllActive(data) {
    this.rawOrdersActive = data.items.map(
      order => new Order(order, this.rootStore)
    );
    this.totalCount = data.pagination.total_count;
    this.rawScheduledTotalCount = data.pagination.scheduled_total_count;
  }

  @action.bound preparedRobotsArray(response) {
    let robotsCount = 0;
    let preparedData = [];

    response.map(item => {
      const ordersQty = item.deliveries.length;
      robotsCount++;

      return item.deliveries.map((delivery, index) => {
        // First row should have span the others should have span = 0
        let count = index ? 0 : ordersQty;
        const formattedData = {
          ...delivery,
          span: count,
          rowClassName: isOdd(robotsCount) ? '' : 'colored--light-blue',
          robot_name: item.robot,
          deliveries_count: item.deliveries_count,
        };

        return (preparedData = [...preparedData, formattedData]);
      });
    });

    return preparedData;
  }

  @action.bound search(search) {
    this.searchValue = search;

    return this.getAll();
  }

  @action.bound setSearch(value) {
    this.searchValue = value;
  }

  @action.bound searchByDateRange([dateFrom, dateTo]) {
    this.dateRange = { dateFrom, dateTo };

    return this.getAll();
  }

  @action.bound async create({ customerId, ...data }) {
    const { location, address } = this.order;
    const {
      create: { method, url },
    } = this.rootStore.urls.orders;
    const dataToSave = {
      dropoff: {
        contact_name: data.name,
        contact_phone: data.phone,
        notes: data.notes,
        ...location,
        address: address,
      },
    };

    await this.rootStore.makeRequest(
      this.onCreate,
      method,
      url(customerId),
      dataToSave
    );
  }

  @action.bound onCreate() {
    this.resetSelectedOrder();
  }

  @action.bound async createHabitatOrder(data, shouldSkipValidations = false) {
    const {
      create: { method, url },
    } = this.rootStore.urls.orders;
    const { dropoff, pickup } = this.habitatOrder;
    const dataToSave = {
      customer_id: data.selectedCustomer,
      pickup: {
        contact_name: data.customerName,
        contact_phone: pickup.contact_phone,
        notes: data.pickupNotes,
        ...pickup.location,
        address: pickup.address,
      },
      dropoff: {
        contact_name: data.dropoffName,
        contact_phone: data.dropoffPhone,
        notes: data.dropoffNotes,
        ...dropoff.location,
        address: dropoff.address,
      },
      sub_fleet_id: data.subFleetId,
      robot_id: data.selectedRobot || null,
      skip_validations: shouldSkipValidations,
    };

    await this.rootStore.makeRequest(
      this.onCreate,
      method,
      url(data.selectedCustomer),
      dataToSave
    );
  }

  @action.bound async editOrder(data, shouldSkipValidations = false) {
    const { location, address } = this.order;
    const dataToSave = {
      skip_validations: shouldSkipValidations,
      failed_reason: data.failed_reason,
      chaser_present: data.chaser_present,
      dropoff: {
        contact_name: data.name,
        contact_phone: data.phone,
        notes: data.notes,
        ...location,
        address: address,
      },
    };

    await this.update(dataToSave);
  }

  @action.bound async changeOrderState({ current_state }) {
    if (this.order.current_state !== current_state) {
      return this.update({ current_state }, this.onStateUpdate);
    }
  }

  @action.bound async update(data, cb = this.onUpdate) {
    const { method, url } = this.rootStore.urls.orders.update;
    return await this.rootStore.makeRequest(
      cb,
      method,
      url(this.order.id),
      data
    );
  }

  @action.bound onUpdate(data) {
    this.rawOrders = this.rawOrders.map(order => {
      return order.id === data.id ? new Order(data, this.rootStore) : order;
    });
  }

  @action.bound onStateUpdate(data) {
    const activeGroup = deliveryStatesCategories.active;
    // the last order on the page
    // set previous page if new state is not from active group
    if (
      !activeGroup.includes(data.current_state) &&
      this.rawOrders.length < 1
    ) {
      const currentPage = this.rawPagination.current;
      this.rawPagination = {
        ...this.rawPagination,
        // if the currentPage > 1 then pick previous page
        // in case the current page is the only one keep current page = 1
        current: currentPage > 1 ? currentPage - 1 : currentPage,
      };
    }
  }

  @action.bound resetSelectedHabitatOrder() {
    this.rawHabitatOrder = new HabitatOrder({}, this.rootStore);
  }

  @action.bound resetOrdersStore() {
    this.state = DEFAULT_ORDER_STATE;
    this.searchValue = '';
    this.rawPagination = DEFAULT_BATCHING_PAGINATION;
    this.dateRange = DEFAULT_DATE_RANGE;
  }

  @action.bound async onRobotReassign(delId) {
    const { method, url } = this.rootStore.urls.orders.robotReassign;
    return await this.rootStore.makeRequest(this.onCreate, method, url(delId));
  }

  @computed get activeOrders() {
    return this.rawOrdersActive;
  }

  @computed get orders() {
    return this.rawOrders;
  }

  @computed get order() {
    return this.rawOrder;
  }

  @computed get habitatOrder() {
    return this.rawHabitatOrder;
  }

  @computed get total() {
    return this.totalCount;
  }

  @computed get pagination() {
    return this.rawPagination;
  }

  @computed get selectedDateRange() {
    return this.dateRange.dateFrom && this.dateRange.dateTo
      ? [moment(this.dateRange.dateFrom), moment(this.dateRange.dateTo)]
      : [];
  }

  @computed get columns() {
    return this.rawColumns;
  }

  @computed get activeColumns() {
    return this.rawActiveColumns;
  }

  @computed get completedColumns() {
    return this.rawCompletedColumns;
  }

  @computed get scheduledColumns() {
    return this.rawScheduledColumns;
  }

  @computed get scheduledTotalCount() {
    return this.rawScheduledTotalCount;
  }

  filtersSetted() {
    return (
      this.searchValue || (this.dateRange.dateFrom && this.dateRange.dateTo)
    );
  }

  getExportFetchParams() {
    if (this.filtersSetted()) {
      const { method, url } = this.searchValue
        ? this.rootStore.urls.orders.search
        : this.rootStore.urls.orders.getAll;
      const params = buildParams({
        ...this.rawPagination,
        ...this.dateRange,
        search: this.searchValue,
      });

      return { method, url: `${url(this.state)}.csv${params}` };
    }

    const { method, url } = this.rootStore.urls.orders.export;

    return { method, url: url(this.state) };
  }
}

export default Orders;
