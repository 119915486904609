import React from 'react';
import { Layout } from 'antd';

import Header from '../../header';
import Content from '../../content';

const LayoutWithoutSidebar = ({ children }) => (
  <Layout>
    <Header />
    <Content>{children}</Content>
  </Layout>
);

export default LayoutWithoutSidebar;
