export const robotInfoNotification = {
  robotStateWrapper: {
    style: {
      display: 'flex',
    },
  },
  robotState: {
    style: {
      display: 'inlineBlock',
      marginRight: '10px',
    },
  },
};
