export const DEFAULT_LAT = 30.2672;
export const DEFAULT_LNG = -97.7431;
export const LAT_REGEX = /^([+-])?(?:90(?:\.0{1,6})?|(?:[0-9]|[1-8][0-9])(?:\.[0-9]{1,20})?)$/;
export const LNG_REGEX = /^([+-])?(?:180(?:\.0{1,6})?|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:\.[0-9]{1,20})?)$/;
export const PRONE_REGEX = /^\d{10}$/;
export const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const REQUIRED_FIELD = 'This is required field';
export const INVALID_NATIONAL_PHONE =
  'Please, enter valid NA national phone number';
export const MAX_LATITUDE = 90.0;
export const MIN_LATITUDE = -90.0;
export const MAX_LONGITUDE = 180.0;
export const MIN_LONGITUDE = -180.0;
export const ROBOT_ERRORS = [
  'Validation failed: This delivery is outside of our delivery radius',
  'No Robots Available for this Delivery',
  'Robot is outside of coverage zone',
  'The assigned robot cannot complete this order. Please choose another one',
  'Robot can not be used for this customer',
  // 'Choose another robot with valid battery'
  //Todo: uncomment changes of RS-239 after adding changes from back-end
];
export const ROBOT_ERROR_OUTSIDE_AREA = 'must be within the service area';
export const ERROR_STATUSES = {
  400: 'validationError',
  403: 'validationError',
  401: 'authorizationError',
};

export const austinCoordinates = {
  lat: 30.2672,
  lng: -97.7431,
};

export const DEFAULT_ZOOM = 11;
export const DEFAULT_ZOOM_IN = 15;

//TEMPORARY CONSTANT UNTIL REQUEST THAT RETURNS LIST OF STATUSES IS NOT READY
export const deliverySelectOptions = [
  { key: 'hailed', value: 'hailed' },
  { key: 'enroute_pickup', value: 'enroute_pickup' },
  { key: 'at_pickup', value: 'at_pickup' },
  { key: 'enroute_dropoff', value: 'enroute_dropoff' },
  { key: 'near_dropoff', value: 'near_dropoff' },
  { key: 'at_dropoff', value: 'at_dropoff' },
  { key: 'completed', value: 'completed' },
  { key: 'cancelled', value: 'cancelled' },
];

export const deliveryStatesCategories = {
  active: [
    'reserved',
    'hailed',
    'enroute_pickup',
    'at_pickup',
    'enroute_dropoff',
    'near_dropoff',
    'at_dropoff',
  ],
  completed: ['expired', 'completed'],
  cancelled: ['cancelled'],
};

export const scheduledStatus = 'scheduled';

export const UPDATE_REQUEST_INTERVAL = 60 * 1000;
export const FILTERS = 'filters';

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
  filter: '',
};
