export const robotsMapSettings = {
  mapWrapper: {
    style: { width: '100%', height: '100%', position: 'relative', flexGrow: 1 },
  },
  mapZoom: {
    zoom: 13,
  },
  mapTitle: {
    style: {
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
};
