import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Form, Input, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { every, isEmpty } from 'lodash';
import {
  checkSpace,
  compose,
  mapEntitiesForSelectOptions,
} from '../../../services/helpers';
import { LAT_REGEX, LNG_REGEX } from "../../../common/constants";

const EditSubFleetForm = ({
                            customersStore,
                            robotsStore,
                            fleetsStore,
                            subFleetsStore,
                            form,
                          }) => {
  const { robotsByFleetIdData, getByFleetId: getRobotsByFleetId } = robotsStore;
  const {
    customersByFleet,
    getByFleetName: getCustomersByFleetName,
    dropCustomersByIds,
  } = customersStore;
  const { fleets, getAll: getAllFleets } = fleetsStore;
  const { selectedSubFleet } = subFleetsStore;

  const [areCoordinatesRequired, setAreCoordinatesRequired] = useState(false);

  useEffect(() => {
    if (!fleets.length) getAllFleets();
  }, [getAllFleets, fleets]);

  // Clear form fields on unmount
  useEffect(() => {
    setAreCoordinatesRequired(!every(form.getFieldsValue(['latitude', 'longitude']), isEmpty))
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    getRobotsByFleetId(selectedSubFleet.fleet_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedFleetName = useCallback(() => {
    if (fleets.length && selectedSubFleet) {
      const selectedFleet = fleets.find(
        f => f.id === selectedSubFleet.fleet_id
      );
      return selectedFleet.name;
    }
  }, [fleets, selectedSubFleet]);

  useEffect(() => {
    const selectedFleetName = getSelectedFleetName();
    getCustomersByFleetName(selectedFleetName);

    return () => {
      dropCustomersByIds();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredCustomersInfo = useMemo(
    () => customersByFleet.map(mapEntitiesForSelectOptions),
    [customersByFleet]
  );

  const filteredRobotsInfo = useMemo(
    () => robotsByFleetIdData.map(mapEntitiesForSelectOptions),
    [robotsByFleetIdData]
  );

  const filteredFleetsInfo = useMemo(
    () => fleets.map(mapEntitiesForSelectOptions),
    [fleets]
  );

  const onSelectFleet = (id, info) => {
    if (id && info) {
      getCustomersByFleetName(info.label);
      getRobotsByFleetId(id);
      form.setFieldsValue({
        customer_ids: [],
        robot_ids: [],
      });
    }
  };

  const handleChange = (e) => {
    setAreCoordinatesRequired(
      e.target.value ? true : !every(form.getFieldsValue(['latitude', 'longitude']), isEmpty)
    )
    form.validateFields(['latitude', 'longitude'], { force: true });
  }

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      autoComplete="off"
    >
      <Form.Item
        label="Subfleet name"
        name="name"
        initialValue={selectedSubFleet.name}
        rules={[
          { required: true, message: 'Please input your subfleet name!' },
        ]}
      >
        <Input placeholder="Enter subfleet name" />
      </Form.Item>

      <Form.Item
        label="Select fleet"
        name="fleet_id"
        initialValue={selectedSubFleet.fleet_id}
        rules={[{ required: true, message: 'Please select fleet!' }]}
      >
        <Select
          showSearch
          placeholder="Select a fleet"
          optionFilterProp="children"
          onChange={onSelectFleet}
          options={filteredFleetsInfo}
          getPopupContainer={trigger => trigger.parentNode}
        />
      </Form.Item>
      <Form.Item
        label="Add customers"
        name="customer_ids"
        initialValue={selectedSubFleet.customer_ids}
        rules={[{ required: true, message: 'Please add customers!' }]}
      >
        <Select
          mode="multiple"
          placeholder="Select customers"
          getPopupContainer={trigger => trigger.parentNode}
          value={selectedSubFleet.customer_ids}
          options={filteredCustomersInfo}
        />
      </Form.Item>
      <Form.Item
        label="Add robots"
        name="robot_ids"
        initialValue={selectedSubFleet.robot_ids}
      >
        <Select
          mode="multiple"
          placeholder="Add robots"
          getPopupContainer={trigger => trigger.parentNode}
          options={filteredRobotsInfo}
        />
      </Form.Item>
      <Form.Item
        label="Slack link"
        name="slack_url"
        initialValue={selectedSubFleet.slack_url}
        tooltip="Please insert the slack channel link"
        rules={[checkSpace]}
      >
        <Input placeholder="Slack link (optional)" />
      </Form.Item>
      <Form.Item
        label="Latitude"
        name="latitude"
        initialValue={selectedSubFleet.latitude}
        rules={[
          {
            validator: (_, value) =>
              !value ?
                !areCoordinatesRequired ? Promise.resolve() : Promise.reject('Please, enter latitude')
                :
                LAT_REGEX.test(value)
                  ? Promise.resolve(value)
                  : Promise.reject('Please, enter correct latitude')
          },
        ]}
      >
        <Input placeholder="Enter latitude" onChange={handleChange} />
      </Form.Item>
      <Form.Item
        label="Longitude"
        name="longitude"
        initialValue={selectedSubFleet.longitude}
        rules={[
          {
            validator: (_, value) =>
              !value ?
                !areCoordinatesRequired ? Promise.resolve() : Promise.reject('Please, enter longitude')
                :
                LNG_REGEX.test(value)
                  ? Promise.resolve(value)
                  : Promise.reject('Please, enter correct longitude')
          },
        ]}
      >
        <Input placeholder="Enter longitude" onChange={handleChange} />
      </Form.Item>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
    robotsStore: rootStore.robotsStore,
    fleetsStore: rootStore.fleetsStore,
    subFleetsStore: rootStore.subFleetsStore,
  })),
  observer
)(EditSubFleetForm);
