import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col,
  Form,
  Tabs,
  Select,
  Badge,
  Steps,
  Collapse,
  Typography,
  Space,
} from 'antd';

import useModal from 'common/hooks/useModal';
import {
  compose,
  mapEntitiesForSelectOptions,
  normalizeOrderDetails,
  normalizeDeliveryHistory,
} from 'services/helpers';

import Modal from 'components/modal';
import Button from 'components/button';
import Search from 'components/search';
import MoreDropdown from 'components/more-dropdown';
import { Table } from 'components';
import { HabitatOrderForm, OrderForm } from 'components/forms';
import OrderStateForm from 'components/forms/orderState/order-state-form';
import { scheduledStatus } from '../../common/constants';
import {
  StyledTable,
  PageActionBar,
  RangePicker,
  TopActionBar,
} from './styled';

import {
  tableActions,
  orders,
  modals,
  reviewOrderColumns,
  selectStyles,
  utcToCurrentTime,
} from './settings';

const {
  rangePicker: { pickerTitle, picker },
  resetSort,
  tabs: { active, completed, canceled, scheduled, rejected },
  habitatOrder,
} = orders;
const {
  editOrder: editOrderModal,
  habitatOrderModal,
  editStatus,
  reviewOrder,
  robotReassignModal,
  deliveryHistoryModal,
} = modals;
const {
  orderDetails,
  updateOrder,
  updateOrderStatus,
  robotReassign,
  deliveryHistory,
} = tableActions;
const { TabPane } = Tabs;

const Orders = ({
  ordersStore,
  rejectedOrdersStore,
  subFleetsStore,
  errorStore,
  customersStore,
  robotsStore,
  cableStore,
  deliveryHistoryStore,
}) => {
  const {
    orders,
    total,
    order,
    getAll: getAllOrders,
    onGetAll,
    search,
    findOne,
    setSearch,
    searchValue,
    changeOrderState,
    editOrder,
    pagination,
    dropPagination,
    resetSelectedOrder,
    searchByDateRange,
    changePagination,
    setStatus,
    selectedDateRange,
    createHabitatOrder,
    state: orderStatus,
    columns,
    activeColumns,
    completedColumns,
    scheduledColumns,
    onRobotReassign,
    scheduledTotalCount,
    resetOrdersStore,
  } = ordersStore;

  const {
    rejectedColumns,
    orders: rejectedOrders,
    getAll: getAllRejectedOrders,
    total: rejectedTotal,
    pagination: rejectedPagination,
    dropPagination: rejectedDropPagination,
    changePagination: rejectedChangePagination,
  } = rejectedOrdersStore;

  const {
    getById: getHistoryById,
    historyData,
    resetHistory,
  } = deliveryHistoryStore;

  const { Step } = Steps;
  const { Panel } = Collapse;
  const {
    dropoff: { location },
  } = order;
  const [selectedOrderID, setSelectedOrderID] = useState();
  const [currentStatus, setCurrentStatus] = useState('active');
  const orderStatusRef = useRef(orderStatus);

  const {
    getAll: getAllSubFleets,
    allSubFleets,
    onSelectSubFleet,
    selectedSubFleet,
    resetSelectedSubFleet,
  } = subFleetsStore;

  const {
    resetAvailableRobotsByDistance,
    selectedRobot,
    isSkipRobotAddToQueue,
    resetSkipRobotAddToQueue,
  } = robotsStore;

  const { resetRobotError } = errorStore;

  const { customer, resetSelectedCustomer } = customersStore;

  const [form] = Form.useForm();
  const [stateForm] = Form.useForm();
  const [createOrderForm] = Form.useForm();

  const [
    isUpdateModalShown,
    onSetIsUpdateModalShown,
    onUpdateOkAction,
    onUpdateCancelAction,
  ] = useModal();

  const [
    isReviewModalShown,
    onSetIsReviewModalShown,
    onReviewOkAction,
    onReviewCancelAction,
  ] = useModal();

  const [
    isUpdateStatusModalShown,
    onSetIsUpdateStatusModalShown,
    onUpdateStatusOkAction,
    onUpdateStatusCancelAction,
  ] = useModal();

  const [
    isCreateOrderModalShown,
    onSetIsCreateOrderModalShown,
    onCreateOrderOkAction,
    onCreateOrderCancelAction,
  ] = useModal();

  const [
    isRobotErrorModalShown,
    onSetIsRobotErrorModalShown,
    onRobotErrorOkAction,
    onRobotErrorAction,
  ] = useModal();

  const [
    isRobotErrorEditOrderModalShown,
    onSetIsRobotErrorEditOrderModalShown,
    onRobotErrorEditOrderOkAction,
    onRobotErrorEditOrderAction,
  ] = useModal();

  const [
    isRobotReassignModalShown,
    onSetIsRobotReassignModalShown,
    onRobotReassignOkAction,
    onRobotReassignCancelAction,
  ] = useModal();

  const [
    isDeliveryHistoryModalShown,
    onSetIsDeliveryHistoryModalShown,
    onDeliveryHistoryOkAction,
    onDeliveryHistoryCancelAction,
  ] = useModal();

  /*  const [
    isRobotReassignErrorModalShown,
    onSetIsRobotReassignErrorModalShown,
    onRobotReassignErrorOkAction,
    onRobotReassignErrorCancelAction,
  ] = useModal();*/
  //Todo: uncomment changes of RS-239 after adding changes from back-end

  const getMenuItems = row => [
    {
      type: updateOrder.type,
      icon: updateOrder.icon,
      onClick: onSetIsUpdateModalShown(() => {
        findOne(row.id);
      }),
      text: updateOrder.tooltip,
      disabled: orderStatus === scheduledStatus,
    },
    {
      type: orderDetails.type,
      icon: orderDetails.icon,
      onClick: onSetIsReviewModalShown(() => {
        findOne(row.id);
      }),
      text: orderDetails.tooltip,
    },
    {
      type: updateOrderStatus.type,
      icon: updateOrderStatus.icon,
      onClick: onSetIsUpdateStatusModalShown(() => {
        findOne(row.id);
      }),
      text: updateOrderStatus.tooltip,
    },
    {
      type: robotReassign.type,
      icon: robotReassign.icon,
      onClick: onSetIsRobotReassignModalShown(() => {
        findOne(row.id);
      }),
      text: robotReassign.tooltip,
      disabled: orderStatus !== 'active' || orderStatus === scheduledStatus,
    },
    {
      type: deliveryHistory.type,
      icon: deliveryHistory.icon,
      onClick: onSetIsDeliveryHistoryModalShown(() => {
        getHistoryById(row.id);
        setSelectedOrderID(row.order_id);
      }),
      text: deliveryHistory.tooltip,
    },
  ];

  const renderActions = row => <MoreDropdown menuItems={getMenuItems(row)} />;

  const renderScheduledTab = () => {
    return (
      <div style={{ display: 'flex', gap: 5, marginRight: '-5px' }}>
        {scheduled.tab}
        <Badge count={scheduledTotalCount}></Badge>
      </div>
    );
  };

  const renderTabHelper = () => {
    return scheduledTotalCount ? (
      <div>
        <Badge count="n"></Badge>{' '}
        <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)' }}>
          Total number of scheduled orders
        </span>
      </div>
    ) : (
      <div />
    );
  };

  const onUpdate = () => {
    form.validateFields().then(async values => {
      await editOrder({ ...values });
      if (!errorStore.robotError) {
        getAllOrders();
        onUpdateOkAction()();
      } else {
        onSetIsRobotErrorEditOrderModalShown()();
      }
    });
  };

  const onUpdateSkippingValidations = () => {
    form.validateFields().then(
      async values => {
        await editOrder({ ...values }, true);
        resetRobotError.bind(errorStore)();
        if (!errorStore.robotError) {
          onRobotErrorEditOrderOkAction()();
          onUpdateOkAction()();
          getAllOrders();
        }
      },
      async () => {
        resetRobotError.bind(errorStore)();
      }
    );
  };

  const onHabitatOrderCreate = () => {
    createOrderForm.validateFields().then(async values => {
      await createHabitatOrder({
        ...values,
        subFleetId: selectedSubFleet.id,
        customerName: customer.contact_name,
        selectedRobot: isSkipRobotAddToQueue ? null : selectedRobot,
      });
      if (!errorStore.robotError) {
        onCreateOrderOkAction()();
        resetSelectedSubFleet();
        resetAvailableRobotsByDistance();
        resetSkipRobotAddToQueue();
        resetSelectedOrder();
        resetSelectedCustomer();
        dropPagination();
        getAllOrders();
      } else {
        onSetIsRobotErrorModalShown()();
      }
    });
  };

  const onHabitatOrderCreateSkippingValidations = () => {
    createOrderForm.validateFields().then(
      async values => {
        await createHabitatOrder(
          {
            ...values,
            subFleetId: selectedSubFleet.id,
            customerName: customer.contact_name,
            selectedRobot,
          },
          true
        );
        resetRobotError.bind(errorStore)();
        if (!errorStore.robotError) {
          onRobotErrorOkAction()();
          onCreateOrderOkAction()();
          resetSelectedSubFleet();
          resetAvailableRobotsByDistance();
          resetSkipRobotAddToQueue();
          getAllOrders();
        } else {
          console.log(errorStore.robotError);
        }
      },
      async () => {
        resetRobotError.bind(errorStore)();
      }
    );
  };

  const onHabitatOrderCancel = () => {
    onCreateOrderCancelAction(() => {
      resetSelectedOrder();
      resetSelectedSubFleet();
      resetSelectedCustomer();
      resetAvailableRobotsByDistance();
      resetSkipRobotAddToQueue();
    })();
  };

  const onChangeState = () =>
    stateForm.validateFields().then(async values => {
      const error = await changeOrderState(values);
      if (!error) {
        onUpdateStatusOkAction()();
        getAllOrders();
      }
    });

  const onTabChange = tab => {
    dropPagination(tab);
    rejectedDropPagination();
    setCurrentStatus(tab);
    setStatus(tab);
    if (tab !== 'rejected') {
      orderStatusRef.current = tab;
    }
  };

  const onChangeRobot = async () => {
    await onRobotReassign(order.id);
    onRobotReassignOkAction()();
    getAllOrders();
  };

  /*const onChangeRobotSkippingValidations = () => {
    resetRobotError.bind(errorStore)();
    reassignRobotForm.validateFields().then(async ({ current_robot }) => {
      await onRobotReassign(order.id, current_robot, true);
      if (!errorStore.robotError) {
        onRobotReassignErrorOkAction()();
        onRobotReassignOkAction()();
        if (orderStatus === 'active') {
          getAllActiveOrders();
        }
        getAllOrders();
      } else {
        console.log(errorStore.robotError);
      }
    });
  }*/
  //Todo: uncomment changes of RS-239 after adding changes from back-end

  const handleResetSort = () => {
    dropPagination();
    getAllOrders();
  };

  const checkHabitatOrderEnabledToSubmit = () => {
    if (isSkipRobotAddToQueue) return false;

    return !selectedRobot;
  };

  const subFleetOptions = useMemo(
    () => allSubFleets.map(mapEntitiesForSelectOptions),
    [allSubFleets]
  );

  useEffect(() => {
    if (currentStatus === 'rejected') return;
    getAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllOrders, orderStatus, getAllSubFleets]);

  useEffect(() => {
    getAllSubFleets();
  }, [getAllSubFleets]);

  useEffect(() => {
    if (currentStatus === 'rejected') {
      getAllRejectedOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  useEffect(() => {
    const onReceive = updatedOrders => {
      onGetAll(updatedOrders[orderStatusRef.current] || {});
    };
    cableStore.createConnection('OrdersChannel', onReceive);

    return () => {
      cableStore.disconnect('OrdersChannel');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSteps = useCallback(() => {
    const data = historyData.map(history => (
      <Step
        title={<Typography.Text strong>{history.title}</Typography.Text>}
        key={history.title}
        subTitle={utcToCurrentTime(history.created_at)}
        status={history.is_success ? 'finish' : 'error'}
        description={
          <Collapse size="small" bordered={false}>
            <Panel header="More Details" key="1" showArrow={false} ghost>
              <Table
                isModal
                showHeader={false}
                columns={reviewOrderColumns}
                data={normalizeDeliveryHistory(history)}
                pagination={false}
                rowKey={record => record.key}
              />
            </Panel>
          </Collapse>
        }
      />
    ));
    return data;
  }, [historyData]);

  useEffect(() => {
    return () => {
      resetOrdersStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        title={`Order for: ${selectedSubFleet.name}`}
        visible={isCreateOrderModalShown}
        width={habitatOrderModal.width}
        destroyOnClose
        onOk={onHabitatOrderCreate}
        onCancel={onHabitatOrderCancel}
        className="order-modal"
        okButtonProps={{
          disabled: checkHabitatOrderEnabledToSubmit(),
        }}
      >
        <HabitatOrderForm form={createOrderForm} />
      </Modal>
      <Modal
        title="Warning"
        width="30%"
        visible={isRobotErrorModalShown}
        onOk={onHabitatOrderCreateSkippingValidations}
        onCancel={onRobotErrorAction(resetRobotError.bind(errorStore))}
        destroyOnClose
      >
        <Space direction="vertical" size="middle">
          <div>{errorStore.robotError}</div>
          Are you sure you want to create this order?
        </Space>
      </Modal>

      <Modal
        title="Warning"
        width="30%"
        visible={isRobotErrorEditOrderModalShown}
        onOk={onUpdateSkippingValidations}
        onCancel={onRobotErrorEditOrderAction(resetRobotError.bind(errorStore))}
        destroyOnClose
      >
        <Space direction="vertical" size="middle">
          <div>{errorStore.robotError}</div>
          Are you sure you want to edit this order?
        </Space>
      </Modal>
      <Modal
        title={editOrderModal.title}
        visible={isUpdateModalShown}
        width={editOrderModal.width}
        destroyOnClose
        onOk={onUpdate}
        onCancel={onUpdateCancelAction(() => resetSelectedOrder())}
        okButtonProps={{ disabled: !location.latitude && !location.longitude }}
      >
        <OrderForm form={form} />
      </Modal>
      <Modal
        title={reviewOrder.title}
        visible={isReviewModalShown}
        width={reviewOrder.width}
        destroyOnClose
        onOk={onReviewOkAction()}
        onCancel={onReviewCancelAction(() => resetSelectedOrder())}
      >
        <Table
          isModal
          showHeader={false}
          columns={reviewOrderColumns}
          data={normalizeOrderDetails(order)}
          pagination={false}
        />
      </Modal>
      <Modal
        title={editStatus.title}
        visible={isUpdateStatusModalShown}
        width={editStatus.width}
        destroyOnClose
        onOk={onChangeState}
        onCancel={onUpdateStatusCancelAction(() => resetSelectedOrder())}
      >
        <OrderStateForm
          state={order.current_state}
          form={stateForm}
          orderStatus={orderStatus}
        />
      </Modal>
      <Modal
        title={robotReassignModal.title}
        visible={isRobotReassignModalShown}
        width={robotReassignModal.width}
        destroyOnClose
        onOk={onChangeRobot}
        onCancel={onRobotReassignCancelAction(() => resetSelectedOrder())}
      >
        <Space direction="vertical" size="middle">
          <div>
            Clicking &#x2018;OK&#x2019; will force REVserver to automatically
            search for a new robot/locker until one is available. You will not
            be able to manually assign the order to another robot/locker. This
            robot won`t be assigned to the same delivery again.
          </div>
          Are you sure you want to proceed with robot reassignment?
        </Space>
      </Modal>

      <Modal
        title={`${deliveryHistoryModal.title} for Order ID: ${selectedOrderID}`}
        visible={isDeliveryHistoryModalShown}
        width={deliveryHistoryModal.width}
        destroyOnClose
        onOk={onDeliveryHistoryOkAction(() => resetHistory())}
        onCancel={onDeliveryHistoryCancelAction(() => resetHistory())}
      >
        <Steps progressDot current={historyData.length} direction="vertical">
          {renderSteps()}
        </Steps>
      </Modal>
      {/*<Modal
        title="Robot Warning"
        width='30%'
        visible={isRobotReassignErrorModalShown}
        onOk={onChangeRobotSkippingValidations}
        onCancel={onRobotReassignErrorCancelAction(resetRobotError.bind(errorStore))}
        destroyOnClose
      >
        Are you sure you want to assign this robot? It may cause some issues
      </Modal>*/}
      {/* Todo: uncomment changes of RS-239 after adding changes from back-end */}
      <TopActionBar>
        <div style={habitatOrder.style}>
          <Select
            placeholder="Select the subfleet"
            style={selectStyles.style}
            onChange={onSelectSubFleet}
            options={subFleetOptions}
            value={selectedSubFleet.id}
          />
          <Button
            shape={habitatOrder.shape}
            icon={habitatOrder.icon}
            type={habitatOrder.type}
            onClick={onSetIsCreateOrderModalShown()}
            disabled={!selectedSubFleet.id}
          >
            {habitatOrder.title}
          </Button>
        </div>
      </TopActionBar>
      <Search
        onChange={setSearch}
        onSearch={value => {
          dropPagination();
          search(value);
        }}
        defaultValue={searchValue}
      />
      <PageActionBar>
        <Col>
          <span style={pickerTitle.style}>{pickerTitle.text} </span>
          <RangePicker
            style={picker.style}
            ranges={picker.ranges}
            value={selectedDateRange}
            onChange={(_, dates) => {
              searchByDateRange(dates);
            }}
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleResetSort}
            shape={resetSort.shape}
          >
            {resetSort.title}
          </Button>
        </Col>
      </PageActionBar>
      <Tabs
        // activeKey={orderStatus}
        activeKey={currentStatus}
        type="card"
        onChange={tab => onTabChange(tab)}
        tabBarExtraContent={{ right: renderTabHelper() }}
      >
        <TabPane tab={active.tab} key={active.key}>
          <StyledTable
            columns={activeColumns}
            data={orders}
            className="no-shadow-table"
            renderActions={renderActions}
            actionsName="Menu"
            actionsWidth={50}
            actionsFirst
            scroll={{ x: 900 }}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
          />
        </TabPane>
        <TabPane tab={completed.tab} key={completed.key}>
          <Table
            columns={completedColumns}
            data={orders}
            className="no-shadow-table"
            renderActions={renderActions}
            actionsName="Menu"
            actionsWidth={50}
            actionsFirst
            scroll={{ x: 900 }}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
          />
        </TabPane>
        <TabPane tab={canceled.tab} key={canceled.key}>
          <Table
            columns={columns}
            data={orders}
            className="no-shadow-table"
            renderActions={renderActions}
            actionsName="Menu"
            actionsWidth={50}
            actionsFirst
            scroll={{ x: 900 }}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
          />
        </TabPane>
        <TabPane tab={renderScheduledTab()} key={scheduled.key}>
          <Table
            columns={scheduledColumns}
            data={orders}
            className="no-shadow-table"
            renderActions={renderActions}
            actionsName="Menu"
            actionsWidth={50}
            actionsFirst
            scroll={{ x: 900 }}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
            rowKey="order_id"
          />
        </TabPane>
        <TabPane tab={rejected.tab} key={rejected.key}>
          <Table
            columns={rejectedColumns}
            data={rejectedOrders}
            pagination={rejectedPagination}
            paginationTotal={rejectedTotal}
            onChange={rejectedChangePagination}
            rowKey={item => item.history_id}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    ordersStore: rootStore.ordersStore,
    rejectedOrdersStore: rootStore.rejectedOrdersStore,
    subFleetsStore: rootStore.subFleetsStore,
    errorStore: rootStore.errorStore,
    customersStore: rootStore.customersStore,
    robotsStore: rootStore.robotsStore,
    cableStore: rootStore.cableStore,
    deliveryHistoryStore: rootStore.deliveryHistoryStore,
  })),
  observer
)(Orders);
