import { observable, action, computed } from 'mobx';
import { message } from 'antd';

import Auth from './Auth';
import { api, urls } from '../services';
import { buildParams } from 'services/helpers';
import Admins from './Admins';
import Error from './Error';
import Orders from './Orders';
import Customers from './Customers';
import Robots from './Robots';
import SubFleets from './SubFleet';
import Fleets from './Fleets';
import ObserversDashboard from './ObserversDashboard';
import Cable from './Cable';
import DeliveryHistory from './DeliveryHistory';
import RejectedOrders from './RejectedOrders';
import { DEFAULT_PAGINATION } from '../common/constants';

class Root {
  message = message;
  urls = urls;
  @observable rawVersionsPagination = DEFAULT_PAGINATION;
  @observable rawVersionsTotal;
  @observable status = 'idle';
  @observable hasError = false;
  @observable versions = [];
  @observable rawSettings = {};
  @observable rawSettingsFailedReason = {};
  @observable rawSettingsRobotsTypes = [];
  @observable rawSettingsFleets = [];
  @observable rawSettingsSubFleets = [];
  @observable rawSettingsExternalIntegrations = [];

  constructor(routingStore) {
    this.routingStore = routingStore;
    this.authStore = new Auth(this);
    this.adminsStore = new Admins(this);
    this.ordersStore = new Orders(this);
    this.customersStore = new Customers(this);
    this.robotsStore = new Robots(this);
    this.errorStore = new Error(this);
    this.subFleetsStore = new SubFleets(this);
    this.fleetsStore = new Fleets(this);
    this.observerDashboard = new ObserversDashboard(this);
    this.cableStore = new Cable(this);
    this.deliveryHistoryStore = new DeliveryHistory(this);
    this.rejectedOrdersStore = new RejectedOrders(this);
  }

  abortRequest() {
    api.abort();
  }

  @action async makeRequest(cb, method, url, body, headers, responseType) {
    this.status = 'pending';

    const [response, error] = await api.makeRequest({
      method,
      url,
      data: method === 'get' || method === 'del' ? {} : body,
      headers,
      responseType,
    });
    if (error) {
      if (!error.status) {
        setTimeout(() => {
          console.log(error);
          this.status = 'idle';
        }, 20000);
      }
      this.errorStore.handleError(error);
      this.status = 'idle';
      return error;
    }
    this.status = 'idle';

    if (response && responseType) {
      return cb(response);
    }

    if (response) {
      return await cb(response, body);
    }
  }

  @computed get pending() {
    return this.status === 'pending';
  }

  @action.bound async getAllVersions() {
    const params = buildParams({
      ...this.rawVersionsPagination,
    });

    const {
      getAll: { method, url },
    } = this.urls.versions;

    return this.makeRequest(this.onGetAllVersions, method, `${url}${params}`);
  }

  @action.bound onGetAllVersions(data) {
    this.versions = data.items;
    this.rawVersionsTotal = data.pagination.total_count;
  }

  @action.bound async changeVersionsPagination(pagination) {
    this.rawVersionsPagination = {
      ...pagination,
    };

    this.getAllVersions();
  }

  @action.bound dropVersionsPagination() {
    this.rawVersionsPagination = DEFAULT_PAGINATION;
  }

  @action.bound async getSettings() {
    const {
      get: { method, url },
    } = this.urls.settings;

    return this.makeRequest(this.onGetSettings, method, url);
  }

  @action.bound onGetSettings(data) {
    this.rawSettings = data;
    this.rawSettingsFailedReason = data.failed_reason;
    this.rawSettingsRobotsTypes = data.robots_types;
    this.rawSettingsFleets = data.fleet_names;
    this.rawSettingsSubFleets = data.sub_fleets;
    this.rawSettingsExternalIntegrations = data.external_integrations;
  }

  @action.bound async addVersion(data) {
    const { method, url } = this.urls.versions.create;
    return this.makeRequest(this.onAddVersion, method, url, data);
  }

  @action.bound onAddVersion() {
    this.dropVersionsPagination();
    this.getAllVersions();
  }

  @computed get lastVersion() {
    return this.versions.length ? this.versions[0] : null;
  }

  @computed get settings() {
    return this.rawSettings;
  }

  @computed get settingsFailedReason() {
    return this.rawSettingsFailedReason;
  }

  @computed get settingsRobotsTypes() {
    return this.rawSettingsRobotsTypes;
  }

  @computed get versionsTotal() {
    return this.rawVersionsTotal;
  }

  @computed get versionsPagination() {
    return this.rawVersionsPagination;
  }
  
  @computed get settingsFleets() {
    return this.rawSettingsFleets;
  }

  @computed get settingsSubFleets() {
    return this.rawSettingsSubFleets;
  }

  @computed get settingsExternalIntegrations() {
    return this.rawSettingsExternalIntegrations
  }
}

export default Root;
