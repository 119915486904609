import { observable, action, computed } from 'mobx';
import { buildParams } from '../services/helpers';
import { DEFAULT_PAGINATION } from '../common/constants';

class Admins {
  @observable rawAdmins = [];
  @observable totalCount = '';
  @observable rawPagination = DEFAULT_PAGINATION;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound onGetAll(data) {
    this.rawAdmins = data.items;
    this.totalCount = data.pagination.total_count;
  }

  @action.bound async getAll() {
    const params = buildParams({
      ...this.rawPagination,
    });
    const {
      getAll: { method, url },
    } = this.rootStore.urls.admins;

    return this.rootStore.makeRequest(this.onGetAll, method, `${url}${params}`);
  }

  @action.bound async changePagination(pagination) {
    this.rawPagination = {
      ...pagination,
    };
    this.getAll();
  }

  @computed get admins() {
    return this.rawAdmins;
  }

  @computed get total() {
    return this.totalCount;
  }

  @computed get pagination() {
    return this.rawPagination;
  }
}

export default Admins;
