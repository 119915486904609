import { observer } from 'mobx-react';
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import * as _ from 'lodash';

import { apiKey, autocomplete } from './settings';

const GoogleAutoComplete = ({
  handlersStore,
  options = { types: ['address'], fields: ['ALL'] },
  onChange
}) => {
  const { setAddress, setLocation, address } = handlersStore;
  const handleAddressChange = e => {
    setAddress(e.target.value);
    if (typeof onChange === 'function') onChange(e.target.value);
    setLocation({
      latitude: '',
      longitude: '',
    });
  };

  const handlePlaceSelected = data => {
    const getLatitude = _.get(data, 'geometry.location.lat', null);
    const getLongitude = _.get(data, 'geometry.location.lng', null);

    setAddress(data.formatted_address);

    if (
      typeof getLatitude === 'function' &&
      typeof getLongitude === 'function'
    ) {
      setLocation({
        latitude: getLatitude(),
        longitude: getLongitude(),
      });
    }
  };

  return (
    <>
      <Autocomplete
        style={autocomplete.style}
        apiKey={apiKey}
        onPlaceSelected={handlePlaceSelected}
        onChange={handleAddressChange}
        value={address}
        options={options}
        className="ant-input"
      />
    </>
  );
};

export default observer(GoogleAutoComplete);
