import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { AsYouType } from 'libphonenumber-js';

const { InfoCircleOutlined } = require('@ant-design/icons');

export const tabs = {
  active: { tab: 'Active', key: 'active' },
  completed: { tab: 'Completed', key: 'completed' },
  canceled: { tab: 'Canceled', key: 'revoke' },
};

export const columns = [
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
    align: 'center',
  },
  {
    title: 'Robot Name',
    dataIndex: 'robot_name',
    key: 'robot_name',
    align: 'center',
    render: text => text || '-',
  },
  {
    title: 'Order Status',
    dataIndex: 'current_state',
    key: 'current_state',
    align: 'center',
  },
  {
    title: 'Dropoff Name',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_name',
    align: 'center',
    render: dropoff => dropoff.contact_name,
  },
  {
    title: 'Dropoff Phone',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_phone',
    align: 'center',
    render: ({ contact_phone }) => {
      return contact_phone ? new AsYouType('US').input(contact_phone) : '';
    },
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at',
    render: created_at => utcToCurrentTime(created_at, true),
  },
  {
    title: 'Dropoff address',
    dataIndex: 'dropoff',
    align: 'center',
    render: dropoff => dropoff.address,
  },
];

export const activeColumns = [
  {
    key: 'robot_name',
    title: 'Robot Name',
    render: (_, record) => ({
      children: (
        <div style={{ height: '100%' }}>
          <div style={{ fontWeight: '600' }}>{record.robot_name || '-'}</div>
          {record.deliveries_count > 1 && (
            <Tooltip title={'No. of batched orders'}>
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '0',
                  fontSize: '12px',
                  backgroundColor: 'rgb(115 184 215)',
                  padding: '0 5px',
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                <InfoCircleOutlined /> {record.deliveries_count} orders
              </div>
            </Tooltip>
          )}
        </div>
      ),
      props: {
        rowSpan: record.span,
      },
    }),
    sorter: true,
    align: 'center',
  },
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
    align: 'center',
  },
  {
    title: 'Locker',
    dataIndex: 'locker_name',
    key: 'locker_name',
    align: 'center',
    render: locker => locker || '-',
  },
  {
    title: 'Order Status',
    dataIndex: 'current_state',
    key: 'current_state',
    align: 'center',
  },
  {
    title: 'Dropoff Name',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_name',
    align: 'center',
    render: dropoff => dropoff.contact_name,
  },
  {
    title: 'Dropoff Phone',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_phone',
    align: 'center',
    render: ({ contact_phone }) => {
      return contact_phone ? new AsYouType('US').input(contact_phone) : '';
    },
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at',
    render: created_at => utcToCurrentTime(created_at, true),
  },
  {
    title: 'Dropoff address',
    dataIndex: 'dropoff',
    align: 'center',
    render: dropoff => dropoff.address,
  },
  {
    title: 'Delivery Time',
    dataIndex: 'dropoff',
    key: 'no_earlier_than',
    align: 'center',
    width: '130px',
    render: (dropoff) => {
      return (
        <>
          <div>
            {dropoff.estimated_arrival.minimum || '-'}
          </div>
          <div>{dropoff.estimated_arrival.maximum || '-'}</div>
        </>
      )
    },
  },
];

export const completedColumn = {
  title: 'Completed At',
  dataIndex: 'completed_at',
  align: 'center',
  key: 'completed_at',
  render: completed_at => utcToCurrentTime(completed_at, true),
};

export const utcToCurrentTime = (time, br, format = 'hh:mm:ss A') => {
  const gettingDate = new Date(time);
  const newDate = new Date(
    gettingDate.getTime() - gettingDate.getTimezoneOffset() * 60 * 1000
  );
  return (
    <>
      <span>{moment(newDate).format('MM-DD-YYYY')} </span>
      {br && <br />}
      <span>{moment(newDate).format(format)}</span>
    </>
  );
};
