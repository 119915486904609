import React from 'react';
import If from '../if';

import { Tip } from './styled';

const LongText = ({
  text = '',
  limit,
  onClick = () => null,
  tipTitle = '',
  showLessTitle = false,
}) => {
  if (text.length <= limit) {
    return (
      <div>
        {text}
        <If condition={showLessTitle}>
          {' '}
          <Tip onClick={onClick}>{tipTitle}</Tip>
        </If>
      </div>
    );
  }

  return (
    <div onClick={onClick}>
      {text.substring(0, limit)}...
      <Tip onClick={onClick}>{tipTitle}</Tip>
    </div>
  );
};

export default LongText;
