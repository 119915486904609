import { action, observable } from 'mobx';
import { createConsumer } from '@rails/actioncable';

const URL = process.env.REACT_APP_ACTION_CABLE_URL;

class Cable {
  @observable rawCable;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.authStore = rootStore.authStore;
  }

  @action.bound createCable () {
    const token = this.authStore.token;

    if (!this.rawCable && token) {
      const createdCable = createConsumer(`${URL}?token=${token}`);
      this.rawCable = createdCable;
      return createdCable;
    }
  }

  @action.bound connect (channelName, onReceive) {
    this.rawCable.subscriptions.create(
      { channel: channelName },
      {
        received(updatedData) {
          onReceive(updatedData);
        },
      }
    );
  }

  @action.bound findSubscription(channelName) {
    const subscriptionList = this.rawCable.subscriptions.findAll(`{"channel":"${channelName}"}`);
    return subscriptionList.length ? subscriptionList[0] : {};
  }

  @action.bound async createConnection(channelName, onReceive) {
    if (!this.rawCable) {
      this.createCable();
    }
    this.connect(channelName, onReceive)
  }

  @action.bound disconnect(channelName) {
    const subscription = this.findSubscription(channelName);
    this.rawCable.subscriptions.remove(subscription);
    this.rawCable.subscriptions.consumer.disconnect();
  }
}

export default Cable;
