import { observable } from 'mobx';

class SubFleet {
  @observable id;
  @observable name;
  @observable fleet_id;
  @observable customer_ids;
  @observable robot_ids;

  constructor(subFleet, rootStore) {
    Object.assign(this, subFleet);
    this.rootStore = rootStore;
  }
}

export default SubFleet;