import React from 'react';
import { Image } from 'antd';

import { StyledLogoWrapper, StyledTitle } from './styled';
import brandLogo from '../../images/refractionLogo.png';

const Logo = ({ size = '' }) => (
  <StyledLogoWrapper size={size} className='logo'>
    <StyledTitle size={size}>
      <Image width={200} src={brandLogo} preview={false} />
    </StyledTitle>
  </StyledLogoWrapper>
);

export default Logo;
