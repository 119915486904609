import { Col } from 'antd';
import styled, { css } from 'styled-components';

const StyledCol = styled(Col);

export const MapContainer = StyledCol(
  ({ height, display, direction, justify }) => css`
    height: ${height};
    display: ${display};
    flex-direction: ${direction};
    justify-content: ${justify};
  `
);
