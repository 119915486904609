import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import {
  GoogleMap,
  LoadScript,
  MarkerClusterer,
  Marker,
} from '@react-google-maps/api';

import {
  changeCoordinatePresigion,
  compose,
  dayAgo,
  openNotification,
} from 'services/helpers';

import { apiKey } from 'components/google-map/settings';
import { austinCoordinates } from 'common/constants';

import { robotsMapSettings } from './settings';

export class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== 'undefined'; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector('body.first-hit-completed'); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error('google api is already presented');
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

const RobotsMap = ({ robotsStore, cableStore }) => {
  const { mapWrapper } = robotsMapSettings;
  const { allRobots, getAllNoPagination, onUpdateAll } = robotsStore;
  const [center, setCenter] = useState(austinCoordinates);

  useEffect(() => {
    getAllNoPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this effect is added to avoid marker disappearing
  useEffect(() => {
    setCenter(center);
  }, [allRobots, center]);

  useEffect(() => {
    cableStore.createConnection('RobotLocationChannel', onUpdateAll);

    return () => {
      cableStore.disconnect('RobotLocationChannel');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const robotsLoc = allRobots.reduce(
    (acc, robot, i, arr) => {
      if (dayAgo(new Date(robot.telemetry_updated_at).getTime())) return acc;
      const isSameExists = arr.some(
        item =>
          item.latitude === robot.latitude && item.longitude === robot.longitude
      );
      if (isSameExists) {
        acc.push({
          ...robot,
          longitude: changeCoordinatePresigion(robot.longitude, i),
        });
        return acc;
      }
      acc.push({ ...robot });
      return acc;
    },
    [allRobots]
  );

  const [mapref, setMapRef] = useState(null);

  const handleOnLoad = map => setMapRef(map);

  const onCenterChanged = () => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  return (
    <>
      <div style={robotsMapSettings.mapTitle.style}>
        <span> All robots with status updates within last 24 hrs.</span>
      </div>
      <LoadScriptOnlyIfNeeded id="robots" googleMapsApiKey={apiKey}>
        <GoogleMap
          onLoad={handleOnLoad}
          onDragEnd={onCenterChanged}
          center={center}
          zoom={13}
          mapContainerStyle={mapWrapper.style}
        >
          <MarkerClusterer maxZoom={18}>
            {clusterer =>
              robotsLoc.map((robot, i) => (
                <Marker
                  key={i}
                  icon={{
                    url: '/robo40.png',
                    scaledSize: new window.google.maps.Size(32, 40),
                  }}
                  title={`Robot name: ${robot.name}\nBattery voltage: ${robot.battery_voltage}`}
                  position={{
                    lat: Number(robot.latitude),
                    lng: Number(robot.longitude),
                  }}
                  clusterer={clusterer}
                  onClick={() =>
                    openNotification({
                      name: robot.name,
                      subFleet: robot.sub_fleet_name,
                      battery: robot.battery_voltage,
                      state: robot.current_state,
                      updated: robot.telemetry_updated_at,
                    })
                  }
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </LoadScriptOnlyIfNeeded>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    robotsStore: rootStore.robotsStore,
    cableStore: rootStore.cableStore,
  })),
  observer
)(RobotsMap);
