import React, { useCallback, useEffect } from 'react';
import { Table as AntdTable } from 'antd';

import { tableRowKey } from './settings';
import { titleToUpperCase } from 'services/helpers';
import Total from './total';

const Table = ({
  isModal = false,
  columns,
  data,
  pagination = { pageSize: 10 },
  paginationTotal,
  onChange,
  rowKey,
  renderActions,
  actionsFixed,
  actionsName,
  actionsWidth,
  actionsFirst,
  ...restProps
}) => {
  const renderColumns = () => {
    if (!renderActions) return columns.map(titleToUpperCase);
    const actionColumn = {
      title: actionsName || 'action',
      key: 'action',
      align: 'center',
      render: (_, row) => renderActions(row),
    };

    if (actionsFixed) actionColumn.fixed = actionsFixed;
    if (actionsWidth) actionColumn.width = actionsWidth;

    const columnsWithActions = actionsFirst
      ? [actionColumn, ...columns]
      : [...columns, actionColumn];

    return columnsWithActions.map(c => {
      if (typeof c.title === 'string') {
        return titleToUpperCase(c);
      }
      return c;
    });
  };

  /** Hack to move Google PAC container following scroll changes */
  const modalWrap = document.querySelector('.ant-modal-wrap');
  const pacInputs = Array.from(
    document.getElementsByClassName('pac-target-input')
  );
  const pacContainers = Array.from(
    document.getElementsByClassName('pac-container')
  );

  const movePacContainer = useCallback(
    ({ pacInputs, pacContainers }) => () => {
      if (pacInputs.length && pacContainers.length) {
        const [activeInput] = pacInputs.filter(
          el => el === document.activeElement
        );
        const [openedPacContainer] = pacContainers.filter(el => {
          return el.style.display !== 'none';
        });

        if (activeInput && openedPacContainer) {
          const { top, height } = activeInput.getBoundingClientRect();
          openedPacContainer.style.top = `${top + height}px`;
        }
      }
    },
    []
  );

  useEffect(() => {
    if (modalWrap) {
      modalWrap.addEventListener(
        'scroll',
        movePacContainer({ pacInputs, pacContainers })
      );
    }

    return () => {
      if (modalWrap) modalWrap.removeEventListener('scroll', movePacContainer);
    };
  }, [modalWrap, pacInputs, pacContainers, movePacContainer]);

  return (
    <>
      <AntdTable
        className={isModal ? 'modal-table' : ''}
        rowKey={rowKey || tableRowKey}
        columns={renderColumns()}
        dataSource={data}
        rowClassName={record =>
          record.rowClassName ? record.rowClassName : null
        }
        onChange={onChange}
        pagination={
          pagination && {
            ...pagination,
            total: paginationTotal,
            showTotal: (_, range) => (
              <Total total={paginationTotal} range={range} />
            ),
          }
        }
        {...restProps}
      />
    </>
  );
};

export default Table;
