import React, { useCallback, useEffect } from 'react';
import { Form, Input, Row, Col, Select, Switch, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { GoogleMap, GoogleAutoComplete, MapContainer } from '../../google-map';
import { apiKey } from '../../google-map/settings';

import {
  compose,
  mapEntitiesForSelectOptions,
} from '../../../services/helpers';
import { checkAddress, orderForm } from './settings';

const {
  layout,
  name,
  address,
  phone,
  notes,
  failedReason,
  mapSettings,
  mapContainerProps,
  chaserPresent,
} = orderForm;

const OrderForm = ({
  rootStore: { settingsFailedReason },
  customersStore,
  ordersStore: { order },
  form,
}) => {
  const { customer } = customersStore;
  const {
    setAddress,
    setLocation,
    dropoff: { location },
  } = order;

  const failedReasonOptions = Object.keys(settingsFailedReason).map(key =>
    mapEntitiesForSelectOptions({
      id: settingsFailedReason[key],
      name: settingsFailedReason[key],
    })
  );

  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey,
  }); // Added for validation order address in google, for checking wrong input data

  useEffect(() => {
    getPlacePredictions({
      input: `${order.address || customer.addressDescription}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.address, customer.addressDescription]);

  useEffect(() => {
    if (!order.address || !form.isFieldsTouched()) return;
    form.validateFields(['address'], { force: true });
  }, [placePredictions, form, order.address]);

  /** Hack to move Google PAC container following scroll changes */
  const modalWrap = document.querySelector('.ant-modal-wrap');
  const pacInputs = Array.from(
    document.getElementsByClassName('pac-target-input')
  );
  const pacContainers = Array.from(
    document.getElementsByClassName('pac-container')
  );

  const movePacContainer = useCallback(
    ({ pacInputs, pacContainers }) => () => {
      if (pacInputs.length && pacContainers.length) {
        const [activeInput] = pacInputs.filter(
          el => el === document.activeElement
        );
        const [openedPacContainer] = pacContainers.filter(el => {
          return el.style.display !== 'none';
        });

        if (activeInput && openedPacContainer) {
          const { top, height } = activeInput.getBoundingClientRect();
          openedPacContainer.style.top = `${top + height}px`;
        }
      }
    },
    []
  );

  useEffect(() => {
    if (modalWrap) {
      modalWrap.addEventListener(
        'scroll',
        movePacContainer({ pacInputs, pacContainers })
      );
    }

    return () => {
      if (modalWrap) modalWrap.removeEventListener('scroll', movePacContainer);
    };
  }, [modalWrap, pacInputs, pacContainers, movePacContainer]);

  return (
    <>
      <Form form={form} layout={layout} preserve={false}>
        <Row justify="space-between" align="baseline">
          <Col span={10}>
            <Form.Item
              label={name.label}
              name={name.name}
              rules={name.rules}
              initialValue={name.initialValue(order.dropoff)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={phone.label}
              name={phone.name}
              rules={phone.rules}
              initialValue={phone.initialValue(order.dropoff)}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={10}>
            <Form.Item
              name={address.name}
              label={address.label}
              rules={[
                {
                  required: true,
                  validator: () =>
                    checkAddress(order.address, placePredictions),
                },
              ]}
            >
              <GoogleAutoComplete handlersStore={order} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={notes.name}
              label={notes.label}
              placeholder={notes.placeholder}
              initialValue={notes.initialValue(order.dropoff)}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <MapContainer {...mapContainerProps}>
            <Form.Item>
              <GoogleMap
                setAddress={setAddress}
                setLocation={setLocation}
                latitude={location.latitude}
                longitude={location.longitude}
                mapSettings={mapSettings}
              />
            </Form.Item>
          </MapContainer>
          <Col span={10}>
            <Form.Item
              name={failedReason.name}
              label={failedReason.label}
              placeholder={failedReason.placeholder}
              initialValue={failedReason.initialValue(order)}
            >
              <Select
                options={failedReasonOptions}
                getPopupContainer={trigger => trigger.parentNode}
              />
            </Form.Item>
            <Form.Item valuePropName="checked" name={chaserPresent.name}>
              <Space>
                <Switch
                  defaultChecked={chaserPresent.initialValue(order)}
                  onChange={checked => {
                    form.setFieldsValue({ [chaserPresent.name]: checked });
                  }}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
                <span>{chaserPresent.label}</span>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
    ordersStore: rootStore.ordersStore,
    customersStore: rootStore.customersStore,
  })),
  observer
)(OrderForm);
