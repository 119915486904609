import React from 'react';
import { PlusOutlined } from '@ant-design/icons';

export const fleets = {
  createSubFleetBtn: {
    title: 'Create Subfleet',
    shape: 'round',
    icon: <PlusOutlined />,
    type: 'primary',
    size: 'small',
    style: { marginBottom: '20px' },
  },
  createSubFleetModal: { title: 'Create Subfleet', width: '90%' },
  editSubFleetModal: { title: 'Edit Subfleet', width: '90%' },
  reviewSubFleetModal: {title: 'Review Subfleet', width: '40%'}
};
