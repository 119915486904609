import { action, computed, observable } from 'mobx';
import Fleet from '../models/Fleet';

class Fleets {
  @observable rawFleets = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get fleets() {
    return this.rawFleets;
  }

  @action.bound async getAll() {
    const { method, url } = this.rootStore.urls.fleets.getAll;

    return this.rootStore.makeRequest(this.onGetAll, method, `${url}`);
  }

  @action.bound onGetAll(data) {
    if (!data) return;
    this.rawFleets = data.map(fleet => new Fleet(fleet, this.rootStore));
  }
}

export default Fleets;
