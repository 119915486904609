import React from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.css';
import './index.css';

import App, { rootStore } from './App';
import { ls } from './services';

const start = async () => {
  try {
    await rootStore.authStore.set(await ls.get('user'));
    ReactDOM.render(<App />, document.getElementById('root'));
  } catch (e) {
    console.error('Application initialization error => ', e);
  }
};

start();
