import React, { useEffect } from 'react';
import { Form, Input, DatePicker } from 'antd';

const { TextArea } = Input;

const VersionForm = ({ form }) => {
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}
      autoComplete="off"
    >
      <Form.Item
        label="Version name"
        name="version"
        rules={[
          { required: true, message: 'Please input Version name' },
        ]}
      >
        <Input placeholder="Enter Version name" />
      </Form.Item>
      <Form.Item
        label='Add Notes'
        name='notes'
        rules={[
          { required: true, message: 'Please add at least one note' },
        ]}
      >
        <TextArea rows={1} placeholder="Every note should start from a new line" autoSize allowClear />
      </Form.Item>
      <Form.Item
        label='Release Date'
        name='release_date'
        rules={[
          { required: true, message: 'Please select the date' },
        ]}
      >
        <DatePicker
          style={{width: '100%'}}
          format='MM-DD-YYYY'
        />
      </Form.Item>
    </Form>
  );
};

export default VersionForm;
