const initialNotesLength = 50;

export const cellSettings = {
  initialNotesLength,
  shortTextTitle: 'Show more',
  longTextTitle: 'Show less',
  isShortText: limit => limit === initialNotesLength,
  showLess: (text, limit) =>
    text.length > initialNotesLength && limit !== initialNotesLength,
};
