import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {inject, observer} from "mobx-react";
import {Layout, Tooltip, Typography} from 'antd';

import Menu from '../menu';
import { sideBar } from './settings';
import { StyledWrapper, StyledLogo } from "./styled";
import {compose} from "../../services/helpers";
import LogoImage from '../../images/refractionLogoImage.png';

const { Sider } = Layout;
const { bar, breakpoint, collapsedWidth } = sideBar;

const SideBar = ({ rootStore }) => {

  useEffect( () => {
    (async () => {
      if (rootStore.authStore.data.userId && !Boolean(rootStore.lastVersion)) {
        rootStore.getSettings();
        await rootStore.getAllVersions();
      }
    })()
  }, [rootStore]);

  return <Sider
    breakpoint={breakpoint}
    collapsedWidth={collapsedWidth}
    style={bar.style}
  >
    <StyledWrapper>
      <StyledLogo src={LogoImage} />
      {rootStore.lastVersion ?
        <Tooltip
          placement='right'
          title={rootStore.lastVersion.notes && <ul style={{paddingLeft: 20, marginBottom: 0, textAlign: 'left'}}>
            {rootStore.lastVersion.notes.split('\n').map((note, i) => <li key={i}>{note}</li>)}
          </ul>}
        >
            <Typography.Text ellipsis style={{maxWidth: 100}}>
              <Link to="/versions">
                {rootStore.lastVersion.version}
              </Link>
            </Typography.Text>
        </Tooltip>
        : null
      }
    </StyledWrapper>
    <Menu />
  </Sider>
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
  })),
  observer
)(SideBar);
