import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { loginForm } from './settings';
import { StyledInput } from './styled';

const {
  layout,
  emailField,
  passwordField,
  iconStyle,
  submitButton,
} = loginForm;

const LoginForm = ({ pending, onFinish }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} name="login" layout={layout} onFinish={onFinish}>
      <Form.Item name={emailField.name} rules={emailField.rules}>
        <StyledInput
          prefix={<MailOutlined style={iconStyle} />}
          type={emailField.type}
          placeholder={emailField.placeholder}
        />
      </Form.Item>
      <Form.Item name={passwordField.name} rules={passwordField.rules}>
        <StyledInput
          prefix={
            <LockOutlined className="site-form-item-icon" style={iconStyle} />
          }
          type={passwordField.type}
          placeholder={passwordField.placeholder}
        />
      </Form.Item>
      <Row>
        <Col push={16} span={8}>
          <Button
            type={submitButton.type}
            htmlType={submitButton.htmlType}
            loading={pending}
            style={submitButton.style}
            size={submitButton.size}
          >
            {submitButton.title}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
