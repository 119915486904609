import React from 'react';
import { Layout as AntdLayout } from 'antd';

import Sidebar from '../../sidebar';
import Header from '../../header';
import Content from '../../content';
import { Layout as CustomLayout } from './styled';

const Layout = ({ children }) => (
  <CustomLayout>
    <Sidebar />
    <AntdLayout>
      <Header />
      <Content>{children}</Content>
    </AntdLayout>
  </CustomLayout>
);

export default Layout;
