import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { compose } from 'services/helpers';

const CustomerStatusCell = ({ customer, customersStore }) =>
  <Switch
    onChange={e => customersStore.onStatusChange(customer.id, e)}
    checkedChildren={<CheckOutlined />}
    unCheckedChildren={<CloseOutlined />}
    defaultChecked={customer.active}
  />

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
  })),
  observer
)(CustomerStatusCell);
