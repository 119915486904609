import React from 'react';
import { Select } from 'antd';

import Button from '../../button';
import { filterOptions, filter } from './settings';

const {
  style,
  buttonsPanel: { style: buttonsStyle, resetText, filterText },
} = filter;

const CityFilter = ({
  clearFilters,
  selectedKeys,
  setSelectedKeys,
  confirm,
}) => {
  const value = selectedKeys.map(key => key.value);
  const handleFilter = () => {
    confirm();
  };
  const handleReset = () => {
    setSelectedKeys([]);
    clearFilters();
  };
  const onChange = value => {
    const selected = filterOptions.filter(option =>
      value.includes(option.value)
    );

    setSelectedKeys(selected);
  };

  return (
    <>
      <Select
        value={value}
        onChange={onChange}
        mode="multiple"
        style={style}
        options={filterOptions}
      />
      <br />
      <span style={buttonsStyle}>
        <Button onClick={handleReset}>{resetText}</Button>
        <Button onClick={handleFilter} type="primary">
          {filterText}
        </Button>
      </span>
    </>
  );
};

export default CityFilter;
