import { observable, action, computed } from 'mobx';

export const DEFAULT_DROPOFF = {
  address: '',
  contact_name: '',
  contact_phone: '',
  notes: '',
  estimated_arrival: {
    minimum: '',
    maximum: '',
  },
  location: {
    latitude: '',
    longitude: '',
  },
};
export const DEFAULT_PICKUP = {
  id: '',
  address: '',
  contact_name: '',
  contact_phone: '',
  estimated_arrival: {
    minimum: '',
    maximum: '',
  },
  location: {
    latitude: '',
    longitude: '',
  },
  notes: null,
  ready_at: null,
};
export const DEFAULT_CURRENT_LOC = {
  latitude: '',
  longitude: '',
  updated_at: '',
};
class Order {
  @observable order_id = '';
  @observable expires_at = '';
  @observable current_state = '';
  @observable pickup = DEFAULT_PICKUP;
  @observable dropoff = DEFAULT_DROPOFF;
  @observable current_location = DEFAULT_CURRENT_LOC;

  constructor(order, rootStore) {
    Object.assign(this, order);
    this.rootStore = rootStore;
  }

  @action.bound setAddress = value => {
    this.dropoff.address = value;
  };

  @action.bound setLocation = ({ latitude, longitude }) => {
    this.dropoff.location.latitude = latitude;
    this.dropoff.location.longitude = longitude;
  };

  @computed get address() {
    return this.dropoff.address;
  }

  @computed get location() {
    return this.dropoff.location;
  }
}

export default Order;
