import React, {useEffect, useMemo, useState} from 'react';
import { Form, Input, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import {
  checkSpace,
  compose,
  mapEntitiesForSelectOptions,
} from '../../../services/helpers';
import {LAT_REGEX, LNG_REGEX} from "../../../common/constants";
import {every, isEmpty} from "lodash";

const CreateSubFleetForm = ({
  customersStore,
  robotsStore,
  fleetsStore,
  form,
}) => {
  const {
    allRobots,
    getAll: getAllRobots,
    getByFleetId,
    robotsByFleetIdData,
  } = robotsStore;
  const { getByFleetName, customersByFleet } = customersStore;
  const { fleets, getAll: getAllFleets } = fleetsStore;

  const [areCoordinatesRequired, setAreCoordinatesRequired] = useState(false);

  useEffect(() => {
    if (!allRobots.length) getAllRobots();
  }, [getAllRobots, allRobots]);

  useEffect(() => {
    if (!fleets.length) getAllFleets();
  }, [getAllFleets, fleets]);

  const customersOptions = useMemo(
    () => customersByFleet.map(mapEntitiesForSelectOptions),
    [customersByFleet]
  );

  const robotsOptions = useMemo(
    () => robotsByFleetIdData.map(mapEntitiesForSelectOptions),
    [robotsByFleetIdData]
  );

  const fleetsOptions = useMemo(() => fleets.map(mapEntitiesForSelectOptions), [
    fleets,
  ]);

  const onSelectFleet = (id, info) => {
    if (id && info) {
      getByFleetName(info.label);
      getByFleetId(id);
      form.resetFields(['customer_ids', 'robot_ids']);
    }
  };

  useEffect(() => {
    setAreCoordinatesRequired(!every(form.getFieldsValue(['latitude', 'longitude']), isEmpty))
    return () => {
      form.resetFields();
    };
  }, [form]);

  const handleChange = (e) => {
    setAreCoordinatesRequired(
      e.target.value ? true : !every(form.getFieldsValue(['latitude', 'longitude']), isEmpty)
    )
    form.validateFields(['latitude', 'longitude'], { force: true });
  }

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={{ remember: true }}
      autoComplete="off"
    >
      <Form.Item
        label="Subfleet name"
        name="name"
        rules={[
          { required: true, message: 'Please input your subfleet name!' },
        ]}
      >
        <Input placeholder="Enter subfleet name" />
      </Form.Item>

      <Form.Item
        label="Select fleet"
        name="fleet_id"
        rules={[{ required: true, message: 'Please select fleet!' }]}
      >
        <Select
          showSearch
          placeholder="Select a fleet"
          optionFilterProp="children"
          options={fleetsOptions}
          onChange={onSelectFleet}
          getPopupContainer={trigger => trigger.parentNode}
        />
      </Form.Item>

      <Form.Item
        label="Add customers"
        name="customer_ids"
        rules={[{ required: true, message: 'Please add customers!' }]}
      >
        <Select
          mode="multiple"
          placeholder="Select customers"
          getPopupContainer={trigger => trigger.parentNode}
          options={customersOptions}
        />
      </Form.Item>

      <Form.Item
        label="Add robots"
        name="robot_ids"
      >
        <Select
          mode="multiple"
          placeholder="Add robots"
          getPopupContainer={trigger => trigger.parentNode}
          options={robotsOptions}
        />
      </Form.Item>
      <Form.Item
        label={<>Slack link</>}
        name="slack_url"
        tooltip="Please insert the slack channel link"
        rules={[checkSpace]}
      >
        <Input placeholder="Slack link (optional)" />
      </Form.Item>
      <Form.Item
        label="Latitude"
        name="latitude"
        rules={[
          {
            validator: (_, value) =>
              !value ?
                !areCoordinatesRequired ? Promise.resolve() : Promise.reject('Please, enter latitude')
                :
                LAT_REGEX.test(value)
                  ? Promise.resolve(value)
                  : Promise.reject('Please, enter correct latitude')
          },
        ]}
      >
        <Input placeholder="Enter latitude" onChange={handleChange} />
      </Form.Item>
      <Form.Item
        label="Longitude"
        name="longitude"
        rules={[
          {
            validator: (_, value) =>
              !value ?
                !areCoordinatesRequired ? Promise.resolve() : Promise.reject('Please, enter longitude')
                :
                LNG_REGEX.test(value)
                  ? Promise.resolve(value)
                  : Promise.reject('Please, enter correct longitude')
          },
        ]}
      >
        <Input placeholder="Enter longitude" onChange={handleChange} />
      </Form.Item>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
    robotsStore: rootStore.robotsStore,
    fleetsStore: rootStore.fleetsStore,
  })),
  observer
)(CreateSubFleetForm);
