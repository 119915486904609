import superagent from 'superagent';
import { isObject, isString } from 'lodash';
import of from 'await-of';

import apiUrls from './api-urls';
import ls from './local-storage';
import { normalizeAuthResponse } from './helpers';

class Api {
  request = null;
  allowedMethods = ['get', 'post', 'put', 'del'];
  host = process.env.REACT_APP_SERVER_HOST;
  headers = { 'x-client-name': 'refraction-admin' };

  validateUrl = url => {
    if (!isString(url)) {
      throw new Error(`{url} parameter must be a string, got a ${typeof url}`);
    }
  };

  validateHeaders = headers => {
    if (!isObject(headers)) {
      throw new Error(
        `{headers} parameter must be a valid object, got a ${typeof headers}`
      );
    }
  };

  validateMethod = method => {
    if (!this.allowedMethods.includes(method)) {
      throw new Error(
        `Method ${method} is not allowed. Please, use one of ${this.allowedMethods}.`
      );
    }
  };

  isUnauthorized(error) {
    return error && error.status === 401;
  }

  logout = async () => {
    await ls.remove('user');
    window.location.reload();
  };

  async getUser() {
    const user = await ls.get('user');
    return user ? user : {};
  }

  async refreshToken() {
    const { refreshToken, refreshTokenExpiresAt } = await this.getUser();

    if (!refreshToken || new Date(refreshTokenExpiresAt) < Date.now()) {
      return false;
    }

    const { method, url } = apiUrls.refreshToken;
    this.request = superagent[method](`${this.host}/${url(refreshToken)}`).set({
      ...this.headers,
    });

    const [response, error] = await of(this.request);

    if (this.isUnauthorized(error)) {
      return false;
    }

    return ls.save('user', normalizeAuthResponse(response.body));
  }

  makeRequest = async requestData => {
    const {
      method,
      url,
      data = {},
      headers = this.headers,
      responseType = '',
    } = requestData;
    this.validateMethod(method);
    this.validateUrl(url);
    this.validateHeaders(headers);
    const user = await ls.get('user');
    const token = user && user.token;
    this.request = superagent[method](`${this.host}/${url}`, data).set({
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    });

    if (responseType) {
      this.request.responseType(responseType);
    }

    const [response, error] = await of(this.request);

    if (this.isUnauthorized(error) && requestData.url !== 'users/login') {
      const tokenRefreshed = await this.refreshToken();

      return tokenRefreshed ? this.makeRequest(requestData) : this.logout();
    }

    const body = response ? response.body : null;

    return [body, error];
  };

  abort = () => {
    if (this.request) {
      return this.request.abort();
    }
    return null;
  };
}

export default new Api();
