import styled from 'styled-components';

export const StyledWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 15px;
`;

export const StyledLogo = styled('img')`
  display: flex;
  height: 62px;
  margin-right: 16px;
`;
