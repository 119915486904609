import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { compose } from 'services/helpers';
import { Table } from 'components';
import { columns } from './settings';

const Admins = ({ adminsStore }) => {
  const { admins, getAll, pagination, changePagination, total } = adminsStore;

  useEffect(() => {
    const onMount = async () => {
      await getAll();
    };

    onMount();
  }, [getAll]);

  return (
    <Table
      columns={columns}
      data={admins}
      pagination={pagination}
      paginationTotal={total}
      onChange={changePagination}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    adminsStore: rootStore.adminsStore,
  })),
  observer
)(Admins);
