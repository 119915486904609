import React from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { compose } from '../../../services/helpers';

const DefaultRoute = ({ authStore }) => {
  const { token, permissionNames } = authStore;

  if (!token) {
    return <Redirect to="/login" />;
  } else if (permissionNames && permissionNames.includes('customer_observer')) {
    return <Redirect to="/orders-statuses" />;
  } else if (permissionNames && permissionNames.includes('admin_versions')) {
    return <Redirect to="/versions" />;
  }

  return <Redirect to="/admins" />;
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
  })),
  observer
)(DefaultRoute);
