import React from 'react';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';

export const subFleetRobotsColumns = [
  { title: 'Robot name', dataIndex: 'name', key: 'name' },
  { title: 'Robot Status', dataIndex: 'status', key: 'status' },
  { title: 'Order ID', dataIndex: 'orderId', key: 'orderId' },
];

export const subFleetColumns = [
  {
    title: 'Subfleet Name',
    dataIndex: 'subfleetName',
    key: 'subfleetName',
    sorter: (a, b) => a.subfleetName.localeCompare(b.subfleetName),
    ellipsis: true,
    width: '70%',
  },
];

export const subFleetsActions = {
  orderDetails: {
    tooltip: 'Review subfleet',
    type: 'link',
    icon: <EyeOutlined />,
    style: { width: '24px' },
  },
  updateOrder: {
    tooltip: 'Edit subfleet',
    type: 'link',
    icon: <EditOutlined />,
    style: { width: '24px' },
  },
  deleteSubfleetAction: {
    tooltip: 'Delete subfleet',
    type: 'link',
    icon: <DeleteOutlined style={{ color: '#ff4d4f' }} />,
    style: { width: '24px' },
  },
};

export const reviewColumns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    width: '30%',
    render: text => ({
      props: {
        style: { fontWeight: 600 },
      },
      children: text,
    }),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: '70%',
    align: 'left',
    tableLayout: 'fixed',
    ellipsis: true,
    render: text => (
      <Tooltip title={text} placement="topLeft">
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text || '-'}
        </div>
      </Tooltip>
    ),
  },
];

export const robotStatusBadges = status => {
  const statuses = {
    assigned: 'warning',
    available: 'success',
    out_of_service: 'error',
    on_delivery: 'processing',
  };
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Badge status={statuses[status]} /> {status}
    </div>
  );
};
