import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { AsYouType } from 'libphonenumber-js';

const {
  EditOutlined,
  EyeOutlined,
  FormatPainterOutlined,
  PlusOutlined,
  RobotOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
} = require('@ant-design/icons');

export const palette = [
  'red',
  'blue',
  'darkcyan',
  'orange',
  'navy',
  'yellow',
  'violet',
  'green',
  'purple',
  'lime',
];

export const orders = {
  createModal: {
    title: 'Create Order',
  },
  resetSort: { title: 'Reset Sort', shape: 'round', type: 'primary' },
  rangePicker: {
    innerStyle: { flexWrap: 'nowrap' },
    pickerTitle: {
      text: 'Select date range:',
      style: { whiteSpace: 'nowrap' },
    },
    picker: {
      ranges: {
        'Current Month': [moment().startOf('month'), moment()],
        'Prior Month': [
          moment()
            .subtract(1, 'months')
            .startOf('month'),
          moment()
            .subtract(1, 'months')
            .endOf('month'),
        ],
        'Last 30 Days': [moment().subtract(30, 'days'), moment()],
        'Last 60 Days': [moment().subtract(60, 'days'), moment()],
        'Last 90 Days': [moment().subtract(90, 'days'), moment()],
        'Current Quarter': [
          moment().startOf('quarter'),
          moment().endOf('quarter'),
        ],
        'Prior Quarter': [
          moment()
            .subtract(1, 'quarter')
            .startOf('quarter'),
          moment()
            .subtract(1, 'quarter')
            .endOf('quarter'),
        ],
        'Current Year': [moment().startOf('year'), moment().endOf('year')],
        'Prior Year': [
          moment()
            .subtract(1, 'year')
            .startOf('year'),
          moment()
            .subtract(1, 'year')
            .endOf('year'),
        ],
      },
    },
  },
  habitatOrder: {
    title: 'Create Order',
    // shape: 'round',
    icon: <PlusOutlined />,
    type: 'primary',
    style: {
      padding: '10px 0',
      display: 'flex',
      width: '22%',
      justifyContent: 'space-between',
    },
  },
  tabs: {
    active: { tab: 'Active', key: 'active' },
    completed: { tab: 'Completed', key: 'completed' },
    canceled: { tab: 'Canceled', key: 'revoke' },
    scheduled: { tab: 'Scheduled', key: 'scheduled' },
    rejected: {tab: 'Rejected', key: 'rejected'}
  },
};

export const tableActions = {
  orderDetails: {
    tooltip: 'Review Order',
    type: 'link',
    icon: <EyeOutlined />,
  },
  updateOrder: {
    tooltip: 'Edit Order',
    type: 'link',
    icon: <EditOutlined />,
  },
  updateOrderStatus: {
    tooltip: 'Edit Status',
    type: 'link',
    icon: <FormatPainterOutlined />,
  },
  robotReassign: {
    tooltip: 'Reassign Robot',
    type: 'link',
    icon: <RobotOutlined />,
  },
  deliveryHistory: {
    tooltip: 'Delivery History',
    type: 'link',
    icon: <HistoryOutlined />,
  },
};

export const modals = {
  editOrder: { title: 'Edit Order', width: '100%' },
  habitatOrderModal: { title: 'Habitat Order', width: '90%' },
  reviewOrder: { title: 'Review Order', width: '40%' },
  editStatus: { title: 'Edit Status', width: '23%' },
  robotReassignModal: { title: 'Robot Reassignment', width: '40%' },
  deliveryHistoryModal: { title: 'Delivery History', width: '50%' },
};

export const RowWithTooltip = props => (
  <Tooltip
    open={true}
    placement="top"
    title={
      props.className.includes('colored') &&
      'Orders created within 20 minutes that have the same drop-off address are highlighted in this color.'
    }
  >
    <tr {...props} />
  </Tooltip>
);

export const columns = [
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
    align: 'center',
    sorter: true,
    width: 100,
  },
  {
    title: 'Customer Name',
    dataIndex: 'pickup',
    key: 'pickup_contact_name',
    align: 'center',
    render: pickup => pickup.name,
    sorter: true,
  },
  {
    title: 'Order Status',
    dataIndex: 'current_state',
    key: 'current_state',
    align: 'center',
    sorter: true,
  },
  {
    title: 'Dropoff Name',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_name',
    align: 'center',
    render: dropoff => dropoff.contact_name,
    sorter: true,
  },
  {
    title: 'Dropoff Phone',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_phone',
    align: 'center',
    render: ({ contact_phone }) => {
      return contact_phone ? new AsYouType('US').input(contact_phone) : '';
    },
    sorter: true,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at',
    sorter: true,
    render: created_at => utcToCurrentTime(created_at, true),
  },
  {
    title: 'Dropoff address',
    dataIndex: 'dropoff',
    align: 'center',
    render: dropoff => dropoff.address,
  },
];

export const activeColumns = [
  {
    key: 'robot_name',
    title: 'Bot Name',
    render: (_, record) => ({
      children: (
        <div style={{ height: '100%' }}>
          <div style={{ fontWeight: '600' }}>{record.robot_name || '-'}</div>
          {record.deliveries_count > 1 && (
            <Tooltip title={'No. of batched orders'}>
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '0',
                  fontSize: '12px',
                  backgroundColor: 'rgb(115 184 215)',
                  padding: '0 5px',
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                <InfoCircleOutlined /> {record.deliveries_count} orders
              </div>
            </Tooltip>
          )}
        </div>
      ),
      props: {
        rowSpan: record.span,
      },
    }),
    sorter: true,
    align: 'center',
    width: 120,
  },
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
    align: 'center',
    width: 100,
  },
  {
    title: 'Locker',
    dataIndex: 'locker_name',
    key: 'locker_name',
    align: 'center',
    render: locker => locker || '-',
  },
  {
    title: 'Customer Name',
    dataIndex: 'pickup',
    key: 'pickup_contact_name',
    align: 'center',
    render: pickup => pickup.name,
  },
  {
    title: 'Order Status',
    dataIndex: 'current_state',
    key: 'current_state',
    align: 'center',
  },
  {
    title: 'Dropoff Name',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_name',
    align: 'center',
    render: dropoff => dropoff.contact_name,
  },
  {
    title: 'Dropoff Phone',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_phone',
    align: 'center',
    render: ({ contact_phone }) => {
      return contact_phone ? new AsYouType('US').input(contact_phone) : '';
    },
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at',
    render: created_at => utcToCurrentTime(created_at, true),
  },
  {
    title: 'Dropoff address',
    dataIndex: 'dropoff',
    align: 'center',
    render: dropoff => dropoff.address,
  },
  {
    title: 'Delivery Time',
    dataIndex: 'dropoff',
    key: 'no_earlier_than',
    align: 'center',
    width: '120px',
    render: (dropoff) => {
      return (
        <>
          <div>
            {dropoff.estimated_arrival.minimum || '-'}
          </div>
          <div>{dropoff.estimated_arrival.maximum || '-'}</div>
        </>
      )
    },
  },
];

export const completedColumn = {
  title: 'Completed At',
  dataIndex: 'completed_at',
  align: 'center',
  key: 'completed_at',
  // wait for backend to add sort for completed_at column
  // sorter: true,
  render: completed_at => utcToCurrentTime(completed_at, true),
};

export const scheduledColumns = [
  {
    title: 'Order ID',
    dataIndex: 'order_id',
    key: 'order_id',
    align: 'center',
    sorter: true,
    width: 120,
  },
  {
    title: 'Customer Name',
    dataIndex: 'pickup',
    key: 'pickup_contact_name',
    align: 'center',
    render: pickup => pickup.name,
    sorter: true,
  },
  {
    title: 'Dropoff Name',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_name',
    align: 'center',
    render: dropoff => dropoff.contact_name,
    sorter: true,
  },
  {
    title: 'Dropoff Phone',
    dataIndex: 'dropoff',
    key: 'dropoff_contact_phone',
    align: 'center',
    render: ({ contact_phone }) => {
      return contact_phone ? new AsYouType('US').input(contact_phone) : '';
    },
    sorter: true,
  },
  {
    title: 'Dropoff address',
    dataIndex: 'dropoff',
    align: 'center',
    render: dropoff => dropoff.address,
  },
  {
    title: 'Robot Validation',
    dataIndex: 'robot_contract_validation',
    key: 'robot_contract_validation',
    align: 'center',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at',
    sorter: true,
    render: created_at => utcToCurrentTime(created_at, true),
  },
  {
    title: 'Scheduled At',
    dataIndex: 'scheduled_at',
    align: 'center',
    key: 'scheduled_at',
    sorter: true,
    render: scheduled_at => utcToCurrentTime(scheduled_at, true),
  },
];

export const selectStyles = {
  style: {
    width: '100%',
    marginRight: '10px',
  },
};

export const reviewOrderColumns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    width: '30%',
    render: text => ({
      props: {
        style: { fontWeight: 600 },
      },
      children: text,
    }),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: '70%',
    align: 'left',
    tableLayout: 'fixed',
    ellipsis: true,
    render: text => (
      <Tooltip title={text}>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text || '-'}
        </div>
      </Tooltip>
    ),
  },
];

export const utcToCurrentTime = (time, br, format = 'hh:mm:ss A') => {
  const gettingDate = new Date(time);
  const newDate = new Date(
    gettingDate.getTime() - gettingDate.getTimezoneOffset() * 60 * 1000
  );
  return (
    <>
      <span>{moment(newDate).format('MM-DD-YYYY')} </span>
      {br && <br />}
      <span>{moment(newDate).format(format)}</span>
    </>
  );
};

export const rejectedColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'External Delivery ID',
    dataIndex: 'ext_order_id',
    key: 'ext_order_id',
    align: 'center',
    render: name => name || '-',
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at',
    render: created_at => utcToCurrentTime(created_at, true),
  },
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
    align: 'center',
    render: name => name || '-',
  },
  {
    title: 'Error Message',
    dataIndex: 'errors_message',
    key: 'errors_message',
  },
];
