import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Col, Select, Button as AntButton, Typography } from 'antd';
import { compose } from 'services/helpers';
import useModal from 'common/hooks/useModal';
import { robots } from './settings';
import { Table } from 'components';
import Modal from 'components/modal';
import Button from '../../components/button';
import { PageActionBar } from './styled';
import { UPDATE_REQUEST_INTERVAL } from '../../common/constants';

const {
  buttons: { resetSort },
} = robots;

const Robots = ({
  robotsStore,
  ordersStore,
  rootStore: { settingsRobotsTypes, settingsFleets, settingsSubFleets },
}) => {
  const {
    getPage,
    pageRobots,
    changePagination,
    total,
    columns,
    pagination,
    dropPagination,
    updateById,
  } = robotsStore;

  const [selectedRobot, setSelectedRobot] = useState({});
  const [statusToChange, setStatusToChange] = useState('');
  const [preparedColumns, setPreparedColumns] = useState(toJS(columns));

  const [
    isModalShown,
    onSetIsModalShown,
    onOkAction,
    onCancelAction,
  ] = useModal();

  const { getAllActive, activeOrders } = ordersStore;

  useEffect(() => {
    if (!activeOrders.length) getAllActive();
  }, [activeOrders.length, getAllActive]);

  useEffect(() => {
    getPage();
  }, [getPage, pagination]);

  useEffect(() => {
    let timer = null;
    // Used to update SubFleets each UPDATE_REQUEST_INTERVAL milliseconds
    (async function updateRequest() {
      await getPage();
      timer = setTimeout(updateRequest, UPDATE_REQUEST_INTERVAL);
    })();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [getPage]);

  const subFleetsOptions = useMemo(() => {
    if (!settingsSubFleets.length) return;
    return settingsSubFleets.map(({name, id}) => {
      return { text: name, value: id };
    });
  }, [settingsSubFleets]);
  
  const robotsOptions = useMemo(() => {
    if (!settingsRobotsTypes.length) return;
    return settingsRobotsTypes.map(item => ({
      text: item,
      value: item,
    }));
  }, [settingsRobotsTypes]);

  useEffect(() => {
    const columnData = [...preparedColumns];

    const subfleetIndex = columnData.findIndex(
      el => el.dataIndex === 'sub_fleet_name'
    );
    const currentStateIndex = columnData.findIndex(
      el => el.dataIndex === 'current_state'
    );
    const namesIndex = columnData.findIndex(el => el.dataIndex === 'name');
    if (subFleetsOptions) {
      columnData[subfleetIndex].filters = subFleetsOptions;
    }
    if (robotsOptions) {
      const formattedSettings = robotsOptions;
      columnData[namesIndex].filters = formattedSettings;
    }

    if (pagination) {
      columnData[subfleetIndex].filteredValue = pagination['subFleetIds'];
      columnData[currentStateIndex].filteredValue = pagination['currentStates'];
      columnData[namesIndex].filteredValue = pagination['names'];
    }

    setPreparedColumns(columnData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, pagination, subFleetsOptions, settingsRobotsTypes]);

  const fleetsOptions = useMemo(() => {
    if (!settingsFleets.length) return;
    return settingsFleets.map((data, index) => ({
      label: data,
      value: index,
    }));
  }, [settingsFleets]);

  const parsedFleetName = useMemo(() => {
    if (typeof pagination.filter === 'string')
      return pagination.filter.split('_')[1];
    return false;
  }, [pagination.filter]);

  const getOrderId = useCallback(
    delId => {
      if (activeOrders.length) {
        const order = activeOrders.find(o => o.id === delId);
        if (order) return order.order_id;
      }
    },
    [activeOrders]
  );

  const filteredRobotsInfo = useMemo(() => {
    return pageRobots.map(
      ({
        sub_fleet_name,
        name,
        current_state,
        battery_voltage,
        battery_remain_mi,
        odometer_mi,
        current_delivery_id,
        current_driver,
        current_chaser,
        id,
      }) => ({
        sub_fleet_name,
        name,
        current_state,
        battery_voltage,
        battery_remain_mi,
        odometer_mi,
        orderId: getOrderId(current_delivery_id),
        current_driver,
        current_chaser,
        key: id,
      })
    );
  }, [getOrderId, pageRobots]);

  const onFilteringByCity = (_, { label }) => {
    changePagination(
      { ...pagination, current: 1 },
      { address: 'address_' + label }
    );
  };

  const changeStatus = data => {
    updateById(data);
  };

  const onChangeStatus = () => {
    changeStatus({ id: selectedRobot.key, param: statusToChange });
    onOkAction()();
  };

  const renderActions = row => (
    <div>
      {(() => {
        if (row.current_state === 'out_of_service') {
          return (
            <AntButton
              onClick={() => {
                onSetIsModalShown()();
                setSelectedRobot(row);
                setStatusToChange('available');
              }}
              style={{ width: 140 }}
              size="small"
              className="button-green"
            >
              Make available
            </AntButton>
          );
        } else if (row.current_state === 'available') {
          return (
            <AntButton
              onClick={() => {
                onSetIsModalShown()();
                setSelectedRobot(row);
                setStatusToChange('out_of_service');
              }}
              style={{ width: 140 }}
              size="small"
              className="button-red"
            >
              Make out of service
            </AntButton>
          );
        }

        return '-';
      })()}
    </div>
  );

  return (
    <>
      <Modal
        title={selectedRobot.name}
        visible={isModalShown}
        width="40%"
        destroyOnClose
        onOk={onChangeStatus}
        onCancel={onCancelAction()}
      >
        Are you sure you want to change status of this robot to{' '}
        <Typography.Text strong>{statusToChange}</Typography.Text>?
      </Modal>

      <PageActionBar>
        <Col>
          <Select
            placeholder="Filtering by city"
            style={{ width: 150 }}
            options={fleetsOptions}
            onChange={onFilteringByCity}
            value={parsedFleetName}
          />
        </Col>
        <Col>
          <Button
            type={resetSort.type}
            shape={resetSort.shape}
            onClick={dropPagination}
          >
            {resetSort.title}
          </Button>
        </Col>
      </PageActionBar>
      <Table
        data={filteredRobotsInfo}
        columns={preparedColumns}
        pagination={pagination}
        onChange={changePagination}
        paginationTotal={total}
        renderActions={renderActions}
        actionsFixed="right"
        actionsWidth={172}
        scroll={{ x: 1300 }}
        rowKey="key"
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    robotsStore: rootStore.robotsStore,
    ordersStore: rootStore.ordersStore,
    rootStore,
  })),
  observer
)(Robots);
