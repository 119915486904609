import React from 'react';
import { Col } from 'antd';
import { inject, observer } from 'mobx-react';

import { LoginForm } from '../../components/forms';
import { compose } from 'services/helpers';
import { row, redirectUrl } from './settings';
import { StyledRow } from './styled';
import Logo from '../../components/logo';

const Login = ({ authStore, rootStore }) => {
  const handleSubmit = async data => {
    await authStore.login(data);
    rootStore.routingStore.push(redirectUrl);
  };

  return (
    <StyledRow type={row.type} justify={row.justify} align={row.align}>
      <Col span={6}>
        <Logo size="large" />
        <LoginForm onFinish={handleSubmit} pending={rootStore.pending} />
      </Col>
    </StyledRow>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore,
  })),
  observer
)(Login);
