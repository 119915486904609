import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, Table, Button, message, Space, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react';

import { GoogleMap, GoogleAutoComplete, MapContainer } from '../../google-map';
import { checkAddress, habitatOrderForm } from './settings';
import {
  compose,
  mapEntitiesForSelectOptions,
} from '../../../services/helpers';
import useDebounce from '../../../common/hooks/useDebounce';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { apiKey } from '../../google-map/settings';
import * as _ from 'lodash';
import { INVALID_NATIONAL_PHONE, PRONE_REGEX } from '../../../common/constants';

import DistanceIcon from '../../../images/distance.svg';
import RobotImage from '../../../images/robo40.png';
import { StyledHeading } from './styled';

const {
  dropoffName,
  pickupPhone,
  dropoffPhone,
  pickupNotes,
  dropoffNotes,
  pickup,
  layout,
  dropoff,
  pickupAddress: pickupAddressSettings,
  dropoffAddress: dropoffAddressSettings,
  mapSettings,
  mapContainerProps,
  selectCustomer,
} = habitatOrderForm;

const HabitatOrderForm = ({
  ordersStore: { habitatOrder, resetSelectedHabitatOrder },
  form,
  customersStore: {
    customer,
    boundedOneByFleet,
    getMatches,
    dropMatches,
    getByFleetName: getCustomersByFleetName,
    customersByFleet,
  },
  subFleetsStore,
  robotsStore,
  fleetsStore,
}) => {
  const {
    setPickupAddress,
    setPickupLocation,
    setDropoffAddress,
    setDropoffLocation,
    dropoffLocation,
    pickupLocation,
    pickupAddress,
    dropoffAddress,
    setPickupPhone,
    setPickupNotes,
  } = habitatOrder;

  const {
    getAll: getAllSubFleets,
    allSubFleets,
    selectedSubFleet,
  } = subFleetsStore;

  const { fleets: allFleets, getAll: getAllFleets } = fleetsStore;

  const {
    robotsAvailableByDistance,
    // getAvailableBySubFleetId,
    getAvailableRobotsByDistance,
    // Todo: replace getAllNoPagination by getAll after changing logic of back-end
    getAllNoPagination: getAllRobots,
    setSelectedRobotId,
    setAddToQueue,
    isSkipRobotAddToQueue,
  } = robotsStore;

  const [pickupDropoffDistance, setPickupDropoffDistance] = useState(0);
  const [isLoadingRobots, setIsLoadingRobots] = useState(false);
  const [hasAlreadyFetchedRobots, setHasAlreadyFetchedRobots] = useState(false);

  const {
    placePredictions: pickUpPredictions,
    getPlacePredictions: getPickUpPredictions,
  } = useGoogle({
    apiKey: apiKey,
  });
  const {
    placePredictions: dropOffPredictions,
    getPlacePredictions: getdropOffPredictions,
  } = useGoogle({
    apiKey: apiKey,
  });

  useEffect(() => {
    getPickUpPredictions({ input: habitatOrder.pickup.address });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habitatOrder.pickup.address]);

  useEffect(() => {
    getdropOffPredictions({ input: habitatOrder.dropoff.address });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habitatOrder.dropoff.address]);

  useEffect(() => {
    if (!habitatOrder.pickup.address || !form.isFieldsTouched()) return;
    form.validateFields(['pickupAddress'], { force: true });
  }, [pickUpPredictions, form, habitatOrder.pickup.address]);

  useEffect(() => {
    if (!habitatOrder.dropoff.address || !form.isFieldsTouched()) return;
    form.validateFields(['dropoffAddress'], { force: true });
  }, [dropOffPredictions, form, habitatOrder.dropoff.address]);

  // useEffect(() => {
  //   if (!habitatOrder.pickup.contact_phone) return;
  //   console.log(habitatOrder.pickup.contact_phone);
  //   form.validateFields(['pickupPhone'], { force: true });
  // }, [form, habitatOrder.pickup.contact_phone]);

  const [searchStr] = useState('');
  const value = useDebounce(searchStr, 200);

  useEffect(() => {
    if (!allFleets.length) getAllFleets();
  }, [allFleets, getAllFleets]);

  useEffect(() => {
    value ? getMatches(value) : dropMatches();
  }, [value, getMatches, dropMatches]);

  useEffect(() => {
    return () => {
      dropMatches();
      resetSelectedHabitatOrder();
    };
  }, [dropMatches, resetSelectedHabitatOrder]);

  // Prefetch all subfleets if they doesn't exist
  useEffect(() => {
    if (!allSubFleets) getAllSubFleets();
  }, [getAllSubFleets, allSubFleets]);

  // Prefetch all robots if they doesn't exist
  /*  useEffect(() => {
    const { id } = selectedSubFleet;

    // if (id) getAvailableBySubFleetId(id);
  }, [selectedSubFleet, getAvailableBySubFleetId]);*/

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    return () => {
      getAllRobots();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availableRobotsOptions = useMemo(() => {
    let isAppropriateRobotFound = false;
    return robotsAvailableByDistance.map(robot => {
      if (
        !isAppropriateRobotFound &&
        robot.id &&
        robot.battery_remain_mi >= robot.total_distance
      ) {
        isAppropriateRobotFound = true;
        setSelectedItems([robot.id]);
        setSelectedRobotId(robot.id);
      }
      return { key: robot.id, ...robot };
    });
  }, [robotsAvailableByDistance, setSelectedRobotId]);

  const tableColumns = useMemo(
    () => [
      {
        title: 'Robot Name',
        dataIndex: 'name',
        render: (name, { battery_remain_mi, total_distance }) => (
          <span
            className="robot-name"
            style={{
              color: battery_remain_mi < total_distance ? 'red' : 'green',
            }}
          >
            {name}
          </span>
        ),
      },
      {
        title: 'Remaining Miles',
        dataIndex: 'battery_remain_mi',
        sorter: {
          compare: (a, b) => a.battery_remain_mi - b.battery_remain_mi,
          multiple: 1,
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'descend',
      },
      {
        title: 'Total Trip Miles*',
        dataIndex: 'total_distance',
        sorter: {
          compare: (a, b) => a.total_distance - b.total_distance,
          multiple: 2,
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        defaultSortOrder: 'ascend',
      },
    ],
    []
  );

  const subFleetCustomersOptions = useMemo(() => {
    const { customer_ids: customerIds = [] } = selectedSubFleet;
    return customersByFleet
      .filter(customer => customerIds.includes(customer.id))
      .map(mapEntitiesForSelectOptions);
  }, [customersByFleet, selectedSubFleet]);

  const onSelectCustomer = (id = '') => {
    const customer = customersByFleet.find(customer => customer.id === id);
    const { latitude, longitude } = customer.address;
    setPickupLocation({
      latitude: Number(latitude),
      longitude: Number(longitude),
    });
    setPickupAddress(customer.address.description);
    setPickupPhone({ target: { value: customer.contact_phone } });
    boundedOneByFleet(id);
  };

  /** Hack to move Google PAC container following scroll changes */
  const modalWrap = document.querySelector('.ant-modal-wrap');
  const pacInputs = Array.from(
    document.getElementsByClassName('pac-target-input')
  );
  const pacContainers = Array.from(
    document.getElementsByClassName('pac-container')
  );

  const movePacContainer = useCallback(
    ({ pacInputs, pacContainers }) => () => {
      if (pacInputs.length && pacContainers.length) {
        const [activeInput] = pacInputs.filter(
          el => el === document.activeElement
        );
        const [openedPacContainer] = pacContainers.filter(el => {
          return el.style.display !== 'none';
        });

        if (activeInput && openedPacContainer) {
          const { top, height } = activeInput.getBoundingClientRect();
          openedPacContainer.style.top = `${top + height}px`;
        }
      }
    },
    []
  );

  useEffect(() => {
    if (modalWrap) {
      modalWrap.addEventListener(
        'scroll',
        movePacContainer({ pacInputs, pacContainers })
      );
    }

    return () => {
      if (modalWrap) modalWrap.removeEventListener('scroll', movePacContainer);
    };
  }, [modalWrap, pacInputs, pacContainers, movePacContainer]);

  const selectedFleetName = useMemo(() => {
    if (!selectedSubFleet.fleet_id) return '';
    const fleet = allFleets.find(f => f.id === selectedSubFleet.fleet_id);
    if (fleet) return fleet.name;
  }, [allFleets, selectedSubFleet]);

  useEffect(() => {
    getCustomersByFleetName(selectedFleetName, {
      active: true,
      external_integration: 'None',
    });
  }, [getCustomersByFleetName, selectedFleetName]);

  const updateAvailableRobots = async () => {
    setSelectedItems([]);
    setSelectedRobotId('');
    try {
      setIsLoadingRobots(true);
      const response = await getAvailableRobotsByDistance({
        customer_id: customer.id,
        pickup: {
          lat: pickupLocation.latitude,
          lng: pickupLocation.longitude,
        },
        dropoff: {
          lat: dropoffLocation.latitude,
          lng: dropoffLocation.longitude,
        },
      });
      if (
        response &&
        Array.isArray(response.robots) &&
        response.robots.length > 0
      ) {
        setPickupDropoffDistance(response.pickup_dropoff_distance);
      } else {
        message.error('No robots available for the selected subfleet');
      }
      setHasAlreadyFetchedRobots(true);
      setIsLoadingRobots(false);
    } catch (err) {
      setIsLoadingRobots(false);
    }
  };

  const shouldNotAllowSearchingAvailableRobots =
    isSkipRobotAddToQueue ||
    !Boolean(customer.id) ||
    Object.values(pickupLocation).some(el => el === '') ||
    Object.values(dropoffLocation).some(el => el === '');

  return (
    <Form form={form} layout={layout} preserve={false}>
      <div className="order__raw">
        <div className="order__column">
          <StyledHeading align="center">{pickup.title}</StyledHeading>
          <Form.Item
            name={selectCustomer.name}
            label={selectCustomer.label}
            rules={[
              {
                required: true,
                message: 'Please select the customer',
              },
            ]}
          >
            <Select
              onChange={onSelectCustomer}
              style={selectCustomer.style}
              getPopupContainer={trigger => trigger.parentNode}
              options={subFleetCustomersOptions}
            />
          </Form.Item>
          <Form.Item
            label={pickupPhone.label}
            name={pickupPhone.name}
            // rules={pickupPhone.rules}
            initialValue={pickupPhone.initialValue(habitatOrder.pickup)}
            getValueProps={value => value}
            rules={[
              {
                required: true,
                validator: () => {
                  return PRONE_REGEX.test(+habitatOrder.pickup.contact_phone)
                    ? Promise.resolve(habitatOrder.pickup.contact_phone)
                    : Promise.reject(INVALID_NATIONAL_PHONE);
                },
              },
            ]}
          >
            <Input
              value={habitatOrder.pickup.contact_phone}
              onChange={setPickupPhone}
            />
          </Form.Item>
          <Form.Item
            name={pickupAddressSettings.name}
            label={pickupAddressSettings.label}
            rules={[
              {
                required: true,
                validator: () =>
                  checkAddress(habitatOrder.pickup.address, pickUpPredictions),
              },
            ]}
          >
            <GoogleAutoComplete
              handlersStore={{
                address:
                  pickupAddress || _.get(customer, 'address.description', ''),
                setAddress: setPickupAddress,
                setLocation: setPickupLocation,
              }}
            />
          </Form.Item>
          <Form.Item
            name={pickupNotes.name}
            label={pickupNotes.label}
            placeholder={pickupNotes.placeholder}
            initialValue={pickupNotes.initialValue(habitatOrder.pickup)}
            getValueProps={value => value}
          >
            <Input
              value={habitatOrder.pickup.notes}
              onChange={setPickupNotes}
            />
          </Form.Item>
          <MapContainer {...mapContainerProps}>
            <Form.Item>
              <GoogleMap
                setAddress={setPickupAddress}
                setLocation={setPickupLocation}
                latitude={pickupLocation.latitude || selectedSubFleet.latitude}
                longitude={
                  pickupLocation.longitude || selectedSubFleet.longitude
                }
                mapSettings={mapSettings}
              />
            </Form.Item>
          </MapContainer>
        </div>
        <div className="distance-wrapper">
          <h3>Round Trip Miles</h3>
          <p className="distance">
            (<span>Pick Up </span>
            <img width={24} height={24} src={DistanceIcon} alt="directions" />
            <span> Drop Off</span>)
          </p>
          <span className="distance-value">{pickupDropoffDistance}</span>
        </div>
        <div className="order__column">
          <StyledHeading align="center">{dropoff.title}</StyledHeading>
          <Form.Item
            label={dropoffName.label}
            name={dropoffName.name}
            rules={dropoffName.rules}
            initialValue={dropoffName.initialValue(habitatOrder.dropoff)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={dropoffPhone.label}
            name={dropoffPhone.name}
            rules={dropoffPhone.rules}
            initialValue={dropoffPhone.initialValue(habitatOrder.dropoff)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={dropoffAddressSettings.name}
            label={dropoffAddressSettings.label}
            rules={[
              {
                required: true,
                validator: () =>
                  checkAddress(
                    habitatOrder.dropoff.address,
                    dropOffPredictions
                  ),
              },
            ]}
          >
            <GoogleAutoComplete
              handlersStore={{
                address: dropoffAddress,
                setAddress: setDropoffAddress,
                setLocation: setDropoffLocation,
              }}
            />
          </Form.Item>
          <Form.Item
            name={dropoffNotes.name}
            label={dropoffNotes.label}
            placeholder={dropoffNotes.placeholder}
            initialValue={dropoffNotes.initialValue(habitatOrder.dropoff)}
          >
            <Input />
          </Form.Item>
          <MapContainer {...mapContainerProps}>
            <Form.Item style={{ marginBottom: 0, minHeight: 0 }}>
              <GoogleMap
                setAddress={setDropoffAddress}
                setLocation={setDropoffLocation}
                latitude={dropoffLocation.latitude || selectedSubFleet.latitude}
                longitude={
                  dropoffLocation.longitude || selectedSubFleet.longitude
                }
                mapSettings={mapSettings}
              />
            </Form.Item>
          </MapContainer>
        </div>
      </div>
      <div className="table-robots">
        <div className="fetching-robots">
          <div className="robot-title">
            <Button
              type="link"
              disabled={
                isLoadingRobots || shouldNotAllowSearchingAvailableRobots
              }
              onClick={updateAvailableRobots}
              style={{ width: 32, height: 40 }}
            >
              <img src={RobotImage} alt="robot" style={{ height: '100%' }} />
            </Button>
            <p>
              Available Robots{' '}
              {availableRobotsOptions.length > 0 && <span>(Select one)</span>}
            </p>
          </div>
          <div className="robot-caption">
            {!shouldNotAllowSearchingAvailableRobots ? (
              <p>
                Click to {hasAlreadyFetchedRobots ? 'refresh' : 'get'} available
                robots
              </p>
            ) : (
              <p>
                To get a list of available robots you should select a
                <span> Customer</span> and fill in <span>Drop off Address</span>{' '}
                field
              </p>
            )}
          </div>
        </div>
        <div className="table-wrapper">
          <Table
            columns={tableColumns}
            dataSource={availableRobotsOptions}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedItems,
              onChange: selectedRowKey => {
                setSelectedItems(selectedRowKey);
                setSelectedRobotId(selectedRowKey[0]);
              },
            }}
            size="small"
            bordered={true}
            pagination={false}
            scroll={{
              y: 120,
            }}
          />
          <p className="table-robots_subtitle">
            Total Trip Miles* = Current Robot Location --{'>'} Pickup +{' '}
            <span>Round Trip Miles</span>
          </p>
        </div>
        <Form.Item valuePropName="checked" name="addToQueue" style={{marginLeft: '20px'}}>
            <Space>
              <Switch
                defaultChecked={isSkipRobotAddToQueue}
                onChange={checked => {
                  setAddToQueue(checked)
                }}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
              <span>Add to the Queue</span>
            </Space>
          </Form.Item>
      </div>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
    ordersStore: rootStore.ordersStore,
    subFleetsStore: rootStore.subFleetsStore,
    robotsStore: rootStore.robotsStore,
    fleetsStore: rootStore.fleetsStore,
  })),
  observer
)(HabitatOrderForm);
