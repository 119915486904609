import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'services/helpers';
import Preloader from '../preloader';
import { StyledContent, StyledContentInner } from './styled';

const Content = ({ rootStore, children }) => {
  rootStore.pending
    ? (document.body.style.cursor = 'wait')
    : (document.body.style.cursor = 'default');
  return (
    <StyledContent>
      {rootStore.pending && <Preloader />}
      <StyledContentInner>{children}</StyledContentInner>
    </StyledContent>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore,
  })),
  observer
)(Content);
