import { action, computed, observable } from 'mobx';

import SubFleet from '../models/SubFleet';
import { buildParams, handleFilters } from '../services/helpers';
import { keys } from 'lodash';
import { subFleetColumns } from '../pages/subfleets/settings';

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
  filter: '',
};

class SubFleets {
  @observable rawAllSubFleets = [];
  @observable rawPageSubFleets = [];
  @observable rawPagination = DEFAULT_PAGINATION;
  @observable rawTotal;
  @observable rawColumns = subFleetColumns;
  @observable rawSelectedSubFleet = new SubFleet({}, this.rootStore);

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound async getAll() {
    const { method, url } = this.rootStore.urls.subFleets.getAll;
    this.rootStore.makeRequest(this.onGetAll, method, `${url}`);
  }

  @action.bound onGetAll(data) {
    this.rawAllSubFleets = data.items.map(
      subFleet => new SubFleet(subFleet, this.rootStore)
    );
  }

  @action.bound async getPage() {
    const { method, url } = this.rootStore.urls.subFleets.getAll;
    const params = buildParams({
      ...this.rawPagination,
    });

    this.rootStore.makeRequest(this.onGetPage, method, `${url}${params}`);
  }

  @action.bound onGetPage(data) {
    this.rawPageSubFleets = data.items.map(
      subFleet => new SubFleet(subFleet, this.rootStore)
    );
    this.rawTotal = data.pagination.total_count;
  }

  @action.bound async create(data) {
    const { method, url } = this.rootStore.urls.subFleets.create;
    return this.rootStore.makeRequest(this.onCreate, method, url, data);
  }

  @action.bound onCreate() {
    this.dropPagination();
    this.getPage();
  }

  @action.bound async update(data) {
    const { id } = this.rawSelectedSubFleet;
    if (!data && !id) return;
    const { method, url } = this.rootStore.urls.subFleets.update;
    return await this.rootStore.makeRequest(
      this.onUpdate,
      method,
      `${url}${id}`,
      data
    );
  }

  @action.bound onUpdate(data) {
    this.rawPageSubFleets = this.rawPageSubFleets.map(subFleet => {
      return subFleet.id === data.id
        ? new SubFleet(data, this.rootStore)
        : subFleet;
    });
  }

  @action.bound async deleteSubfleet() {
    const { id } = this.rawSelectedSubFleet;
    if (!id) return;
    const { method, url } = this.rootStore.urls.subFleets.delete;
    return await this.rootStore.makeRequest(
      () => {},
      method,
      `${url}${id}`
    );
  }

  @action.bound onDeleteSubfleet() {
    // the last subfleet on the page
    // set previous page
    if (this.rawPageSubFleets.length === 1) {
      const currentPage = this.rawPagination.current;
      this.rawPagination = {
        ...this.rawPagination,
        // if the currentPage != 1 then pick previous page
        // in case the current page is the only one keep current page = 1
        current: currentPage > 1 ? currentPage - 1 : currentPage,
      };
    }
    this.getPage();
  }

  @action.bound async changePagination(pagination, filter, sort) {
    this.rawPagination = {
      ...pagination,
      ...sort,
      filter: handleFilters(filter),
    };

    this.rawColumns = this.rawColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
      filteredValue: keys(filter).includes(column.key)
        ? filter[column.key]
        : false,
    }));
    // Refetch SubFleets with new pagination params
    this.getPage();
  }

  @action.bound dropPagination() {
    this.rawPagination = DEFAULT_PAGINATION;
    this.rawColumns = this.rawColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: false,
    }));
  }

  @action.bound onSelectSubFleet(id = '') {
    const selectedSubfleet = [
      ...this.rawPageSubFleets,
      ...this.rawAllSubFleets,
    ].find(e => e.id === id);
    this.rawSelectedSubFleet = new SubFleet(selectedSubfleet, this.rootStore);
  }

  @action.bound resetSelectedSubFleet() {
    this.rawSelectedSubFleet = new SubFleet({}, this.rootStore);
  }

  @computed get allSubFleets() {
    return this.rawAllSubFleets;
  }

  @computed get pageSubFleets() {
    return this.rawPageSubFleets;
  }

  @computed get selectedSubFleet() {
    return this.rawSelectedSubFleet;
  }

  @computed get total() {
    return this.rawTotal;
  }

  @computed get pagination() {
    return this.rawPagination;
  }

  @computed get columns() {
    return this.rawColumns;
  }
}

export default SubFleets;
