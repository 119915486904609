import Login from 'pages/login';
import Orders from 'pages/orders';
import Customers from 'pages/customers';
import Admins from 'pages/admins';
import Robots from 'pages/robots';
import Subfleets from 'pages/subfleets';
import RobotsMap from 'pages/robots-map';
import Versions from 'pages/versions';
import OrdersStatuses from 'pages/orders-statuses';

export const login = {
  path: '/login',
  component: Login,
};

export const ordersStatuses = {
  path: '/orders-statuses',
  component: OrdersStatuses,
};

export default [
  {
    path: '/versions',
    exact: true,
    component: Versions,
    roles: ['admin_versions', 'admin'],
  },
  { path: '/admins', exact: true, component: Admins, roles: ['admin'] },
  { path: '/customers', exact: true, component: Customers, roles: ['admin'] },
  { path: '/orders', exact: true, component: Orders, roles: ['admin'] },
  { path: '/robots', exact: true, component: Robots, roles: ['admin'] },
  { path: '/subfleets', exact: true, component: Subfleets, roles: ['admin'] },
  { path: '/robots-map', exact: true, component: RobotsMap, roles: ['admin'] },
  {
    path: '/orders-statuses',
    exact: true,
    component: OrdersStatuses,
    roles: ['customer_observer'],
  },
];
