import { action, computed, observable, observe } from 'mobx';
import { keys } from 'lodash';

import Robot from 'models/Robot';
import { FILTERS } from 'common/constants';
import { buildParams } from 'services/helpers';
import { columns } from 'pages/robots/settings';

const filterCityFromLS = (() => {
  const filteredCity = JSON.parse(localStorage.getItem(FILTERS));
  if (!filteredCity) return '';
  return filteredCity;
})();

const defaultFilters = {
  filter: '',
  subFleetIds: [],
  currentStates: [],
  names: [],
};

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
  filter: filterCityFromLS.filter || defaultFilters.filter,
  subFleetIds: filterCityFromLS.subFleetIds || defaultFilters.subFleetIds,
  currentStates: filterCityFromLS.currentStates || defaultFilters.currentStates,
  names: filterCityFromLS.names || defaultFilters.names,
};

class Robots {
  @observable rawPageRobots = [];
  @observable rawAllRobots = [];
  @observable rawPagination = DEFAULT_PAGINATION;
  @observable rawTotal;
  @observable rawColumns = columns;
  @observable rawRobotsByIds = [];
  @observable rawRobotsByFleetId = [];
  @observable rawRobotsAvailableByFleet = [];
  @observable rawRobotsAvailableByDistance = [];
  @observable selectedAvailableRobotId = '';
  @observable addToQueue = false;
  @observable rawSelectedCity = filterCityFromLS;

  constructor(rootStore) {
    this.rootStore = rootStore;
    observe(this, (change) => {
      // reset robots list when addToQueue is selected
      if (change.name === 'addToQueue' && change.newValue) {
        this.resetAvailableRobotsByDistance();
      }
    });
  }

  @action.bound async getAll() {
    const { method, url } = this.rootStore.urls.robots.getAll;
    return this.rootStore.makeRequest(this.onGetAll, method, `${url}`);
  }

  @action.bound onGetAll(data) {
    this.rawAllRobots = data.items.map(
      robot => new Robot(robot, this.rootStore)
    );
  }

  @action.bound async updateById({ id, param }) {
    const { method, url } = this.rootStore.urls.robots.update;
    return this.rootStore.makeRequest(
      this.onUpdateById,
      method,
      `${url}${id}${param ? `/${param}` : ''}`
    );
  }

  @action.bound onUpdateById(data) {
    this.rawPageRobots = this.rawPageRobots.map(robot => {
      return robot.id === data.id ? new Robot(data, this.rootStore) : robot;
    });
  }

  @action.bound onUpdateAll(updatedRobot) {
    const isRobotOld = this.rawAllRobots.find(
      robot => robot.id === updatedRobot.id
    );
    if (isRobotOld) {
      this.rawAllRobots = this.rawAllRobots.map(robot => {
        const {
          battery_voltage,
          latitude,
          longitude,
          odometer_mi,
        } = updatedRobot;
        return robot.id === updatedRobot.id
          ? {
              ...robot,
              ...updatedRobot,
              latitude: Number(latitude),
              longitude: Number(longitude),
              odometer_mi: Number(odometer_mi),
              battery_voltage: Number(battery_voltage),
            }
          : robot;
      });
    } else {
      this.rawAllRobots = [
        ...this.rawAllRobots,
        new Robot(updatedRobot, this.rootStore),
      ];
    }
  }

  @action.bound async getAllNoPagination() {
    const { method, url } = this.rootStore.urls.robots.getAllNoPagination;
    return this.rootStore.makeRequest(
      this.ongetAllNoPagination,
      method,
      `${url}`
    );
  }

  @action.bound ongetAllNoPagination(data) {
    this.rawAllRobots = data.items.map(
      robot => new Robot(robot, this.rootStore)
    );
  }

  @action.bound async getPage() {
    const { method, url } = this.rootStore.urls.robots.getAll;
    const params = buildParams({
      ...this.rawPagination,
    });
    return this.rootStore.makeRequest(
      this.onGetPage,
      method,
      `${url}${params}`
    );
  }

  @action.bound onGetPage(data) {
    this.rawPageRobots = data.items.map(
      robot => new Robot(robot, this.rootStore)
    );
    this.rawTotal = data.pagination.total_count;
  }

  @action.bound async changePagination(pagination, filter, sort = {}) {
    const currentFilters = {
      filter: filter['address'] || pagination.filter,
      subFleetIds:
        filter['subFleet'] === null
          ? []
          : filter['subFleet'] || pagination['subFleetIds'],
      currentStates:
        filter['current_state'] === null
          ? []
          : filter['current_state'] || pagination['currentStates'],
      names:
        filter['name'] === null ? [] : filter['name'] || pagination['names'],
    };
    this.rawPagination = {
      ...pagination,
      ...sort,
      ...currentFilters,
    };

    localStorage.setItem(
      FILTERS,
      JSON.stringify({
        ...currentFilters,
      })
    );

    if (this.rawColumns) {
      this.rawColumns = this.rawColumns.map(column => ({
        ...column,
        sortOrder: sort.columnKey === column.key ? sort.order : false,
        filteredValue: keys(filter).includes(column.key)
          ? filter[column.key]
          : [],
      }));
    }
  }

  @action.bound dropPagination() {
    this.rawPagination = { ...DEFAULT_PAGINATION, ...defaultFilters };
    this.rawColumns = this.rawColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: [],
    }));
    localStorage.setItem(FILTERS, JSON.stringify(defaultFilters));
  }

  @action.bound async getRobotsByIds(robotsIdsArr = []) {
    if (!robotsIdsArr.length) return;

    // Remove robots before fetching them for another SubFleet
    this.rawRobotsByIds = [];

    const { method, url } = this.rootStore.urls.robots.get;
    await Promise.allSettled(
      robotsIdsArr.map(id => {
        return this.rootStore.makeRequest(
          this.onGetByIds,
          method,
          `${url}${id}`
        );
      })
    );
  }

  @action.bound onGetByIds(data) {
    this.rawRobotsByIds = this.rawRobotsByIds.concat([
      new Robot(data, this.rootStore),
    ]);
  }

  @action.bound async getByFleetId(fleetId) {
    const { url, method } = this.rootStore.urls.robots.getByFleet;
    const queryString = new URLSearchParams({ fleet_id: fleetId });
    await this.rootStore.makeRequest(
      this.onGetByFleetId,
      method,
      `${url}?${queryString}&page=1&per_page=1100`
    );
  }

  @action.bound onGetByFleetId(data) {
    this.rawRobotsByFleetId = data.items.map(
      robot => new Robot(robot, this.rootStore)
    );
  }

  @action.bound async getAvailableBySubFleetId(subFleetId) {
    const { url, method } = this.rootStore.urls.robots.getAvailableBySubFleetId;
    const queryString = new URLSearchParams({ sub_fleet_ids: subFleetId });
    await this.rootStore.makeRequest(
      this.onGetAvailableBySubFleetId,
      method,
      `${url}?${queryString}`
    );
  }

  @action.bound onGetAvailableBySubFleetId(data) {
    if (Array.isArray(data) && data.length > 0) {
      this.rawRobotsAvailableByFleet = data.map(
        robot => new Robot(robot, this.rootStore)
      );
    }
  }

  @action.bound async getAvailableRobotsByDistance({
    customer_id,
    pickup,
    dropoff,
  }) {
    const {
      url,
      method,
    } = this.rootStore.urls.robots.getAvailableRobotsByDistance;
    const queryString = new URLSearchParams({
      customer_id,
      'pickup[lat]': pickup.lat,
      'pickup[lng]': pickup.lng,
      'dropoff[lat]': dropoff.lat,
      'dropoff[lng]': dropoff.lng,
    });
    return await this.rootStore.makeRequest(
      this.onGetAvailableRobotsByDistance,
      method,
      `${url}?${queryString}`
    );
  }

  @action.bound onGetAvailableRobotsByDistance(data) {
    this.rawRobotsAvailableByDistance =
      data.robots && Array.isArray(data.robots)
        ? data.robots.map(robot => new Robot(robot, this.rootStore))
        : [];
    return data;
  }

  @action.bound resetAvailableRobotsByDistance() {
    this.rawRobotsAvailableByDistance = [];
    this.selectedAvailableRobotId = '';
  }

  @action.bound resetSkipRobotAddToQueue() {
    this.addToQueue = false;
  }

  @action.bound setSelectedRobotId(robotId) {
    this.selectedAvailableRobotId = robotId;
  }

  @action.bound setAddToQueue(value) {
    this.addToQueue = value;
  }

  @computed get robotsByIdsData() {
    return this.rawRobotsByIds;
  }

  @computed get robotsByFleetIdData() {
    return this.rawRobotsByFleetId;
  }

  @computed get robotsAvailableByFleet() {
    return this.rawRobotsAvailableByFleet;
  }

  @computed get robotsAvailableByDistance() {
    return this.rawRobotsAvailableByDistance;
  }

  @computed get pageRobots() {
    return this.rawPageRobots;
  }

  @computed get allRobots() {
    return this.rawAllRobots;
  }

  @computed get total() {
    return this.rawTotal;
  }

  @computed get pagination() {
    return this.rawPagination;
  }

  @computed get columns() {
    return this.rawColumns;
  }

  @computed get selectedFleet() {
    return this.rawSelectedCity;
  }

  @computed get selectedRobot() {
    return this.selectedAvailableRobotId;
  }

  @computed get isSkipRobotAddToQueue() {
    return this.addToQueue;
  }
}

export default Robots;
