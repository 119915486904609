import { action, computed, observable, toJS } from 'mobx';
import { keys } from 'lodash';

import {
  buildParams,
  handleFilters,
  handleParams,
  normalizeCustomerData,
} from '../services/helpers';
import Customer from '../models/Customer';
import { columns } from '../pages/customers/settings';

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
};
class Customers {
  @observable rawAllCustomers = [];
  @observable rawCustomers = [];
  @observable rawCustomer = {};
  @observable totalCount = '';
  @observable rawSearchValue = '';
  @observable matchingCustomers = [];
  @observable rawPagination = DEFAULT_PAGINATION;
  @observable rawColumns = columns;
  @observable customersByFleetName = [];
  @observable polygon = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawCustomer = new Customer({}, rootStore);
  }

  @computed get allCustomers() {
    return this.rawAllCustomers;
  }

  @computed get pageCustomers() {
    return this.rawCustomers;
  }

  @computed get customer() {
    return this.rawCustomer;
  }

  @computed get columns() {
    return this.rawColumns;
  }

  @action.bound async getAll() {
    const { url, method } = this.rootStore.urls.customers.getAll;
    await this.rootStore.makeRequest(this.onGetAll, method, `${url}`);
  }

  @action.bound onGetAll(data) {
    this.rawAllCustomers = data.items.map(
      customer => new Customer(customer, this.rootStore)
    );
  }

  @action.bound async getByFleetName(fleetName, filters) {
    const filterByAddress = fleetName ? `address_${fleetName}` : '';
    const structuredParams = filters && handleParams(filters);
    const { url, method } = this.rootStore.urls.customers.getByFleet;

    await this.rootStore.makeRequest(
      this.onGetByFleetName,
      method,
      `${url}?${filterByAddress}${
        structuredParams ? `&${structuredParams}` : ''
        // Todo: remove per_page after changing logic of back-end
      }&per_page=1000`
    );
  }

  @action.bound onGetByFleetName(data) {
    this.customersByFleetName = data.items.map(
      customer => new Customer(customer, this.rootStore)
    );
  }

  @action.bound async getPage() {
    const { url, method } = this.rawSearchValue
      ? this.rootStore.urls.customers.search
      : this.rootStore.urls.customers.getAll;
    const params = buildParams({
      ...this.rawPagination,
      search: this.rawSearchValue,
    });

    await this.rootStore.makeRequest(this.onGetPage, method, `${url}${params}`);
  }

  @action.bound onGetPage(data) {
    this.rawCustomers = data.items.map(
      customer => new Customer(customer, this.rootStore)
    );
    this.totalCount = data.pagination.total_count;
  }

  @action.bound async changePagination(pagination, filter, sort) {
    this.rawPagination = {
      ...pagination,
      ...sort,
      filter: handleFilters(filter),
    };
    this.rawColumns = this.rawColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
      filteredValue: keys(filter).includes(column.key)
        ? filter[column.key]
        : false,
    }));

    this.getPage();
  }

  @action.bound dropPagination() {
    this.dropSearchValue();
    this.rawPagination = DEFAULT_PAGINATION;
    this.rawColumns = this.rawColumns.map(col => ({
      ...col,
      sortOrder: false,
      filteredValue: false,
    }));
  }

  @action.bound async getMatches(value) {
    const { method, url } = this.rootStore.urls.customers.matches;
    const params = buildParams({ search: value });

    await this.rootStore.makeRequest(this.onMatches, method, `${url}${params}`);
  }

  @action.bound onMatches(data) {
    this.matchingCustomers = data;
  }

  @action.bound findOne(id) {
    const selectedCustomer = [
      ...this.rawCustomers,
      ...this.rawAllCustomers,
    ].find(e => e.id === id);
    this.rawCustomer = new Customer(toJS(selectedCustomer), this.rootStore);
  }

  @action.bound async boundedOneByFleet(id) {
    const selectedCustomer = this.customersByFleetName.find(e => e.id === id);
    this.rawCustomer = new Customer(selectedCustomer, this.rootStore);
  }

  @action.bound resetSelectedCustomer() {
    this.rawCustomer = new Customer({}, this.rootStore);
  }

  @action.bound async create(values) {
    const { method, url } = this.rootStore.urls.customers.create;

    return await this.rootStore.makeRequest(
      this.onCreate,
      method,
      url,
      normalizeCustomerData({
        ...values,
        address: this.rawCustomer.addressDescription,
        latitude: this.rawCustomer.addressLatitude,
        longitude: this.rawCustomer.addressLongitude,
      })
    );
  }

  @action.bound async update({ customerId, ...values }) {
    const { method, url } = this.rootStore.urls.customers.update;

    return await this.rootStore.makeRequest(
      this.onUpdate,
      method,
      `${url}${customerId}`,
      normalizeCustomerData({
        ...values,
        address: this.rawCustomer.addressDescription,
        latitude: this.rawCustomer.addressLatitude,
        longitude: this.rawCustomer.addressLongitude,
      })
    );
  }

  @action.bound onCreate() {
    this.dropPagination();
  }

  @action.bound onUpdate(data) {
    this.rawCustomers = this.rawCustomers.map(customer => {
      return customer.id === data.id
        ? new Customer(data, this.rootStore)
        : customer;
    });

    this.resetSelectedCustomer();
  }

  @action.bound async search(search) {
    this.rawSearchValue = search;
    this.matchingCustomers = [];

    await this.getPage();
  }

  @action.bound async searchHabitat(selected, cb) {
    const { url, method } = this.rootStore.urls.customers.search;
    const params = buildParams({
      search: selected,
    });

    await this.rootStore.makeRequest(cb, method, `${url}${params}`);
  }

  @action.bound dropMatches() {
    this.matchingCustomers = [];
  }

  @action.bound dropSearchValue() {
    this.rawSearchValue = '';
  }

  @action.bound dropCustomersByIds() {
    this.customersByFleetName = [];
  }

  @action.bound async onStatusChange(id, status) {
    const { method, url } = this.rootStore.urls.customers.active;

    await this.rootStore.makeRequest(
      this.onStatusChanged,
      method(status),
      url(id)
    );
  }

  @action.bound onStatusChanged(responseData) {
    this.rawCustomers = this.rawCustomers.map(customer => {
      return customer.id === responseData.id
        ? new Customer(responseData, this.rootStore)
        : customer;
    });
  }

  @action.bound async onPolygonCreate(polygon) {
    this.polygon = [...polygon];
  }

  @action.bound async onPolygonSend(customerId) {
    if (!this.polygon.length) return;
    const { method, url } = this.rootStore.urls.customers.serviceArea;

    return await this.rootStore.makeRequest(
      this.onCreate,
      method,
      url(customerId),
      {
        coordinates: this.polygon,
      }
    );
  }

  @action.bound onPolygonDelete() {
    this.polygon = [];
  }

  @computed get total() {
    return this.totalCount;
  }

  @computed get searchValue() {
    return this.rawSearchValue;
  }

  @computed get pagination() {
    return this.rawPagination;
  }

  @computed get customersByFleet() {
    return this.customersByFleetName;
  }

  @computed get customerPolygon() {
    return this.polygon;
  }
}

export default Customers;
