import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { EMAIL_REGEX, REQUIRED_FIELD } from '../../../common/constants';
import { checkSpace, checkPhoneNumber } from '../../../services/helpers';

dayjs.extend(customParseFormat);

const getInitialValue = key => entity => {
  return entity ? entity[key] : '';
};

const getInitialValueTime = key => entity => {
  const time = entity ? entity[key] : null;
  return time ? dayjs(time, 'HH:mm') : time;
};

export const layout = 'vertical';

export const customerFields = {
  restaurantName: {
    name: 'name',
    label: 'Customer Name',
    rules: [
      () => ({
        required: true,
        validator(_, value) {
          if (value.trim() !== '') {
            return Promise.resolve();
          }
          return Promise.reject(new Error('This is required field'));
        },
      }),
    ],
    initialValue: getInitialValue('name'),
  },
  address: {
    name: 'address',
    label: 'Address',
    rules: [{ required: true, message: REQUIRED_FIELD }],
    initialValue: getInitialValue('description'),
  },
  restaurantPhone: {
    name: 'contact_phone',
    label: 'Customer Phone',
    rules: [checkPhoneNumber],
    initialValue: getInitialValue('contact_phone'),
  },
  contactName: {
    name: 'contact_name',
    label: 'Contact Name',
    rules: [
      () => ({
        required: true,
        validator(_, value) {
          if (value.trim() !== '') {
            return Promise.resolve();
          }
          return Promise.reject(new Error('This is required field'));
        },
      }),
    ],
    initialValue: getInitialValue('contact_name'),
  },
  email: {
    name: 'email',
    label: 'Email',
    rules: [
      {
        required: true,
        message: REQUIRED_FIELD,
        pattern: EMAIL_REGEX,
      },
    ],
    initialValue: getInitialValue('email'),
  },
  notes: {
    name: 'notes',
    label: 'Notes',
    initialValue: getInitialValue('notes'),
  },
  surveyLink: {
    name: 'survey_link',
    label: 'Survey Link',
    initialValue: getInitialValue('survey_link'),
    rules: [checkSpace],
  },
  slackLink: {
    name: 'slack_url',
    label: 'Slack Link',
    initialValue: getInitialValue('slack_url'),
    rules: [checkSpace],
  },
  sendSms: {
    name: 'send_sms',
    label: 'Refraction to send SMS messages',
    initialValue: getInitialValue('send_sms'),
  },
  externalIntegration: {
    name: 'external_integration',
    label: 'External Integration',
    initialValue: getInitialValue('external_integration'),
  },
  startAcceptOrdersAt: {
    name: 'start_accept_orders_at',
    label: 'Start Accepting Orders',
    initialValue: getInitialValueTime('start_accept_orders_at'),
    rules: [{ required: true, message: 'This is required field' }],
  },
  stopAcceptOrdersAt: {
    name: 'stop_accept_orders_at',
    label: 'Stop Accepting Orders',
    initialValue: getInitialValueTime('stop_accept_orders_at'),
    rules: [{ required: true, message: 'This is required field' }],
  },
  externalCustomerId: {
    name: 'external_customer_id',
    label: 'External Customer ID',
    initialValue: getInitialValue('external_customer_id'),
  },
  orderTimeout: {
    name: 'order_timeout',
    label: 'Min Order Timeout (minutes)',
    initialValue: getInitialValue('order_timeout'),
  },
  map: {
    zoom: 11,
    style: {
      maxWidth: '100%',
      height: '310px',
      transform: 'translate(0, -10px)',
    },
  },
  mapContainerProps: {
    height: '350px',
    display: 'flex',
    direction: 'column',
    justify: 'space-between',
    span: 11,
  },
};
