import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Form, Tooltip, message } from 'antd';
import dayjs from 'dayjs';

import {
  compose,
  prepareCustomerReview,
  formatPhoneNumber,
} from 'services/helpers';
import {
  modals,
  tableActions,
  createButton,
  resetSortButton,
  reviewCustomerColumns,
} from './settings';

import { Table } from 'components';
import Button from 'components/button';
import useModal from 'common/hooks/useModal';
import Modal from 'components/modal';
import { CustomerForm } from 'components/forms';
import useDebounce from 'common/hooks/useDebounce';
import AutoComplete from 'components/autocomplete';
import { pageSettings } from './settings';
import { ActionsBar, ActionsWrapper } from './styled';
import PolygonCreating from 'components/polygon';

const {
  autocomplete: { selectWidth, width: autocompleteWidth },
} = pageSettings;
const { updateCustomer, reviewCustomer, createPolygon } = modals;
const { editCustomer, customerDetails, customerPolygon } = tableActions;

const Customers = ({ customersStore, errorStore }) => {
  const {
    pageCustomers,
    total,
    customer,
    update,
    create,
    getPage,
    findOne,
    search,
    columns,
    getMatches,
    dropMatches,
    pagination,
    dropPagination,
    matchingCustomers,
    changePagination,
    resetSelectedCustomer,
    polygon,
    onPolygonSend,
    onPolygonDelete,
  } = customersStore;

  const { resetRobotError } = errorStore;
  const [form] = Form.useForm();
  const [searchStr, setSearchStr] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const value = useDebounce(searchStr, 200);

  useEffect(() => {
    value ? getMatches(value) : dropMatches();
  }, [value, getMatches, dropMatches]);

  useEffect(() => {
    const onMount = async () => {
      await getPage();
    };
    onMount();
  }, [getPage]);

  const handleChange = ({ target: { value } }) => {
    if (selectedCustomer) {
      setSelectedCustomer(null);
    }
    setSearchStr(value);
  };

  const handleSelect = selected => {
    setSelectedCustomer(selected);
    dropPagination();
    search(selected);
  };

  const handleSearch = () => {
    search(value);
  };

  const handleResetSort = () => {
    setSelectedCustomer(null);
    dropPagination();
    setSearchStr('');
    getPage();
  };

  const onUpdate = async () =>
    form.validateFields().then(async values => {
      const isUpdate = customer.id;
      values.name = values.name.trim();
      values.contact_name = values.contact_name.trim();
      values.contact_phone = formatPhoneNumber(values.contact_phone);
      values.start_accept_orders_at = dayjs(
        values.start_accept_orders_at
      ).format('HH:mm');
      values.stop_accept_orders_at = dayjs(values.stop_accept_orders_at).format(
        'HH:mm'
      );
      // convert order_timeout to number
      values.order_timeout = +values.order_timeout;
      const error = isUpdate
        ? await update({ ...values, customerId: customer.id })
        : await create({ ...values });

      if (!error) {
        onUpdateOkAction()();
        resetSelectedCustomer();
        dropPagination();
        getPage();

        if (!isUpdate) {
          setSelectedCustomer(null);
          setSearchStr('');
        }
      }
    });

  const onPolygonCreate = async () => {
    if (!polygon.length) return message.info('You need to finish your polygon');
    const error = await onPolygonSend(customer.id);
    if (!error) {
      onPolygonDelete();
      onPolygonOkAction()();
      await getPage();
      await resetSelectedCustomer();
      return message.success('Polygon created');
    } else {
      resetRobotError.bind(errorStore)();
    }
  };

  const [
    isUpdateModalShown,
    onSetIsUpdateModalShown,
    onUpdateOkAction,
    onUpdateCancelAction,
  ] = useModal();
  const [
    isReviewModalShown,
    onSetIsReviewModalShown,
    onReviewOkAction,
    onReviewCancelAction,
  ] = useModal();
  const [
    isPolygonModalShown,
    onSetIsPolygonModalShown,
    onPolygonOkAction,
    onPolygonCancelAction,
  ] = useModal();

  const renderActions = row => (
    <ActionsWrapper>
      <Tooltip title={editCustomer.tooltip}>
        <div>
          <Button
            style={customerDetails.style}
            type={editCustomer.type}
            icon={editCustomer.icon}
            onClick={onSetIsUpdateModalShown(() => findOne(row.id))}
          />
        </div>
      </Tooltip>
      <Tooltip title={customerDetails.tooltip}>
        <div>
          <Button
            style={customerDetails.style}
            type={customerDetails.type}
            icon={customerDetails.icon}
            onClick={onSetIsReviewModalShown(() => findOne(row.id))}
          />
        </div>
      </Tooltip>
      <Tooltip title={customerPolygon.tooltip}>
        <div>
          <Button
            style={customerPolygon.style}
            type={customerPolygon.type}
            icon={customerPolygon.icon}
            onClick={onSetIsPolygonModalShown(() => findOne(row.id))}
          />
        </div>
      </Tooltip>
    </ActionsWrapper>
  );

  return (
    <>
      <ActionsBar type="flex">
        <Col>
          <Button
            type={createButton.type}
            icon={createButton.icon}
            shape={createButton.shape}
            onClick={onSetIsUpdateModalShown()}
          >
            {createButton.title}
          </Button>
        </Col>
        <Col>
          <Button
            type={resetSortButton.type}
            shape={resetSortButton.shape}
            onClick={handleResetSort}
          >
            {resetSortButton.title}
          </Button>
        </Col>
      </ActionsBar>
      <AutoComplete
        search={true}
        searchValue={selectedCustomer || searchStr}
        onSearch={handleSearch}
        onChange={handleChange}
        onSelect={handleSelect}
        options={matchingCustomers}
        selectWidth={selectWidth}
        width={autocompleteWidth}
      />
      <Modal
        title={updateCustomer.title}
        visible={isUpdateModalShown}
        width={updateCustomer.width}
        height={updateCustomer.height}
        destroyOnClose
        onOk={onUpdate}
        onCancel={onUpdateCancelAction(resetSelectedCustomer)}
      >
        <CustomerForm form={form} />
      </Modal>
      <Modal
        style={{ top: '40px' }}
        title={reviewCustomer.showTitle(customer)}
        width={reviewCustomer.width}
        visible={isReviewModalShown}
        destroyOnClose
        onOk={onReviewOkAction(resetSelectedCustomer)}
        onCancel={onReviewCancelAction(resetSelectedCustomer)}
      >
        <Table
          isModal
          showHeader={false}
          columns={reviewCustomerColumns}
          data={prepareCustomerReview(customer)}
          pagination={false}
        />
      </Modal>
      <Modal
        title={`Polygon for: ${customer.name}`}
        width={createPolygon.width}
        height={'100%'}
        visible={isPolygonModalShown}
        destroyOnClose
        onOk={onPolygonCreate}
        onCancel={onPolygonCancelAction()}
      >
        <PolygonCreating />
      </Modal>
      <Table
        columns={columns}
        data={pageCustomers}
        renderActions={renderActions}
        paginationTotal={total}
        pagination={pagination}
        onChange={changePagination}
      />
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
    errorStore: rootStore.errorStore,
  })),
  observer
)(Customers);
