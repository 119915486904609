import React, { useState } from 'react';

import LongText from '../../long-text/long-text';
import { cellSettings } from './settings';
import { TipTitle } from './styled';

const {
  initialNotesLength,
  shortTextTitle,
  longTextTitle,
  isShortText,
  showLess,
} = cellSettings;

const CustomerNotesCell = ({ text }) => {
  const [limit, setLimit] = useState(initialNotesLength);
  const [tip, setTip] = useState(shortTextTitle);
  const showLessTitle = showLess(text, limit);
  const setValues = (limit, tip) => {
    setLimit(limit);
    setTip(tip);
  };
  const handleClickTip = () => {
    if (isShortText(limit)) {
      return setValues(Infinity, longTextTitle);
    }

    return setValues(initialNotesLength, shortTextTitle);
  };

  return (
    <LongText
      text={text}
      limit={limit}
      tipTitle={<TipTitle>{tip}</TipTitle>}
      onClick={handleClickTip}
      showLessTitle={showLessTitle}
    />
  );
};

export default CustomerNotesCell;
