import React, { forwardRef, useState } from 'react';
import { Input } from 'antd';

import { searchAttributes } from './settings';

const { Search: AntdSearch } = Input;
const { placeholder, enterButton, size, allowClear } = searchAttributes;

const Search = forwardRef(({ onSearch, onChange, defaultValue }, ref) => {
  const [loading, setLoading] = useState(false);
  const changeLoading = () => setLoading(prevValue => !prevValue);
  const handleSearch = async (value, event) => {
    changeLoading();
    await onSearch(value);
    changeLoading();
  };
  const handleEnter = async e => {
    const { value } = e.target;

    await onSearch(value);
  };
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <AntdSearch
      placeholder={placeholder}
      enterButton={enterButton}
      onPressEnter={handleEnter}
      onSearch={handleSearch}
      onChange={handleChange}
      size={size}
      allowClear={allowClear}
      loading={loading}
      value={defaultValue}
    />
  );
});

export default Search;
