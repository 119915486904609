import React, { useState } from 'react';
import { Form, Row, Col } from 'antd';

import { StyledContent, StyledSelect, StyledStatus } from './styled';
import { deliverySelectOptions, scheduledStatus } from '../../../common/constants';
import { statusSelect } from './settings';
import If from '../../if';

const OrderStateForm = ({ form, state, orderStatus }) => {
  const [orderState, setOrderState] = useState('');
  const handleChange = value => setOrderState(value);

  return (
    <Form layout={'vertical'} form={form} preserve={false}>
      <Row>
        <Col span={24}>
          <Form.Item
            label={statusSelect.label}
            name={statusSelect.name}
            rules={statusSelect.rules}
            initialValue={state}
          >
            <StyledSelect
              onChange={handleChange}
              options={
                orderStatus !== scheduledStatus
                  ? deliverySelectOptions
                  : deliverySelectOptions.filter(
                      option => option.key === 'cancelled'
                    )
              }
            />
          </Form.Item>
          <If condition={orderState}>
            <StyledContent>
              {statusSelect.content}
              <StyledStatus>{orderState}</StyledStatus>?
            </StyledContent>
          </If>
        </Col>
      </Row>
    </Form>
  );
};

export default OrderStateForm;
