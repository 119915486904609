import { Row } from 'antd';
import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ActionsBar = styled(Row)`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;
