import { DEFAULT_LAT, DEFAULT_LNG } from '../../common/constants';
import Geocode from 'react-geocode';

export const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

Geocode.setApiKey(apiKey);
Geocode.enableDebug();

export const geocode = Geocode;
export const getInitialCoords = (lat, lng) =>
  lat && lng ? { lat, lng } : { lat: DEFAULT_LAT, lng: DEFAULT_LNG };

export const autocomplete = {
  style: {
    zIndex: 1,
    width: '100%',
    height: '30px',
  },
};
export const getMapInitialValues = dropoff => {
  const { latitude: lat, longitude: lng } = dropoff.location;

  return {
    zoom: 5,
    style: { width: '300px', height: '300px' },
    center: getInitialCoords(lat, lng),
  };
};
export const getMarkerInitialValues = ({ latitude: lat, longitude: lng }) =>
  getInitialCoords(lat, lng);
