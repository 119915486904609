import { action, computed, observable } from 'mobx';
import { message } from 'antd';

import { getErrorText, isRobotError } from '../services/helpers';
import { ERROR_STATUSES } from '../common/constants';

class Error {
  status = '';
  errorStatuses = ERROR_STATUSES;
  @observable robotErrorMessage = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.message = message;
  }

  get isValidationError() {
    return this.status === 'validationError';
  }

  get isUnauthorized() {
    return this.status === 'authorizationError';
  }

  set errorStatus(code) {
    this.status = this.errorStatuses[code] || 'rejected';
  }

  @action resetRobotError() {
    this.robotErrorMessage = '';
  }

  @computed get robotError() {
    return this.robotErrorMessage;
  }

  @action handleError(error) {
    this.errorStatus = error.status;

    if (isRobotError(error)) {
      return (this.robotErrorMessage = getErrorText(error));
    }

    if (this.isValidationError) {
      const errorText = getErrorText(error) || 'Validation error';
      return this.message.error(errorText);
    }

    if (this.isUnauthorized) {
      const errorText = getErrorText(error) || 'Unauthorized';
      return this.message.error(errorText);
    }

    if (!error.status) {
      return this.message.error('Internet connection error');
    }

    this.message.error('An error occur, please try again');
  }
}

export default Error;
