import React, { useCallback, useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Form,
  Input,
  Row,
  Col,
  Switch,
  Space,
  TimePicker,
  InputNumber,
  Select,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { GoogleAutoComplete, GoogleMap, MapContainer } from '../../google-map';
import { apiKey } from '../../google-map/settings';

import { compose } from '../../../services/helpers';
import { checkAddress } from '../order/settings';
import { customerFields, layout } from './settings';

const { TextArea } = Input;
const {
  restaurantName,
  address,
  restaurantPhone,
  notes,
  contactName,
  map,
  mapContainerProps,
  email,
  surveyLink,
  slackLink,
  startAcceptOrdersAt,
  stopAcceptOrdersAt,
  orderTimeout,
  sendSms,
  externalIntegration,
  externalCustomerId,
} = customerFields;

const CustomerForm = ({
  customersStore,
  form,
  rootStore: { settingsExternalIntegrations },
}) => {
  const { customer } = customersStore;
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey,
  });

  useEffect(() => {
    getPlacePredictions({ input: customer.addressDescription });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.addressDescription]);

  useEffect(() => {
    if (!customer.addressDescription || !form.isFieldsTouched()) return;
    form.validateFields(['address'], { force: true });
  }, [placePredictions, form, customer.addressDescription]);

  const externalIntegrationOptions = useMemo(() => {
    if (!settingsExternalIntegrations.length) return;
    return settingsExternalIntegrations.map(item => ({
      text: item,
      value: item,
    }));
  }, [settingsExternalIntegrations]);

  /** Hack to move Google PAC container following scroll changes */
  const modalWrap = document.querySelector('.ant-modal-wrap');
  const pacInputs = Array.from(
    document.getElementsByClassName('pac-target-input')
  );
  const pacContainers = Array.from(
    document.getElementsByClassName('pac-container')
  );

  const movePacContainer = useCallback(
    ({ pacInputs, pacContainers }) => () => {
      if (pacInputs.length && pacContainers.length) {
        const [activeInput] = pacInputs.filter(
          el => el === document.activeElement
        );
        const [openedPacContainer] = pacContainers.filter(el => {
          return el.style.display !== 'none';
        });

        if (activeInput && openedPacContainer) {
          const { top, height } = activeInput.getBoundingClientRect();
          openedPacContainer.style.top = `${top + height}px`;
        }
      }
    },
    []
  );

  useEffect(() => {
    if (modalWrap) {
      modalWrap.addEventListener(
        'scroll',
        movePacContainer({ pacInputs, pacContainers })
      );
    }

    return () => {
      if (modalWrap) modalWrap.removeEventListener('scroll', movePacContainer);
    };
  }, [modalWrap, pacInputs, pacContainers, movePacContainer]);

  return (
    <Form form={form} layout={layout} preserve={false}>
      <Row justify="space-between" align="baseline">
        <Col span={11}>
          <Form.Item
            label={restaurantName.label}
            name={restaurantName.name}
            rules={restaurantName.rules}
            initialValue={restaurantName.initialValue(customer)}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Row justify="space-between" gutter={16}>
            <Col span={12}>
              <Form.Item
                label={restaurantPhone.label}
                name={restaurantPhone.name}
                rules={restaurantPhone.rules}
                initialValue={restaurantPhone.initialValue(customer)}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={email.label}
                name={email.name}
                rules={email.rules}
                initialValue={email.initialValue(customer)}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label={contactName.label}
            name={contactName.name}
            rules={contactName.rules}
            initialValue={contactName.initialValue(customer)}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label={<>{slackLink.label}</>}
            name={slackLink.name}
            rules={slackLink.rules}
            tooltip="Please insert the slack channel link"
            initialValue={slackLink.initialValue(customer)}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={11}>
          <Form.Item
            label={address.label}
            name={address.name}
            initialValue={customer.addressDescription}
            rules={[
              {
                required: true,
                validator: () =>
                  checkAddress(customer.addressDescription, placePredictions),
              },
            ]}
          >
            <GoogleAutoComplete
              handlersStore={{
                address: address.initialValue(customer.address),
                setAddress: customer.setAddress,
                setLocation: customer.setLocation,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label={<>{surveyLink.label}</>}
            name={surveyLink.name}
            rules={surveyLink.rules}
            tooltip="You can post the link for CFA locations only"
            initialValue={surveyLink.initialValue(customer)}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <MapContainer {...mapContainerProps}>
          <Form.Item>
            <GoogleMap
              setAddress={customer.setAddress}
              setLocation={customer.setLocation}
              latitude={customer.addressLatitude}
              longitude={customer.addressLongitude}
              mapSettings={map}
            />
          </Form.Item>
        </MapContainer>
        <Col span={11}>
          <Row justify="space-between" style={{ alignItems: 'end' }}>
            <Col span={12}>
              <Form.Item
                label={externalIntegration.label}
                name={externalIntegration.name}
                initialValue={
                  externalIntegration.initialValue(customer) || 'None'
                }
              >
                <Select
                  placeholder="Select integration"
                  options={externalIntegrationOptions}
                  getPopupContainer={trigger => trigger.parentNode}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item valuePropName="checked" name={sendSms.name}>
                <Space>
                  <Switch
                    defaultChecked={sendSms.initialValue(customer)}
                    onChange={checked => {
                      form.setFieldsValue({ [sendSms.name]: checked });
                    }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                  <span>{sendSms.label}</span>
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={notes.label}
            name={notes.name}
            rules={notes.rules}
            initialValue={notes.initialValue(customer)}
          >
            <TextArea rows={1} allowClear />
          </Form.Item>
          <Form.Item
            label={<>{externalCustomerId.label}</>}
            name={externalCustomerId.name}
            initialValue={externalCustomerId.initialValue(customer)}
          >
            <Input />
          </Form.Item>

          <Row justify="space-between" gutter={16}>
            <Col span={8}>
              <Form.Item
                label={<>{startAcceptOrdersAt.label}</>}
                name={startAcceptOrdersAt.name}
                rules={startAcceptOrdersAt.rules}
                initialValue={startAcceptOrdersAt.initialValue(customer)}
                tooltip="Times are based on the restaurant’s time zone"
              >
                <TimePicker format="HH:mm" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<>{stopAcceptOrdersAt.label}</>}
                name={stopAcceptOrdersAt.name}
                rules={stopAcceptOrdersAt.rules}
                initialValue={stopAcceptOrdersAt.initialValue(customer)}
                tooltip="Times are based on the restaurant’s time zone"
              >
                <TimePicker format="HH:mm" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={orderTimeout.label}
                name={orderTimeout.name}
                initialValue={orderTimeout.initialValue(customer)}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min="2"
                  max="90"
                  step="0.1"
                  onChange={value => {
                    form.setFieldsValue({ [orderTimeout.name]: value });
                  }}
                  stringMode
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
    rootStore,
  })),
  observer
)(CustomerForm);
