import { observable, action, computed } from 'mobx';

class DeliveryHistory {
  @observable rawHistory = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound onGetAll(data) {
    this.rawHistory = data.items;
  }

  @action.bound async getById(id) {
    const { url, method } = this.rootStore.urls.deliveryHistory.getById;
    await this.rootStore.makeRequest(this.onGetAll, method, url(id));
  }

  @action.bound resetHistory() {
    this.rawHistory = [];
  }

  @computed get historyData() {
    return this.rawHistory;
  }
}

export default DeliveryHistory;
