import styled from 'styled-components';

import { Table } from 'components';

export const StyledTable = styled(Table)`
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: inherit;
  }

  .colored--light-blue {
    background: #f4faff;
  }
  .colored--light-blue td {
    transition: none;
  }
  .colored--light-blue td.ant-table-column-sort {
    background: #f4faff;
  }

  .ant-table-row {
    &.status-at_pickup {
      background-color: #ffdddd;
      &:hover > td {
        background-color: transparent;
      }
    }

    &.status-hailed {
      background-color: #feffd3;
      &:hover > td {
        background-color: transparent;
      }
    }
  }
`;
