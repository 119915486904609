import React from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { notification } from 'antd';
import {
  ROBOT_ERRORS,
  ROBOT_ERROR_OUTSIDE_AREA,
  PRONE_REGEX,
  INVALID_NATIONAL_PHONE,
  REQUIRED_FIELD,
} from '../common/constants';
import RobotInfoNotification from '../components/notifications/RobotInfoNotification';
import { utcToCurrentTime } from '../pages/orders/settings';

export const compose = (...funcs) => {
  if (funcs.length === 0) {
    return arg => arg;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
};

export const normalizeAuthResponse = ({
  user_id,
  username,
  access_token,
  refresh_token,
  access_token_expires_at,
  refresh_token_expires_at,
  permission_names,
}) => ({
  userId: user_id,
  username: username,
  token: access_token,
  refreshToken: refresh_token,
  tokenExpiresAt: access_token_expires_at,
  refreshTokenExpiresAt: refresh_token_expires_at,
  permission_names,
});

export const titleToUpperCase = entity => {
  if (entity.title.hasOwnProperty('props')) {
    return {
      ...entity,
      title: {
        ...entity.title,
        props: {
          ...entity.title.props,
          children: entity.title.props.children.toUpperCase(),
        },
      },
    };
  }

  return {
    ...entity,
    title: entity.title.toUpperCase(),
  };
};

export const normalizeCustomerData = rawData => {
  const { address, latitude, longitude, ...rest } = rawData;

  return { ...rest, address: { description: address, latitude, longitude } };
};

export const normalizeOrderDetails = ({ pickup, dropoff, ...rest }) => [
  {
    key: 'Order Id',
    value: rest.order_id,
  },
  {
    key: 'Created At',
    value: utcToCurrentTime(rest.created_at),
  },
  {
    key: 'Restaurant Name',
    value: pickup.name,
  },
  {
    key: 'Restaurant Pickup Address',
    value: pickup.address,
  },
  {
    key: 'Order Status',
    value: rest.current_state,
  },
  {
    key: 'Deliver to Name',
    value: dropoff.contact_name,
  },
  {
    key: 'Deliver to Address',
    value: dropoff.address,
  },
  {
    key: 'Deliver to Phone',
    value: dropoff.contact_phone,
  },
  {
    key: 'Notes',
    value: dropoff.notes,
  },
  {
    key: 'Delivery Time',
    value: `${dropoff.estimated_arrival.minimum || '-'} / ${dropoff
      .estimated_arrival.maximum || '-'}`,
  },
];

export const normalizeDeliveryHistory = rest => [
  {
    key: 'External Delivery ID',
    value: rest.ext_order_id,
  },
  {
    key: 'Delivery ID',
    value: rest.delivery_id,
  },
  {
    key: 'Current Status',
    value: rest.current_state,
  },
  {
    key: 'Failed Reason',
    value: rest.failed_reason,
  },
  {
    key: 'Cancel Reason',
    value: rest.cancel_reason,
  },
  {
    key: 'Error Message',
    value: rest.errors_message,
  },
  {
    key: 'Robot Name',
    value: rest.robot_name,
  },

  {
    key: 'Customer Name',
    value: rest.customer_name,
  },
  {
    key: 'Dropoff Address',
    value: rest.dropoff_address,
  },
  {
    key: 'Dropoff Notes',
    value: rest.dropoff_notes,
  },
  {
    key: 'Attempt Counter',
    value: rest.attempt_counter.toString(),
  },
  {
    key: 'User',
    value: rest.username,
  },
  ...('chaser_present' in rest
    ? [
        {
          key: 'Chaser Present?',
          value: (function() {
            if (rest.chaser_present) return 'Yes';
            return 'No';
          })(),
        },
      ]
    : [
        {
          key: 'Chaser Present?',
          value: 'N/A',
        },
      ]),
  {
    key: 'Delivery Time',
    value: `${rest.no_earlier_than || '-'} / ${rest.no_later_than || '-'}`,
  },
];

export const prepareCustomerReview = customer => [
  {
    key: 'Name',
    value: customer.name,
  },
  {
    key: 'Contact Phone',
    value: customer.contact_phone,
  },
  {
    key: 'Contact Name',
    value: customer.contact_name,
  },
  {
    key: 'Address',
    value: customer.address.description,
  },
  {
    key: 'Latitude',
    value: customer.address.latitude,
  },
  {
    key: 'Longitude',
    value: customer.address.longitude,
  },
  {
    key: 'Slack Link',
    value: customer.slack_url,
  },
  {
    key: 'Survey Link',
    value: customer.survey_link,
  },
  {
    key: 'Notes',
    value: customer.notes,
  },
  {
    key: 'Min Order Timeout',
    value: `${customer.order_timeout} minutes`,
  },
  {
    key: 'Start/Stop Accepting Orders',
    value: `${customer.start_accept_orders_at} / ${customer.stop_accept_orders_at}`,
  },
];

export const isRobotError = error => {
  const text = getErrorText(error);

  // added the customer as it also has ROBOT_ERROR_OUTSIDE_AREA text
  if (text.includes("The customer's")) return false;
  return text
    ? ROBOT_ERRORS.includes(text) || text.includes(ROBOT_ERROR_OUTSIDE_AREA)
    : false;
};

export const getErrorText = error => {
  if (!error.response) return;
  return JSON.parse(error.response.text).error.message;
};

export const buildParams = ({
  current,
  pageSize,
  columnKey,
  order,
  search,
  dateFrom,
  dateTo,
  filter,
  names,
  subFleetIds,
  currentStates,
  status,
}) => {
  return getParamStr({
    dateFrom,
    dateTo,
    query: search,
    page: current,
    per_page: pageSize,
    sorted_by: getSortStr(columnKey, order),
    filter,
    sub_fleet_ids: subFleetIds,
    current_states: currentStates,
    status,
    names,
  });
};

const getSortStr = (columnKey, order) => {
  return columnKey && order ? `${columnKey}_${order.slice(0, -3)}` : '';
};

export const getParamStr = params => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      const a = value.reduce(
        (acc, val) => `${acc}${acc.length ? '&' : ''}${key}[]=${val}`,
        ''
      );
      return value.length ? `${acc}${acc.length ? '&' : ''}${a}` : acc;
    }
    return value ? `${acc}${acc ? '&' : '?'}${key}=${value}` : acc;
  }, '');
};

export const handleFilters = filters => {
  return Object.entries(filters)
    .reduce((acc, [key, value]) => {
      if (!value) return acc;
      if (key === 'address')
        return `${acc}address_${getAddressFilterStr(value)},`;

      return `${acc}${key}_${value},`;
    }, '')
    .slice(0, -1);
};

export const handleParams = params => {
  return Object.entries(params)
    .reduce((acc, [key, value]) => {
      return `${acc}${key}=${value}&`;
    }, '')
    .slice(0, -1);
};

const getAddressFilterStr = addresses => {
  return addresses.map(address => address.key).join('@');
};

export const mapEntitiesForSelectOptions = ({ name, id }) => ({
  label: name,
  value: id,
});

export const mapObjectsForSelectOptions = ({ name, id }) => ({
  label: name,
  value: id,
});

export const mapEntitiesForSelectRobots = ({
  name,
  id,
  battery_remain_mi,
}) => ({
  label: `${name} -- ${battery_remain_mi || 0} miles`,
  value: id,
});

export const hideTooltip = active => {
  return !active ? {} : { pointerEvents: 'none' };
};

export const checkSpace = () => ({
  validator(_, value) {
    if (!value) return Promise.resolve();
    if (!(value.indexOf(' ') >= 0)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Incorrect link'));
  },
});

export const checkPhoneNumber = () => ({
  required: true,
  validator: (_, value) => {
    let parsedObject;
    let parsedNumber;

    if (!value) {
      return Promise.reject(REQUIRED_FIELD);
    }

    parsedObject = parsePhoneNumber(value, 'US');
    parsedNumber = parsedObject && parsedObject.nationalNumber;

    return PRONE_REGEX.test(+value) || PRONE_REGEX.test(parsedNumber)
      ? Promise.resolve(value)
      : Promise.reject(INVALID_NATIONAL_PHONE);
  },
});

export const openNotification = robotData => {
  const { name } = robotData;
  notification.info({
    message: `Robot name: ${name}`,
    description: <RobotInfoNotification robotData={robotData} />,
    placement: 'topRight',
    duration: 7,
  });
};

// according to https://stackoverflow.com/questions/3548920/google-maps-api-v3-multiple-markers-on-exact-same-spot
export const changeCoordinatePresigion = (coord, modifier = 1) =>
  coord + modifier * 0.000006;

export const dayAgo = date => {
  const hour = 1000 * 60 * 60 * 24;
  const hourago = new Date() - hour;
  return new Date(date) < hourago;
};

export const formatPhoneNumber = value => {
  const parsedObject = parsePhoneNumber(value, 'US');
  const parsedNumber = parsedObject && parsedObject.nationalNumber;

  return parsedNumber || value;
};

export const isOdd = value => value % 2 !== 0;
