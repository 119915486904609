import React from 'react';

import { Button as AntdButton } from 'antd';

const Button = ({
  type = 'default',
  size = 'middle',
  color = '',
  icon = '',
  children,
  onClick,
  shape,
  disabled,
  style = {},
}) => (
  <AntdButton
    type={type}
    color={color}
    icon={icon}
    onClick={onClick}
    shape={shape}
    style={{ color, ...style }}
    size={size}
    disabled={disabled}
  >
    {children}
  </AntdButton>
);

export default Button;
