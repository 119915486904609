import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu as AntdMenu } from 'antd';
import { inject, observer } from 'mobx-react';

import { compose } from 'services/helpers';
import { menu, menuItems } from './settings';

const Menu = ({ location }) => (
  <AntdMenu
    theme={menu.theme}
    mode={menu.mode}
    selectedKeys={[location.pathname]}
  >
    {menuItems.map(({ title, icon, href }) => (
      <AntdMenu.Item key={href}>
        <Link to={href}>
            {icon}
            {title}
        </Link>
      </AntdMenu.Item>
    ))}
  </AntdMenu>
);

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore,
  })),
  withRouter,
  observer
)(Menu);
