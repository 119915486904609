import { observable, action, computed } from 'mobx';
import { api } from '../services';
import { normalizeAuthResponse } from '../services/helpers';

import ls from '../services/local-storage';

class Auth {
  @observable data = {
    userId: '',
    token: '',
    refreshToken: '',
    username: '',
    permission_names: [],
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get role() {
    return this.data.admin.role || '';
  }

  @action.bound set(data) {
    if (!data) return;
    this.data = data;
  }

  @action.bound async onLoginSuccess(data) {
    const normalizedData = normalizeAuthResponse(data);
    this.set(normalizedData);
    await ls.save('user', normalizedData);
  }

  @action.bound async login(data) {
    const { method, url } = this.rootStore.urls.login;
    return this.rootStore.makeRequest(this.onLoginSuccess, method, url, data);
  }

  @action.bound async logout() {
    const { method, url } = this.rootStore.urls.logout;
    return this.rootStore.makeRequest(api.logout, method, url);
  }

  @computed get username() {
    return this.data.username;
  }

  @computed get token() {
    return this.data.token;
  }

  @computed get permissionNames() {
    return this.data.permission_names;
  }
}

export default Auth;
