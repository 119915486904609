import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

export const StyledContent = styled(Content)`
  overflow: 'initial';
`;

export const StyledContentInner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 32px);
  padding: 24px;
  background: #fff;
  margin: 16px;
`;
