import { observable } from 'mobx';

class Fleet {
  @observable id;
  @observable name;
  @observable slack_url;

  constructor(fleet, rootStore) {
    Object.assign(this, fleet);
    this.rootStore = rootStore;
  }
}

export default Fleet;
