import React from 'react';
import { robotStatusBadges } from '../subfleets/settings';
import { robotVoltageStyledResults } from './styled';

export const columns = [
  {
    title: 'Subfleet Name',
    dataIndex: 'sub_fleet_name',
    key: 'subFleet',
    fixed: 'left',
    width: 164,
    render: subFleetName => (subFleetName ? subFleetName : '-'),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    fixed: 'left',
    width: 130,
  },
  {
    title: 'Current State',
    dataIndex: 'current_state',
    key: 'current_state',
    fixed: 'left',
    width: 160,
    render: s => robotStatusBadges(s),
    filters: [
      {
        text: 'on delivery',
        value: 'on_delivery',
      },
      {
        text: 'available',
        value: 'available',
      },
      {
        text: 'assigned',
        value: 'assigned',
      },
      {
        text: 'out of service',
        value: 'out_of_service',
      },
    ],
  },
  {
    title: 'Battery Voltage',
    dataIndex: 'battery_voltage',
    width: 160,
    align: 'center',
    render: i => robotVoltageStyledResults(i),
  },
  {
    title: 'Mileage Remaining',
    dataIndex: 'battery_remain_mi',
    width: 170,
    align: 'center',
  },
  {
    title: 'Odometer Mi',
    dataIndex: 'odometer_mi',
    width: 130,
    align: 'center',
  },
  {
    title: 'Current Driver',
    dataIndex: 'current_driver',
    width: 150,
    render: chaser =>
      chaser && (
        <>
          {chaser.operator_id}
          <br />
          {chaser.operator_name}
        </>
      ),
  },
  {
    title: 'Current Chaser',
    dataIndex: 'current_chaser',
    width: 158,
    render: chaser =>
      chaser && (
        <>
          {chaser.operator_id}
          <br />
          {chaser.operator_name}
        </>
      ),
  },
];

export const robots = {
  buttons: {
    resetSort: { title: 'Reset filter', shape: 'round', type: 'primary' },
  },
};
