import { observable, action, computed } from 'mobx';

import { rejectedColumns } from '../pages/orders/settings';
import { buildParams } from '../services/helpers';

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
  filter: '',
};

class RejectedOrders {
  @observable rawOrders = [];
  @observable totalCount = 0;
  @observable rawPagination = DEFAULT_PAGINATION;
  @observable rawRejectedColumns = rejectedColumns;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action.bound async getAll() {
    const { method, url } = this.rootStore.urls.orders.getFailedSnapshots;
    let params = buildParams({
      ...this.rawPagination,
    });

    return this.rootStore.makeRequest(this.onGetAll, method, `${url}${params}`);
  }

  @action.bound async changePagination(pagination) {
    this.rawPagination = {
      ...pagination,
    };

    this.getAll();
  }

  @action.bound dropPagination() {
    this.rawPagination = DEFAULT_PAGINATION;
  }

  @action.bound onGetAll(data) {
    this.rawOrders = data.items.map(order => order);
    this.totalCount = data.pagination.total_count;
  }

  @computed get orders() {
    return this.rawOrders;
  }

  @computed get total() {
    return this.totalCount;
  }

  @computed get pagination() {
    return this.rawPagination;
  }

  @computed get rejectedColumns() {
    return this.rawRejectedColumns;
  }
}

export default RejectedOrders;
