export const filterOptions = [
  { key: 'Austin-TX', value: 'Austin' },
  { key: 'Ann Arbor-FL', value: 'Ann Arbor' },
];

export const filter = {
  style: { width: '300px' },
  buttonsPanel: {
    style: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: '10px',
      paddingBottom: '10px',
    },
    resetText: 'Reset',
    filterText: 'Filter',
  },
};
