import { observable, action, computed } from 'mobx';

import Order from '../models/Order';
import { isOdd } from '../services/helpers';
import { buildParams } from '../services/helpers';

import {
  columns,
  activeColumns,
  completedColumn,
} from '../pages/orders-statuses/settings';

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  columnKey: '',
  order: '',
  filter: '',
};

const DEFAULT_BATCHING_PAGINATION = {
  current: 1,
  pageSize: 20,
  columnKey: '',
  order: '',
  filter: '',
};

const DEFAULT_ORDER_STATUS = 'active';

class ObserversDashboard {
  @observable status = DEFAULT_ORDER_STATUS;
  @observable rawOrders = [];
  @observable rawOrder = {};
  @observable totalCount = '';
  @observable rawPagination = DEFAULT_BATCHING_PAGINATION;
  @observable rawColumns = columns;
  @observable rawActiveColumns = activeColumns;
  @observable rawCompletedColumns = [...columns, completedColumn];
  @observable rawCustomerName = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawOrder = new Order({}, rootStore);
  }

  @action.bound setStatus(status) {
    this.status = status;
  }

  @action.bound resetPagination(status) {
    if (status && status === DEFAULT_ORDER_STATUS ) {
      this.rawPagination = DEFAULT_BATCHING_PAGINATION;
    } else if (!status && this.status === DEFAULT_ORDER_STATUS) {
      this.rawPagination = DEFAULT_BATCHING_PAGINATION;
    } else {
      this.rawPagination = DEFAULT_PAGINATION;
    }

    this.rawColumns = this.rawColumns.map(col => ({
      ...col,
      sortOrder: false,
    }));

    this.rawActiveColumns = this.rawActiveColumns.map(col => ({
      ...col,
      sortOrder: false,
    }));
  }

  @action.bound onGetAll(data) {
    let prepared = data.items;
    if (this.status === DEFAULT_ORDER_STATUS) {
      prepared = this.preparedRobotsArray(data.items);
    }

    this.rawOrders = prepared.map(order => new Order(order, this.rootStore));

    this.totalCount = data.pagination.total_count;
    this.rawCustomerName = data.customer_observer_name;
  }

  @action.bound async getAll() {
    const { method, url } = this.rootStore.urls.observerDashboard.getAll;
    const params = buildParams({
      ...this.rawPagination,
      status: this.status,
    });

    return this.rootStore.makeRequest(this.onGetAll, method, `${url}${params}`);
  }

  @action.bound preparedRobotsArray(response) {
    let robotsCount = 0;
    let preparedData = [];

    response.map(item => {
      const ordersQty = item.deliveries.length;
      robotsCount++;

      return item.deliveries.map((delivery, index) => {
        // First row should have span the others should have span = 0
        let count = index ? 0 : ordersQty;
        const formattedData = {
          ...delivery,
          span: count,
          rowClassName: isOdd(robotsCount) ? '' : 'colored--light-blue',
          robot_name: item.robot,
          deliveries_count: item.deliveries_count,
        };

        return (preparedData = [...preparedData, formattedData]);
      });
    });

    return preparedData;
  }

  @action.bound async changePagination(pagination, filter, sort) {
    this.rawPagination = {
      ...pagination,
      ...sort,
    };

    this.rawColumns = this.rawColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
    }));

    this.rawActiveColumns = this.rawActiveColumns.map(column => ({
      ...column,
      sortOrder: sort.columnKey === column.key ? sort.order : false,
    }));
  }

  @computed get orders() {
    return this.rawOrders;
  }

  @computed get pagination() {
    return this.rawPagination;
  }

  @computed get columns() {
    return this.rawColumns;
  }

  @computed get activeColumns() {
    return this.rawActiveColumns;
  }

  @computed get completedColumns() {
    return this.rawCompletedColumns;
  }

  @computed get customerName() {
    return this.rawCustomerName;
  }

  @computed get total() {
    return this.totalCount;
  }
}

export default ObserversDashboard;
