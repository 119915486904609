import React from 'react';
import { utcToCurrentTime } from '../../pages/orders/settings';
import { robotVoltageStyledResults } from '../../pages/robots/styled';
import { robotStatusBadges } from '../../pages/subfleets/settings';
import { robotInfoNotification } from './settings';
import moment from 'moment';

const RobotInfoNotification = ({ robotData }) => {
  const { subFleet, battery, state, updated } = robotData;
  const { robotState, robotStateWrapper } = robotInfoNotification;
  console.log(utcToCurrentTime(updated));
  const date=new Date(updated)
  return (
    <>
      <div>Subfleet name: {subFleet || '-'}</div>
      <div>Battery voltage: {robotVoltageStyledResults(battery) || '-'}</div>
      <div style={robotStateWrapper.style}>
        <span style={robotState.style}>Current state: </span>
        {robotStatusBadges(state) || '-'}
      </div>
      <div>Last update: {moment(date).format('MM-DD-YYYY')||'-'} {moment(date).format('hh:mm:ss A') || '-'}</div>
    </>
  );
};

export default RobotInfoNotification;
