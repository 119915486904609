import React, { useState } from 'react';
import { Menu, Dropdown, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import Button from 'components/button';

const MoreDropdown = ({ menuItems }) => {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = () => {
    setVisible(false);
  };

  const menu = (
    <Menu>
      {menuItems.map((item, index) => {
        const menuItem = (
          <Menu.Item key={index.toString()} styles={{ paddingLeft: 0 }}>
            <Button
              type={item.type}
              icon={item.icon}
              onClick={e => {
                handleMenuClick(e);
                item.onClick(e);
              }}
              disabled={item.disabled}
            >
              {item.text}
            </Button>
          </Menu.Item>
        );

        return menuItem;
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      onVisibleChange={v => setVisible(v)}
      visible={visible}
      trigger={['click']}
    >
      <Space>
        <Button
          style={{
            border: 'none',
            padding: 0,
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <MoreOutlined style={{ fontSize: '24px' }} />
        </Button>
      </Space>
    </Dropdown>
  );
};

export default MoreDropdown;
