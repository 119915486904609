import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';

export const PageActionBar = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const badgeStyle = {
  style: {
    green: {
      color: 'white',
      background: 'green',
      borderRadius: '5px',
      padding: '2px',
    },
    yellow: {
      color: 'white',
      background: '#D4B94D',
      borderRadius: '5px',
      padding: '2px',
    },
    red: {
      color: 'white',
      background: '#C70000',
      borderRadius: '5px',
      padding: '2px',
    },
  },
};

export const robotVoltageStyledResults = i => {
  if (i >= 0 && i < 48) return <span style={badgeStyle.style.red}>{i}</span>;
  if (i >= 50) return <span style={badgeStyle.style.green}>{i}</span>;
  if (i >= 48 && i < 50)
    return <span style={badgeStyle.style.yellow}>{i}</span>;
};
