import styled from 'styled-components';
import { Table } from 'components';

export const StyledTable = styled(Table)`
  tr td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }
`;
