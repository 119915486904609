import { observable } from 'mobx';

class Robot {
  @observable id;
  @observable name;
  @observable fleet_name;
  @observable current_state;
  @observable battery_voltage;
  @observable odometer_mi;
  @observable current_delivery_id;
  @observable current_driver;
  @observable current_chaser;
  @observable telemetry_updated_at;
  @observable fleet_id;

  constructor(robot, rootStore) {
    Object.assign(this, robot);
    this.rootStore = rootStore;
  }
}

export default Robot;
