import React from 'react';
import { Input, AutoComplete as AntdAutoComplete } from 'antd';

import { autocomplete } from './settings';

const {
  style,
  searchAttributes: { enterButton, size, placeholder, allowClear },
} = autocomplete;

const AutoComplete = ({
  onChange,
  onSelect,
  onSearch,
  selectWidth,
  width,
  options,
  search,
  searchValue,
}) => {
  return (
    <AntdAutoComplete
      dropdownMatchSelectWidth={selectWidth}
      onSelect={onSelect}
      style={{
        width: width,
        ...style,
      }}
      options={options}
      value={searchValue}
    >
      {search ? (
        <Input.Search
          onChange={onChange}
          onSearch={onSelect}
          onPressEnter={onSearch}
          enterButton={enterButton}
          size={size}
          placeholder={placeholder}
          allowClear={allowClear}
        />
      ) : (
        <Input onChange={onChange} />
      )}
    </AntdAutoComplete>
  );
};

export default AutoComplete;
