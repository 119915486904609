import React from "react";
import moment from "moment";
import { Tooltip, Typography } from "antd";

export const columns = [
  {
    title: 'Versions',
    dataIndex: 'version',
    align: 'left',
    width: 120,
    render: version => <Tooltip title={version} placement='topLeft'>
      <Typography.Text ellipsis style={{width: 100}}>{version}</Typography.Text>
    </Tooltip>,
  },
  {
    title: 'Release Notes',
    dataIndex: 'notes',
    align: 'left',
    render: notes => notes &&
      <ul style={{paddingLeft: 20, marginBottom: 0}}>
        {notes.split('\n').map((note, i) => <li key={i}>{note}</li>)}
      </ul>
  },
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'left',
    width: 120,
    render: release_date => moment(release_date).format('MM/DD/YYYY')
  },
];
