import styled from 'styled-components';

export const StyledControls = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 8px;
  bottom: 10px;
  
  button {
    margin-left: 8px;
  }
`;

export const StyledDropdownWrapper = styled.div`
  .subtitle {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const StyledMessage = styled.p`
  display: inline;
  span {
    font-weight: bold;
  }
`;
