import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import { apiKey, geocode, getInitialCoords } from './settings';
import { observer } from 'mobx-react';
import { compose } from 'services/helpers';
import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  DEFAULT_ZOOM,
  DEFAULT_ZOOM_IN,
} from '../../common/constants';

const GoogleMap = ({
  google,
  setAddress,
  latitude,
  longitude,
  mapSettings,
  setLocation,
}) => {
  const center = getInitialCoords(latitude, longitude);

  const zoomControl = () => {
    if (center.lat !== DEFAULT_LAT && center.lng !== DEFAULT_LNG) {
      return DEFAULT_ZOOM_IN;
    }
    return DEFAULT_ZOOM;
  };

  const onDragend = async (...props) => {
    const { results } = await geocode.fromLatLng(
      props[2].latLng.lat(),
      props[2].latLng.lng()
    );

    setAddress(results[0].formatted_address);
    setLocation({
      latitude: results[0].geometry.location.lat,
      longitude: results[0].geometry.location.lng,
    });
  };

  return (
    <Map
      google={google}
      zoom={zoomControl()}
      style={mapSettings.style}
      initialCenter={{
        lat: center.lat,
        lng: center.lng,
      }}
      center={{
        lat: center.lat,
        lng: center.lng,
      }}
    >
      <Marker
        google={google}
        draggable={true}
        onDragend={onDragend}
        position={{
          lat: latitude || center.lat,
          lng: longitude || center.lng,
        }}
      />
    </Map>
  );
};

export default compose(
  GoogleApiWrapper({
    apiKey,
  }),
  observer
)(GoogleMap);
