import React from 'react';
import { spinnerStyle, StyledPreloader } from './styled';
import { Spin } from 'antd';

const Preloader = () => (
  <StyledPreloader>
    <Spin style={spinnerStyle.style} />
  </StyledPreloader>
);

export default Preloader;
