import {
  REQUIRED_FIELD,
  PRONE_REGEX,
  INVALID_NATIONAL_PHONE,
} from '../../../common/constants';

const getInitialValue = key => entity => {
  return entity ? entity[key] : '';
};

export const orderForm = {
  layout: 'vertical',
  name: {
    name: 'name',
    label: 'Drop off Name',
    initialValue: getInitialValue('contact_name'),
    rules: [{ required: true, type: 'string', message: REQUIRED_FIELD }],
  },
  address: {
    name: 'address',
    label: 'Drop off Address',
    initialValue: getInitialValue('address'),
  },
  phone: {
    name: 'phone',
    label: 'Drop off Phone',
    placeholder: '',
    initialValue: getInitialValue('contact_phone'),
    rules: [
      {
        required: true,
        validator: phoneValidation,
      },
    ],
  },
  notes: {
    name: 'notes',
    label: 'Notes',
    placeholder: 'enter some notes',
    initialValue: getInitialValue('notes'),
  },
  failedReason: {
    name: 'failed_reason',
    label: 'Failed Reason',
    placeholder: 'Select reason',
    initialValue: getInitialValue('failed_reason'),
  },
  chaserPresent: {
    name: 'chaser_present',
    label: 'Chaser Present',
    initialValue: getInitialValue('chaser_present'),
  },
  mapSettings: {
    zoom: 11,
    style: {
      maxWidth: '100%',
      height: '310px',
      transform: 'translate(0, -10px)',
    },
  },
  mapContainerProps: {
    height: '350px',
    display: 'flex',
    direction: 'column',
    justify: 'space-between',
    span: 10,
  },
  robotError: {
    title: 'Robot error',
    width: '30%',
  },
};

export const habitatOrderForm = {
  pickup: { title: 'Pick Up' },
  dropoff: { title: 'Drop Off' },
  // layout: 'vertical',
  pickupName: {
    name: 'pickupName',
    label: 'Pick up Name',
    initialValue: getInitialValue('contact_name'),
    selectWidth: '100%',
    rules: [{ required: true, type: 'string', message: REQUIRED_FIELD }],
  },
  dropoffName: {
    name: 'dropoffName',
    label: 'Drop off Name',
    initialValue: getInitialValue('contact_name'),
    rules: [{ required: true, type: 'string', message: REQUIRED_FIELD }],
  },
  pickupAddress: {
    name: 'pickupAddress',
    label: 'Pick up Address',
    initialValue: getInitialValue('address'),
    rules: [{ required: true, message: REQUIRED_FIELD }],
  },
  dropoffAddress: {
    name: 'dropoffAddress',
    label: 'Drop off Address',
    initialValue: getInitialValue('address'),
    rules: [{ required: true, message: REQUIRED_FIELD }],
  },
  pickupPhone: {
    name: 'pickupPhone',
    label: 'Pick up Phone',
    placeholder: '',
    initialValue: getInitialValue('contact_phone'),
    rules: [
      {
        required: true,
        validator: phoneValidation,
      },
    ],
  },
  dropoffPhone: {
    name: 'dropoffPhone',
    label: 'Drop off Phone',
    placeholder: '',
    initialValue: getInitialValue('contact_phone'),
    rules: [
      {
        required: true,
        validator: phoneValidation,
      },
    ],
  },
  pickupNotes: {
    name: 'pickupNotes',
    label: 'Pick up Notes',
    placeholder: 'enter some notes',
    initialValue: getInitialValue('notes'),
  },
  dropoffNotes: {
    name: 'dropoffNotes',
    label: 'Drop off Notes',
    placeholder: 'enter some notes',
    initialValue: getInitialValue('notes'),
  },
  mapSettings: {
    zoom: 11,
    style: {
      maxWidth: '100%',
      height: '160px',
      transform: 'translate(0, -10px)',
    },
  },
  selectCustomer: {
    name: 'selectedCustomer',
    label: 'Select Customer',
    style: { width: '100%' },
  },
  mapContainerProps: {
    height: '170px',
    display: 'flex',
    direction: 'column',
    justify: 'space-between',
  },
  robotError: {
    title: 'Robot error',
    width: '30%',
  },
};

export const checkAddress = (address = '', predictions = []) => {
  if (!address) return Promise.reject('This is required field');
  if (predictions.some(el => el.description === address)) {
    return Promise.resolve();
  }
  return Promise.reject('Address is not valid');
};

export function phoneValidation(_, value) {
  return PRONE_REGEX.test(+value)
    ? Promise.resolve(value)
    : Promise.reject(INVALID_NATIONAL_PHONE);
}
