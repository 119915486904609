import React from 'react';

import {
  // FieldTimeOutlined,
  AuditOutlined,
  AndroidOutlined,
  DeliveredProcedureOutlined,
  TrademarkCircleOutlined,
  DatabaseOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';

const iconStyles = { paddingRight: '10px' };

export const menuItems = [
/*  {
    title: 'Versions',
    icon: <FieldTimeOutlined style={iconStyles}/>,
    href: '/versions',
  },*/
  {
    title: 'Admins',
    icon: <AuditOutlined style={iconStyles} />,
    href: '/admins',
  },
  {
    title: 'Customers',
    icon: <TrademarkCircleOutlined style={iconStyles} />,
    href: '/customers',
  },
  {
    title: 'Orders',
    icon: <DeliveredProcedureOutlined style={iconStyles} />,
    href: '/orders',
  },
  {
    title: 'Robots',
    href: '/robots',
    icon: <AndroidOutlined style={iconStyles} />,
  },
  {
    title: 'Robot Locations',
    href: '/robots-map',
    icon: <EnvironmentOutlined style={iconStyles} />,
  },
  {
    title: 'Subfleets',
    href: '/subfleets',
    icon: <DatabaseOutlined style={iconStyles} />,
  },
];
export const menu = { theme: 'light', mode: 'vertical' };
