import { REQUIRED_FIELD } from '../../../common/constants';

export const loginForm = {
  layout: 'vertical',
  iconStyle: { color: 'rgba(0,0,0,.25)' },
  emailField: {
    name: 'username',
    rules: [{ required: true, message: REQUIRED_FIELD }],
    prefix: {
      type: 'mail',
    },
    type: 'email',
    placeholder: 'email',
  },
  passwordField: {
    name: 'password',
    rules: [{ required: true, message: REQUIRED_FIELD }],
    prefix: {
      type: 'lock',
    },
    type: 'password',
    placeholder: 'password',
  },
  submitButton: {
    type: 'primary',
    htmlType: 'submit',
    title: 'Log In',
    size: 'large',
    style: { width: '100%' },
  },
};
