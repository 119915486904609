import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { Layout, LayoutWithoutSidebar } from '../../layouts';
import { compose } from '../../../services/helpers';

const ProtectedRoute = ({ authStore, ...restProps }) => {
  const { component: Page, roles, ...routeProps } = restProps;
  const { token, permissionNames } = authStore;

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...routeProps}
      render={props =>
        (() => {
          if (
            (permissionNames && permissionNames[0] === 'admin_versions') ||
            (permissionNames && permissionNames[0] === 'customer_observer')
          ) {
            return (
              <LayoutWithoutSidebar>
                <Page {...props} />
              </LayoutWithoutSidebar>
            );
          }

          return (
            <Layout>
              <Page {...props} />
            </Layout>
          );
        })()
      }
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
  })),
  observer
)(ProtectedRoute);
