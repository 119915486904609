import React from 'react';
import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import { LogoutOutlined } from '@ant-design/icons';

import { StyledHeader, StyledUsername } from './styled';
import { compose } from 'services/helpers';

const Header = ({ authStore: { logout, username } }) => {
  return (
    <StyledHeader>
      <StyledUsername>{username}</StyledUsername>
      <Button shape="circle" icon={<LogoutOutlined />} onClick={logout} />
    </StyledHeader>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
  })),
  observer
)(Header);
