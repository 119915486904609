import React from 'react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router, Switch, Route, } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';

import RootStore from 'stores/Root';
import routes, { login as loginRouteProps } from 'routes';
import { ProtectedRoute, LoginRoute, DefaultRoute } from 'components/routes';

const routingStore = new RouterStore();
export const rootStore = new RootStore(routingStore);
const history = syncHistoryWithStore(createBrowserHistory(), routingStore);
const storesWithRouting = { rootStore };

const App = () => (
  <Provider {...storesWithRouting}>
    <Router history={history}>
      <Switch>
        <Route component={DefaultRoute} path="/" exact />

        <LoginRoute {...loginRouteProps} />
        {routes.map(props => (
          <ProtectedRoute key={props.path} {...props} />
        ))}
      </Switch>
    </Router>
  </Provider>
);

export default App;
