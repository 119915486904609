import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Tabs } from 'antd';

import { compose } from 'services/helpers';

import { tabs } from './settings';
import { StyledTable } from './styled';

const UPDATE_REQUEST_INTERVAL = 30 * 1000;

const OrdersStatuses = ({ observerDashboard }) => {
  const {
    customerName,
    orders,
    total,
    pagination,
    resetPagination,
    status: orderStatus,
    setStatus,
    getAll: getAllOrders,
    changePagination,
    columns,
    activeColumns,
    completedColumns,
  } = observerDashboard;

  const { active, completed, canceled } = tabs;

  useEffect(() => {
    let timer = null;

    // Used to update orders each UPDATE_REQUEST_INTERVAL milliseconds
    (async function updateRequest() {
      await getAllOrders();
      timer = setTimeout(updateRequest, UPDATE_REQUEST_INTERVAL);
    })();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [getAllOrders, pagination, orderStatus]);

  return (
    <div>
      <div style={{ marginBottom: '20px', fontSize: '20px' }}>
        Orders for: <span style={{ fontWeight: 'bold' }}>{customerName}</span>
      </div>

      <Tabs
        activeKey={orderStatus}
        type="card"
        onChange={status => {
          resetPagination(status);
          setStatus(status);
        }}
      >
        <Tabs.TabPane tab={active.tab} key={active.key}>
          <StyledTable
            columns={activeColumns}
            data={orders}
            rowKey={record => record.order_id}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
            rowClassName={record => `status-${record.current_state}`}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={completed.tab} key={completed.key}>
          <StyledTable
            columns={completedColumns}
            data={orders}
            rowKey={record => record.order_id}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
            rowClassName={record => `status-${record.current_state}`}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={canceled.tab} key={canceled.key}>
          <StyledTable
            columns={columns}
            data={orders}
            rowKey={record => record.order_id}
            pagination={pagination}
            paginationTotal={total}
            onChange={changePagination}
            rowClassName={record => `status-${record.current_state}`}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    observerDashboard: rootStore.observerDashboard,
  })),
  observer
)(OrdersStatuses);
