import { Select } from 'antd';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledContent = styled.div`
  padding: 10px 10px 0 10px;
  font-size: 18px;
`;

export const StyledStatus = styled.span`
  color: green;
  font-weight: bold;
`;
